var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',[_c('BRow',[_c('BCol',{attrs:{"cols":"12","sm":"11","md":"11","lg":"11","xl":"11"}},[_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('BaseDatePicker',{ref:"toDate",staticClass:"datepicker input-margin",attrs:{"datepicker-id":"claim-date-id","value":_vm.value.claimDate,"label":_vm.datePickerLabel,"placeholder":_vm.$t('placeholder'),"start-date":false,"required":true,"max-error-date":_vm.getMaxDate(),"max-selectable-date":_vm.getMaxDate(),"required-error-msg":_vm.$t('error.requiredClaimDate'),"before-max-date-error-msg":_vm.$t('error.future'),"pill":"","is-left":"","automation-id":_vm.getAutomationId('provider-fields')},on:{"input":_vm.updateClaimDate}})],1)],1),_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[(!_vm.onlyParticipant)?_c('div',[_c('label',{class:[
								'participant-label',
								!_vm.value.claimDate || _vm.participantDisabled ? 'disabled' : ''
							],attrs:{"for":"participant-dropdown"}},[_vm._v(_vm._s(_vm.$t('label.participant')))]),_c('br'),_c('ParticipantDropdown',{staticClass:"participant-dropdown input-margin",attrs:{"id":"participant-dropdown","value":_vm.value.participant,"placeholder":_vm.$t('placeholder'),"validation-rules":{ required: true },"validation-messages":{
								required: _vm.$t('error.required')
							},"show-placeholder":_vm.showPlaceholder || _vm.participantDisabled,"show-icon":_vm.showIcon,"show-dob":!_vm.showPlaceholder,"participants":_vm.members,"title-text":_vm.$t('placeholder'),"selected-participant":_vm.value.participant,"automation-id":_vm.getAutomationId('provider-fields')},on:{"blur":_vm.checkParticipantValue,"click":_vm.updateParticipant}}),(_vm.showParticipantErrorComputed)?_c('ValidationProvider',{attrs:{"id":"service-exists-validation","rules":"required"}},[_c('div',{staticClass:"d-block error-message",attrs:{"id":"service-exists-error"}},[_vm._v(" "+_vm._s(_vm.$t('error.required'))+" ")])]):_vm._e()],1):_c('div',[_c('label',{staticClass:"participant-label",attrs:{"for":"participant"}},[_vm._v(_vm._s(_vm.$t('label.participant')))]),_c('p',{staticClass:"participant-name",attrs:{"id":"participant"}},[_vm._v(" "+_vm._s(_vm.onlyParticipant.firstName)+" "+_vm._s(_vm.onlyParticipant.lastName)+" ")])])])],1),(!_vm.isVision && !_vm.isPWA && !_vm.isDrugsOrDental)?_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('BaseSelect',{ref:"select",class:[
							'provider-styles',
							'input-margin',
							!_vm.value.participant || _vm.participantDisabled ? 'disabled' : ''
						],attrs:{"value":_vm.value.providerType ? _vm.value.providerType : '',"label":_vm.$t('label.claimFor'),"placeholder":_vm.$t('placeholder'),"options":_vm.providerTypeList,"validation-rules":{ required: _vm.value.participant !== null },"validation-messages":{
							required: _vm.$t('error.serviceRequired')
						},"disabled":!_vm.value.participant,"automation-id":'provider-type-dropdown'},on:{"change":_vm.updateProviderType}})],1)],1):_vm._e(),(_vm.showOtherInput)?_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('BaseInput',{staticClass:"mb-0 input",attrs:{"label":_vm.$t('label.otherProvider'),"value":_vm.otherProviderType,"validation-rules":{ required: true },"validation-messages":{
							required: _vm.$t('error.otherRequired')
						},"max-length":50,"automation-id":_vm.getAutomationId('provider-fields')},on:{"input":_vm.updateOtherProviderType}})],1)],1):_vm._e(),(_vm.isPWA)?[_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('ServiceSelect',{ref:"select",class:['input', _vm.isServiceDisabled ? 'disabled' : ''],attrs:{"label":_vm.$t('label.pwaService'),"value":_vm.value.service,"services":_vm.pwaServices,"validation-rules":{ required: !_vm.isServiceDisabled },"validation-messages":{ required: _vm.$t('error.servicePwaRequired') },"placeholder":_vm.$t('placeholder'),"automation-id":_vm.getAutomationId('provider-fields'),"disabled":_vm.isServiceDisabled},on:{"change":_vm.updateService}})],1)],1),_c('BRow',{staticClass:"input-row"},[_c('BCol',{attrs:{"cols":"12"}},[_c('CurrencyInput',{ref:"currency",class:['currency-input', _vm.isCurrencyDisabled ? 'disabled' : ''],attrs:{"id":'currencyInput',"label":_vm.$t('label.currencyInput'),"value":_vm.value.amountPaid,"symbole":true,"min-value":-1,"disabled":_vm.isCurrencyDisabled,"allow-zero":"","validation-rules":{ required: !_vm.isCurrencyDisabled, isMinValue: 0.01 },"validation-messages":{
								required: _vm.$t('error.currencyRequired'),
								isMinValue: _vm.$t('error.valid')
							},"automation-id":_vm.getAutomationId('provider-fields')},on:{"change":_vm.updateAmount}})],1)],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }