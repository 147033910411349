<template>
	<div>
		<BRow>
			<BCol>
				<BaseCard>
					<ProviderCard
						class="mb-4"
						:provider="provider"
						:member="member"
						:show-provider="provider && !claim.isProviderNotFound"
						automation-id="service-type"
					/>
				</BaseCard>
			</BCol>
		</BRow>
		<template v-if="showServiceList">
			<BaseCard class="service-card">
				<BRow v-for="(service, index) in services" :key="service.id" class="m-0">
					<BCol class="p-0">
						<div v-if="editServiceIndex === index" class="edit-service-card">
							<h3>{{ $t('editTitle') }}</h3>
							<ServiceCard
								v-if="claim"
								:value="editedServiceValue"
								:hide-date-picker="index === 0"
								:coordination-of-benefits="claim.coordinationOfBenefits"
								:service-types="serviceTypes"
								:claim-type="claim.claimType"
								:service-date="convertedServiceDate"
								:show-save-buttons="true"
								:require-prescription="index === 0 ? requirePrescription : false"
								:show-more-than-one-service-error="serviceAlreadyExists"
								:has-cob-on-file="hasCobOnFile"
								class="mx-0"
								@cancel="cancelEdit"
								@checkNextStatus="changeNextStatus"
								@input="(arg) => updateEditService(arg, index)"
								@save="saveEditedService"
								@currencyCheck="currencyCheck"
							/>
						</div>
						<div v-else>
							<ServiceListItem
								:value="service"
								:class="index > 0 ? 'border-top' : null"
								:disable-buttons="showAddService || editServiceIndex !== null"
								:hide-remove="index === 0"
								:automation-id="`service-type-${index}`"
								@edit="editService(index)"
								@remove="confirmRemoveService(service, index)"
							/>
						</div>
					</BCol>
				</BRow>
			</BaseCard>
		</template>
		<template v-else>
			<BRow>
				<BCol>
					<BaseCard>
						<ServiceCard
							v-if="claim"
							:value="benefitService"
							:coordination-of-benefits="claim.coordinationOfBenefits"
							:service-types="serviceTypes"
							:claim-type="claim.claimType"
							:service-date="convertedServiceDate"
							:require-prescription="requirePrescription"
							:has-cob-on-file="hasCobOnFile"
							class="mx-0"
							@input="saveService"
							@checkNextStatus="changeNextStatus"
							@checkStatus="changeNextStatus(checkNextStatus())"
						/>
					</BaseCard>
				</BCol>
			</BRow>
		</template>
		<BRow v-if="showAddService">
			<BCol>
				<BaseCard>
					<ServiceCard
						:value="addServiceValue"
						:coordination-of-benefits="claim.coordinationOfBenefits"
						:service-types="serviceTypes"
						:claim-type="claim.claimType"
						:service-date="convertedServiceDate"
						:has-cob-on-file="hasCobOnFile"
						:show-save-buttons="true"
						:require-prescription="false"
						:hide-date-on-service="true"
						:show-more-than-one-service-error="serviceAlreadyExists"
						class="mx-0"
						@checkNextStatus="changeNextStatus"
						@input="addService"
						@save="saveAdditionalService"
						@cancel="cancelAddService"
					/>
				</BaseCard>
			</BCol>
		</BRow>
		<BRow v-else>
			<BCol class="mb-3 ml-1">
				<!-- Only show this button if the member has less that 10 services and are not editing or adding a service -->
				<BaseButton
					v-if="showServiceList && services.length < 10 && editServiceIndex === null"
					:label="btnAddServiceLabel"
					:icon="['fal', 'plus-circle']"
					icon-position="left"
					variant="link"
					icon-size="lg"
					class="pl-0 btn-add-services"
					automation-id="service-type-add-service"
					@click="enableAddService"
				/>
			</BCol>
		</BRow>

		<!-- prescription required Modal -->
		<ErrorModal
			:data-test-automation-id="getAutomationId('prescription-required-modal')"
			:modal-show="showPrescriptionModal"
			:error-title="$t('modal.title')"
			:error-message="$t('modal.text')"
			:has-close-button="false"
			:cant-close-on-esc="true"
			@change="navigateToClaimType()"
		>
			<BaseButton
				v-if="showPrescriptionModal"
				class="mt-2"
				variant="primary"
				:label="$t('modal.button')"
				pill
				is-right
				type="submit"
				:automation-id="getAutomationId('prescription-required-ok')"
				data-tracking-id="prescription-required-ok-button"
				@click="navigateToClaimType()"
			></BaseButton>
		</ErrorModal>
		<!-- Confirm Remove Modal -->
		<ErrorModal
			class="remove-error-modal"
			:data-test-automation-id="getAutomationId('remove-error-modal')"
			:modal-show="showConfirmRemoveServiceModal"
			:error-title="$t('modal.removeTitle')"
			:has-close-button="false"
			:cant-close-on-esc="true"
		>
			<BRow>
				<BCol>
					<ServiceListItem
						:value="serviceToRemove"
						:hide-buttons="true"
						:automation-id="`service-list-remove`"
					/>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<BaseButton
						:label="$t('error.btn.no')"
						variant="primary"
						class="mr-2"
						automation-id="service-type-error-no"
						@click="cancelRemove()"
					/>
					<BaseButton
						:label="$t('error.btn.yes')"
						class="mr-auto"
						automation-id="service-type-error-yes"
						@click="removeService()"
					/>
				</BCol>
			</BRow>
		</ErrorModal>
		<!-- ERROR MODAL FOR MIN INPUT AMOUNTS -->
		<ErrorModal
			:data-test-automation-id="getAutomationId('min-amount-modal')"
			:modal-show="showAmountErrorModal"
			:error-title="$t('error.minTitle')"
			:error-message="$t('error.min')"
			no-hide-on-blur
			:has-close-button="false"
		>
			<template #default>
				<BRow>
					<BCol>
						<BaseButton
							:label="$t('error.btn.no')"
							variant="primary"
							class="ml-auto mr-2"
							automation-id="service-type-error-no"
							@click="focusZeroCurrency()"
						/>
						<BaseButton
							:label="$t('error.btn.yes')"
							class="mr-auto"
							automation-id="service-type-error-yes"
							@click="saveServiceLowAmount"
						/>
					</BCol>
				</BRow>
			</template>
		</ErrorModal>
		<!-- ERROR MODAL FOR MAX INPUT AMOUNTS -->
		<ErrorModal
			:data-test-automation-id="getAutomationId('min-amount-modal')"
			:modal-show="showMaxAmountErrorModal"
			:error-title="$t('error.maxTitle')"
			:error-message="$t('error.max')"
			no-hide-on-blur
			:has-close-button="false"
		>
			<template #default>
				<BRow>
					<BCol>
						<BaseButton
							:label="$t('error.btn.okay')"
							variant="primary"
							class="ml-auto mr-2"
							automation-id="service-type-error-no"
							@click="closeMaxAmountModal"
						/>
					</BCol>
				</BRow>
			</template>
		</ErrorModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import ProviderCard from '@/components/claim/claimService/ProviderCard';
import ServiceCard from '@/components/claim/claimService/ServiceCard';
import ServiceListItem from '@/components/claim/claimService/ServiceListItem';
import BenefitService from '@/models/claims/BenefitService';
import ErrorModal from '@/components/common/ErrorModal';
import * as Claimtypes from '@/constants/ClaimTypes';
import IdMixin from '@/mixins/id';
import { CLAIM_TYPE } from '@/constants/Routes.js';
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';

@Component({
	name: 'ClaimDetails',
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		serviceTypes: {
			type: Array,
			default: () => []
		},
		serviceDate: {
			type: String,
			default: ''
		},
		member: {
			type: Object,
			default: () => {}
		},
		invalidServices: {
			type: Boolean,
			default: false
		},
		showPrescriptionModal: {
			type: Boolean,
			default: false
		},
		providerType: {
			type: String,
			default: null
		},
		showServiceList: {
			type: Boolean,
			default: false
		},
		checkCurrencyInputs: {
			type: Boolean,
			default: false
		}
	},
	components: {
		BRow,
		BCol,
		BaseButton,
		BaseCard,
		ServiceCard,
		ServiceListItem,
		ProviderCard,
		ErrorModal
	},
	watch: {
		checkCurrencyInputs: function () {
			if (this.checkCurrencyInputs) {
				let isValid = this.currencyCheck(this.claim.benefitServices[0]);
				if (isValid) {
					this.$emit('saveService');
				}
			}
		}
	}
})
export default class ClaimDetails extends Vue {
	localRowId = 0;

	requirePrescription = false;
	showAddService = false;
	addServiceValue = null;
	editedServiceValue = null;
	serviceAlreadyExists = false;
	editServiceIndex = null;
	showConfirmRemoveServiceModal = false;
	showAmountErrorModal = false;
	showMaxAmountErrorModal = false;
	serviceToRemoveIndex = null;
	serviceToRemove = null;
	minAmount = 1;
	maxAmount = 100000;
	checkCurrencyInputslocal = false;

	// ---------------------------- COMPUTED PROPERTIES ------------------------------

	get claim() {
		return this.value ? this.value : {};
	}

	get availableServiceTypes() {
		return this.serviceTypes ? this.serviceTypes : [];
	}

	get services() {
		return this.claim?.benefitServices ? this.claim.benefitServices : [];
	}

	get benefitService() {
		return this.claim?.benefitServices?.length > 0 ? this.claim.benefitServices[0] : null;
	}

	get hasCobOnFile() {
		return this.claim?.coordinationOfBenefits?.infoOnFileValid;
	}

	/**
	 * return provider information
	 */
	get provider() {
		return this.claim?.benefitProvider;
	}

	get initialService() {
		return this.claim?.benefitServices ? this.claim.benefitServices[0] : {};
	}

	get specialtyCode() {
		return this.claim?.benefitServices[0]?.specialtyCode
			? this.claim.benefitServices[0].specialtyCode
			: 0;
	}

	get serviceCount() {
		return this.claim?.benefitServices ? this.claim.benefitServices.length : 0;
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get isPWA() {
		return this.claim.claimType === Claimtypes.PWA;
	}
	/**
	 * returns enabled service types count
	 */
	get activeServiceTypesCount() {
		return this.serviceTypes ? this.serviceTypes?.filter((service) => service.isEnabled).length : 0;
	}

	servicesMatchServiceTypes() {
		return this.serviceCount === this.serviceTypes.length;
	}

	get convertedServiceDate() {
		const sDate = new Date(
			this.serviceDate.substr(0, 4),
			this.serviceDate.substr(4, 2) - 1,
			this.serviceDate.substr(6, 2)
		);
		const dateLocale = this.$store.state.i18n.locale === 'en' ? enCA : frCA;
		const dateFormat = this.$store.state.i18n.locale === 'en' ? 'MMMM d, yyyy' : 'd MMMM yyyy';
		return format(sDate, dateFormat, {
			locale: dateLocale
		});
	}

	get btnAddServiceLabel() {
		return this.$t('buttonAdd', { serviceType: this.providerType, name: this.member.firstName });
	}

	/**
	 * On mount check if a service exists and if it requires a prescription.
	 */
	mounted() {
		if (this.claim?.benefitServices[0]) {
			this.requirePrescription = this.claim.benefitServices[0].isRequirePrescription;
		}
	}

	// ---------------------------- SAVE ORIGIONAL SERVICE ------------------------------

	/**
	 * This method is used to save the original service on the service details screen. That is only shown for specific claim flows.
	 * It finds the serviceType using the descriptionRbKey the member selected then uses that object to create a benefit service to save to the claim.
	 */
	async saveService(serviceToSave) {
		// non-pwa claims need to change availability of service
		const serviceType = this.serviceTypes.find(
			(serviceToSearch) => serviceToSearch.value === serviceToSave.descriptionRbKey
		);
		// replace the current service under selected locale
		let service = this.services.find((nService) => {
			if (serviceToSave.serviceRow) {
				return nService.serviceRow === serviceToSave.serviceRow;
			}
			return nService.id === serviceToSave.id;
		});
		if (serviceType?.service) {
			service.categoryId = serviceType.service.categoryId;
			service.categoryUiId = serviceType.service.categoryUiId;
			service.description = serviceType.service.description;
			service.descriptionRbKey = serviceType.service.descriptionRbKey;
			service.isMasterService = serviceType.service.isMasterService;
			service.isRequirePrescription = serviceType.service.isRequirePrescription;
			service.isServiceTypeOther = serviceType.service.isServiceTypeOther;
			service.languageCode = serviceType.service.languageCode;
			service.pocLobCode = serviceType.service.pocLobCode;
			service.pocLobId = serviceType.service.pocLobId;
			service.programCd = serviceType.service.programCd;
			service.programId = serviceType.service.programId;
			service.serviceCode = serviceType.service.serviceCode;
			service.serviceId = serviceType.service.serviceId;
			service.specialtyCode = serviceType.service.specialtyCode;
			service.specialtyId = serviceType.service.specialtyId;
		}
		service.dateOfService = this.serviceDate;
		service.serviceRow = serviceToSave.serviceRow;
		service.amountPaid = serviceToSave.amountPaid;
		service.wasSubmittedToOtherFirst = serviceToSave.wasSubmittedToOtherFirst;
		service.amountPaidByOtherCarrier = serviceToSave.amountPaidByOtherCarrier;
		service.cobOtherReason = serviceToSave.cobOtherReason;
		service.cobOtherReasonNote = serviceToSave.cobOtherReasonNote;

		// Set require prescription property to know if we need to ask the member for a prescription.
		this.requirePrescription = service.isRequirePrescription;

		//Update component so that the values are up to date
		await this.$emit('input', this.claim);

		this.changeNextStatus(this.checkNextStatus());
	}

	// ---------------------------------------- SAVE ADDITIONAL SERVICES -------------------------------------------

	/**
	 * create new service and set default for items in both locale
	 */
	enableAddService() {
		this.addServiceValue = new BenefitService();
		this.showAddService = true;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This method will cancel adding a service, and clear any values entered by the member.
	 */
	cancelAddService() {
		this.showAddService = false;
		this.addServiceValue = null;
		this.serviceAlreadyExists = false;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This method updates an object called addServiceValue which will be used to save the service to the claim object.
	 * It will check to make sure the member isn't adding a duplicate service, and then save the service and cob info.
	 */
	addService(service) {
		// Check if the member has already entered the service base on service type and date.
		// If they have then display an error.
		// Only check if the claim is not pwa.
		if (!this.isPWA) {
			this.serviceAlreadyExists = false;
			this.services.forEach((savedService) => {
				if (
					service.dateOfService &&
					savedService.dateOfService === service.dateOfService.replaceAll('-', '')
				) {
					if (service && service.descriptionRbKey === savedService.descriptionRbKey) {
						this.serviceAlreadyExists = true;
					}
				}
			});
		}
		// If the service exists then use it to find the service type, and get all the information needed to save the service to the claim.
		if (service) {
			const serviceType = this.serviceTypes.find(
				(serviceToSearch) => serviceToSearch.value === service.descriptionRbKey
			);
			if (serviceType?.service) {
				this.addServiceValue = {
					categoryId: serviceType.service.categoryId,
					categoryUiId: serviceType.service.categoryUiId,
					description: serviceType.service.description,
					descriptionRbKey: serviceType.service.descriptionRbKey,
					isMasterService: serviceType.service.isMasterService,
					isRequirePrescription: serviceType.service.isRequirePrescription,
					isServiceTypeOther: serviceType.service.isServiceTypeOther,
					languageCode: serviceType.service.languageCode,
					pocLobCode: serviceType.service.pocLobCode,
					pocLobId: serviceType.service.pocLobId,
					programCd: serviceType.service.programCd,
					programId: serviceType.service.programId,
					serviceCode: serviceType.service.serviceCode,
					serviceId: serviceType.service.serviceId,
					specialtyCode: serviceType.service.specialtyCode,
					specialtyId: serviceType.service.specialtyId,
					dateOfService: service.dateOfService?.replaceAll('-', ''),
					serviceRow: service.serviceRow,
					amountPaid: service.amountPaid,
					wasSubmittedToOtherFirst: service.wasSubmittedToOtherFirst,
					amountPaidByOtherCarrier: service.amountPaidByOtherCarrier,
					cobOtherReason: service.cobOtherReason,
					cobOtherReasonNote: service.cobOtherReasonNote
				};
			}
		}
	}

	/**
	 * This method is used when the member clicks save while adding an additional service.
	 * It saves the addServiceValue variable that we set in the addService() method.
	 */
	saveAdditionalService() {
		if (this.currencyCheck(this.addServiceValue)) {
			this.services.push(this.addServiceValue);
			this.cancelAddService();
		}
	}

	// ---------------------------------------- EDIT EXISTING SERVICES -------------------------------------------

	/**
	 * Set the editedServiceValue to be passed into the service card component.
	 * Also sets the editServiceIndex to know which service to edit, and to display that service when looping through list of services.
	 */
	editService(index) {
		this.editedServiceValue = structuredClone(this.services[index]);
		this.editServiceIndex = index;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This method will save the updated services whenever the member inputs a new value. It will first check if the service already exists for that date, and if it does it will set the ServiceAlreadyExists property to display an error.
	 * Next it will find the service type and update the service to be saved to the claim.
	 */
	updateEditService(service, index) {
		// Check if the member has already entered the service base on service type and date.
		// If they have then display an error.

		if (!this.isPWA) {
			this.serviceAlreadyExists = false;
			for (let i = 0; i < this.services.length; i++) {
				// This is to make sure it doesn't complain that the service being edited already exists.
				if (i !== index) {
					if (
						service.dateOfService &&
						this.services[i].dateOfService === service.dateOfService.replaceAll('-', '')
					) {
						if (service && service.descriptionRbKey === this.services[i].descriptionRbKey) {
							this.serviceAlreadyExists = true;
						}
					}
				}
			}
		}
		if (service) {
			const serviceType = this.serviceTypes.find(
				(serviceToSearch) => serviceToSearch.value === service.descriptionRbKey
			);
			if (serviceType?.service) {
				this.editedServiceValue = {
					categoryId: serviceType.service.categoryId,
					categoryUiId: serviceType.service.categoryUiId,
					description: serviceType.service.description,
					descriptionRbKey: serviceType.service.descriptionRbKey,
					isMasterService: serviceType.service.isMasterService,
					isRequirePrescription: serviceType.service.isRequirePrescription,
					isServiceTypeOther: serviceType.service.isServiceTypeOther,
					languageCode: serviceType.service.languageCode,
					pocLobCode: serviceType.service.pocLobCode,
					pocLobId: serviceType.service.pocLobId,
					programCd: serviceType.service.programCd,
					programId: serviceType.service.programId,
					serviceCode: serviceType.service.serviceCode,
					serviceId: serviceType.service.serviceId,
					specialtyCode: serviceType.service.specialtyCode,
					specialtyId: serviceType.service.specialtyId,
					dateOfService: service.dateOfService.replaceAll('-', ''),
					serviceRow: service.serviceRow,
					amountPaid: service.amountPaid,
					amountPaidByOtherCarrier: service.amountPaidByOtherCarrier,
					wasSubmittedToOtherFirst: service.wasSubmittedToOtherFirst,
					cobOtherReason: service.cobOtherReason,
					cobOtherReasonNote: service.cobOtherReasonNote,
					hasPrescription: service.hasPrescription
				};
			}
		}
	}

	/**
	 * This method saves the service the member updated to the array of services, and clears the edited values.
	 */
	saveEditedService() {
		if (this.editedServiceValue.hasPrescription === false) {
			this.$emit('showPrescriptionModal');
		}
		if (this.currencyCheck(this.editedServiceValue)) {
			this.services[this.editServiceIndex] = this.editedServiceValue;
			this.editedServiceValue = null;
			this.editServiceIndex = null;
			this.changeNextStatus(this.checkNextStatus());
		}
	}

	/**
	 * This will remove the editServiceIndex so that no services will be edited.
	 */
	cancelEdit() {
		this.editServiceIndex = null;
		this.serviceAlreadyExists = false;
		this.editedServiceValue = null;
		this.changeNextStatus(this.checkNextStatus());
	}

	// ---------------------------------------- REMOVE SERVICES -------------------------------------------

	confirmRemoveService(service, index) {
		this.showConfirmRemoveServiceModal = true;
		this.serviceToRemove = service;
		this.serviceToRemoveIndex = index;
	}
	/**
	 * Remove service from list and also change availability of items
	 * @param id Integer service location in array
	 */
	removeService() {
		const serviceIndex = this.services.findIndex((service) => {
			return (
				service.descriptionRbKey === this.serviceToRemove.descriptionRbKey &&
				service.amountPaid === this.serviceToRemove.amountPaid
			);
		});

		if (this.services[serviceIndex].$id) {
			BenefitService.removeBenefitServiceById(this.services[serviceIndex].id);
		}

		//remove service from array
		this.services.splice(this.serviceToRemoveIndex, 1);
		this.showConfirmRemoveServiceModal = false;
	}

	/**
	 * Cancel removing the service and hide the modal.
	 */
	cancelRemove() {
		this.showConfirmRemoveServiceModal = false;
		this.serviceToRemove = null;
		this.serviceToRemoveIndex = null;
	}

	// ---------------------------------------- ADDITIONAL METHODS. -------------------------------------------

	/**
	 * This method is called when the member enters an amount lower than $1, but wants to proceed with the claim.
	 */
	saveServiceLowAmount() {
		if (this.showAddService) {
			this.services.push(this.addServiceValue);
			this.cancelAddService();
		} else if (this.editServiceIndex !== null) {
			this.services[this.editServiceIndex] = this.editedServiceValue;
			this.editedServiceValue = null;
			this.editServiceIndex = null;
			this.changeNextStatus(this.checkNextStatus());
		} else {
			this.$emit('saveService');
		}
		this.showAmountErrorModal = false;
	}

	/**
	 * toggle the modal on the screen.
	 */
	toggleModal() {
		this.modalShow = !this.modalShow;
	}

	closeMaxAmountModal() {
		this.showMaxAmountErrorModal = false;
		this.$emit('closeModal');
	}

	/**
	 * Navigate to the claim type screen.
	 * This is used by the modal if the member does not have a prescription
	 */
	navigateToClaimType() {
		this.$store.dispatch('submitClaimAdditionalQs', {});
		this.$router.push({ name: CLAIM_TYPE });
	}

	/**
	 * Used to disabled the button that adds services if the amount has been exceed
	 */
	isVisible() {
		this.changeNextStatus(this.checkNextStatus());
		if (this.claim?.claimType === Claimtypes.PWA && this.serviceCount > 8) {
			return false;
		} else if (
			this.claim?.claimType === Claimtypes.HEALTH &&
			this.activeServiceTypesCount < 2 &&
			this.servicesMatchServiceTypes()
		) {
			return false;
		}
		return true;
	}

	currencyCheck(service) {
		if (
			(!isNaN(service.amountPaid) &&
				service.amountPaid < this.minAmount &&
				service.amountPaid !== 0) ||
			(!isNaN(service.amountPaidByOtherCarrier) &&
				service.amountPaidByOtherCarrier < this.minAmount &&
				service.amountPaidByOtherCarrier !== 0 &&
				service.amountPaidByOtherCarrier !== null)
		) {
			this.showAmountErrorModal = true;
			return false;
		} else if (
			!isNaN(service.amountPaid) &&
			service.amountPaid >= this.maxAmount &&
			service.amountPaid !== 0
		) {
			this.showMaxAmountErrorModal = true;
			return false;
		}
		return true;
	}

	/**
	 * Used to give focus to the currencyInput which the user has prompted was wrong
	 */
	focusZeroCurrency() {
		this.showAmountErrorModal = false;
		this.$emit('closeModal');
		let inputs = document.querySelectorAll('input');
		let target = null;
		inputs.forEach((input) => {
			let formattedValue = input.value.replace('$', '');
			if (input.type === 'text' && formattedValue < 1) {
				target = input;
			}
		});
		// if (this.currencyInputTarget) target = document.getElementById(this.currencyInputTarget);
		this.$nextTick(() => {
			setTimeout(function () {
				if (target) target.focus();
			}, 200);
		});
	}

	checkNextStatus() {
		// If the member is editing a service then disable the next button.
		if (this.editServiceIndex !== null || this.showAddService === true) {
			return false;
		}
		let hasAllServices = false;
		let hasService = 0;
		let noService = 0;
		this.services.forEach((service) => {
			if (hasAllServices) return;
			if (service.amountPaid > 0 && service.descriptionRbKey) {
				hasService++;
			} else {
				noService++;
			}
		});
		if (noService === 0 && hasService > 0) {
			hasAllServices = true;
		}
		return hasAllServices;
	}

	changeNextStatus(state) {
		this.$emit('changeNextStatus', state);
	}
}
</script>
<style lang="scss" scoped>
* ::v-deep .org-name-or-member-name {
	font-family: $font-family-headline;
	color: $black;
}
.border-top {
	border-top: 1px solid $gray-light;
}
.remove-error-modal ::v-deep(.modal-dialog) {
	max-width: 590px;
}
.remove-error-modal ::v-deep(.modal-content) {
	width: 100%;
}
.remove-error-modal ::v-deep(.service-list-item) {
	background-color: $very-light-grey;
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 20px;
}

.service-card ::v-deep(.card-body) {
	padding: 0;
}
.edit-service-card {
	padding: 30px;
	border-top: 1px solid $gray-light;
}
</style>
<i18n>
{
  "en": {
		"buttonAdd": "Add another {serviceType} claim for {name}",
		"editTitle": "Edit Service",
		"modal": {
			"title": "Before we can process your claim:",
			"text": "For your claim to be eligible for reimbursement, you'll need to submit a prescription from a health professional. If you already have the prescription, please include it with your claim documents.",
			"button": "Try again later",
			"removeTitle": "Are you sure you want to remove this from your claim?"
		},
		"error": {
			"minTitle": "This cost seems low.",
			"min" : "You've entered an amount less than $1.00. Is that correct?",
			"maxTitle": "This cost seems high.",
			"max": "The total cost you entered is too high for an online claim. Please double-check the amount entered and try again.",
			"btn": {
				"yes": "Yes",
				"no": "No",
				"okay": "OK"
			}
		}
  },
  "fr": {
		"buttonAdd": "Ajouter une demande de règlement pour des services de {serviceType} pour {name}",
		"editTitle": "Modifier le service",
		"modal": {
			"title": "Avant que nous puissions traiter votre demande de règlement :",
			"text": "Afin que votre demande de règlement soit admissible au remboursement, vous devez soumettre une ordonnance d'un professionnel de la santé. Si vous avez déjà l'ordonnance, veuillez l'inclure avec vos documents de demande de règlement.",
			"button": "Veuillez réessayer plus tard",
			"removeTitle": "Voulez-vous vraiment supprimer cela de votre demande de règlement?"
		},
		"error": {
			"minTitle": "Ce coût semble trop bas.",
			"min" : "Vous avez entré un montant de moins de 1 $. Est-ce le bon montant?",
			"maxTitle": "Ce coût semble trop élevé.",
			"max": "Le coût total que vous avez entré est trop élevé pour une demande de règlement en ligne. Veuillez vérifier le montant entré et réessayer.",
			"btn": {
				"yes": "Oui",
				"no": "Non",
				"okay": "OK"
			}
		}
  }
}
</i18n>
