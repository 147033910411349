<template>
	<BaseSelect
		:label="label"
		:value="value ? value : ''"
		:options="services"
		:placeholder="$t('label.placeholder')"
		:validation-rules="validationRules"
		:validation-messages="validationMessages"
		:automation-id="getAutomationId('services')"
		:focus-on-load="requiresFocus"
		:disabled="disabled"
		@change="onChange"
		@blur="onBlur"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseSelect from '@/components/common/base/BaseSelect';
import IdMixin from '@/mixins/id';

@Component({
	name: 'ServiceSelect',
	mixins: [IdMixin],
	components: {
		BaseSelect
	},
	props: {
		label: {
			type: String,
			default: ''
		},
		value: {
			type: [String, Object, Number],
			default: null
		},
		services: {
			type: Array,
			default: () => []
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		/**
		 * sets the focus on load for the dropdown
		 */
		requiresFocusOnLoad: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		}
	}
})
export default class ServiceSelect extends Vue {
	get requiresFocus() {
		return this.requiresFocusOnLoad;
	}

	onChange(newValue) {
		this.$emit('change', newValue);
	}
	onBlur(value) {
		this.$emit('blur', value);
	}
}
</script>
<i18n>
{
	"en": {
		"label": {
			"placeholder": "Please select..."
		}
	},
	"fr": {
		"label": {
			"placeholder": "Veuillez sélectionner..."
		}
	}
}
</i18n>
