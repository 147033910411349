<template>
	<BaseCard>
		<h3 class="h5">{{ $t('title') }}</h3>
		<CheckListItem v-if="typeOfClaims.life" id="life" class="check-list-item">
			<i18n path="life.lifeInsurance.title" tag="span">
				<template #lifeInsuranceClaim>
					<b>{{ $t('life.lifeInsurance.lifeInsuranceClaim') }}</b>
				</template> </i18n
			><template #description>
				<div class="mt-1">
					<ul>
						<li>{{ $t('life.lifeInsurance.bullet1') }}</li>
					</ul>
					<ul>
						<li>{{ $t('life.lifeInsurance.bullet2') }}</li>
					</ul>
				</div>
			</template></CheckListItem
		>
		<CheckListItem
			v-if="typeOfClaims.accidentalDismemberment"
			id="accidental"
			class="check-list-item"
		>
			<i18n path="life.accidental.title" tag="span">
				<template #accidentalClaim>
					<b>{{ $t('life.accidental.accidentalClaim') }}</b>
				</template> </i18n
			><template #description>
				<div class="mt-1">
					<ul>
						<li>{{ $t('life.accidental.bullet1') }}</li>
					</ul>
				</div>
			</template></CheckListItem
		>
		<CheckListItem v-if="typeOfClaims.terminalIllness" id="terminal" class="check-list-item">
			<i18n path="life.terminal.title" tag="span">
				<template #terminalIllnessClaim>
					<b>{{ $t('life.terminal.terminalIllnessClaim') }}</b>
				</template> </i18n
			><template #description>
				<div class="mt-1">
					<ul>
						<li>{{ $t('life.terminal.bullet1') }}</li>
					</ul>
				</div>
			</template></CheckListItem
		>
		<CheckListItem class="checklist-item">
			<h4 class="mb-0 h6">{{ $t('forms.title') }}</h4>
			<template #description>
				<div class="mt-1">
					{{ $t('forms.description') }}
				</div>
				<BaseButton
					automation-id="dci-checklist-button"
					variant="link"
					:label="$t('formsButton')"
					:icon="['fal', 'external-link-alt']"
					icon-position="left"
					class="p-1 pt-2 text-left"
					@click="openFormLink()"
				/>
			</template>
		</CheckListItem>
	</BaseCard>
</template>

<script>
// components
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import CheckListItem from '@/components/common/CheckListItem';

export default {
	name: 'LADDCheckListCard',
	components: {
		BaseButton,
		BaseCard,
		CheckListItem
	},
	props: {
		// Prop is used to know which check list items to show.
		// Types used on this component are: life, accidentalDismemberment, and terminalIllness
		typeOfClaims: {
			type: Object,
			default: null
		}
	},
	methods: {
		openFormLink() {
			if (this.$root.$i18n.locale === 'fr') {
				window.open('https://www.medaviebc.ca/fr/adherents/formulaires', 'blank');
			} else {
				window.open(`https://www.medaviebc.ca/en/members/forms`, 'blank');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.check-list-item ul {
	margin-bottom: 0;
}
.check-list-item ::v-deep(.display-group-label) {
	font-family: $lato-font-family;
	font-size: 14px;
}
.check-list-item ::v-deep(.display-group-text) {
	font-size: 14px;
}
.check-list-item ::v-deep(.check-icon) {
	font-size: 22px;
}
.checklist-item {
	& ul:last-child,
	&:last-child > ::v-deep span {
		margin-bottom: 0;
	}
}
</style>

<i18n>
{
  "en": {
	"title": "Do you have the required documents on hand?",
    "life": {
		"lifeInsurance": {
			"title": "For a {lifeInsuranceClaim}, you will need to upload:",
			"lifeInsuranceClaim": "Life Insurance or Accidental Death claim",
			"bullet1": "A completed Group Life Claim form (FORM - 190)",
			"bullet2": "A death certificate"
		},
		"accidental": {
			"title": "For an {accidentalClaim}, you will need to upload:",
			"accidentalClaim": "Accidental Dismemberment claim",
			"bullet1": "A completed Accidental Dismemberment Claim form (FORM - 202)"
		},
		"terminal": {
			"title": "For a {terminalIllnessClaim}, you will need to upload:",
			"terminalIllnessClaim": "Terminal Illness claim",
			"bullet1": "A completed Terminal Illness Claim form (FORM - 969)"
		}
    },
    "forms": {
		"title": "Do you have any other documents you might need?",
		"description": "If you have other relevant documents, such as medical reports, please have these ready to upload."
		
    },
    "formsButton": "Find forms and more information"
  },
  "fr": {
	"title": "Avez-vous les documents requis à votre disposition?",
	"life": {
		"lifeInsurance": {
			"title": "{lifeInsuranceClaim}, vous devez téléverser les documents suivants :",
			"lifeInsuranceClaim": "Pour une demande de prestations d'assurance vie ou d'assurance décès par accident",
			"bullet1": "Le formulaire Demande de prestation d’assurance vie collective dûment rempli (FORM-190F)",
			"bullet2": "Un certificat de décès"
		},
		"accidental": {
			"title": "{accidentalClaim}, vous devez téléverser les documents suivants : ",
			"accidentalClaim": "Pour une demande de prestations d'assurance mutilation par accident",
			"bullet1": "Le formulaire Demande de règlement pour mutilation par accident (FORM-202F)"
		},
		"terminal": {
			"title": "{terminalIllnessClaim}, vous devez téléverser les documents suivants :",
			"terminalIllnessClaim": "Pour une demande de prestations pour maladie en phase terminale",
			"bullet1": "Le formulaire Demande de prestation pour maladie en phase terminale (FORM-969F)"
		}
    },
    "forms": {
		"title": "Avez-vous d’autres documents dont vous pourriez avoir besoin?",
		"description": "Si vous avez d’autres documents pertinents, comme des rapports médicaux, préparez-les pour les téléverser."
		
    },
    "formsButton": "Formulaires et renseignements supplémentaires"
  }
}
</i18n>
