<template>
	<div>
		<BaseCard class="service-card">
			<BRow v-for="(service, index) in services" :key="service.id" class="m-0">
				<BCol cols="12" class="p-0">
					<!-- If the edit service index is the same as the index in the services then show the edit inputs. -->
					<div v-if="editServiceIndex === index" class="edit-service-card">
						<h3>{{ $t('editTitle') }}</h3>
						<HealthServiceFields
							:value="service"
							:members="participants"
							:provider-type-list="providerTypes"
							:automation-id="getAutomationId('health-claim-details')"
							@save="saveEditedService"
							@cancel="cancelEdit"
						/>
					</div>
					<div v-else>
						<HealthServiceListItem
							:value="service"
							:member="getMember(service.participant)"
							:class="index > 0 ? 'border-top' : null"
							:disable-buttons="showAddService || editServiceIndex !== null"
							:hide-remove="index === 0"
							:automation-id="`health-service-type-${index}`"
							@edit="editService(index)"
							@remove="confirmRemoveService(service, index)"
						/>
					</div>
				</BCol>
			</BRow>
		</BaseCard>
		<BRow v-if="showAddService">
			<BCol>
				<BaseCard>
					<HealthServiceFields
						:members="participants"
						:provider-type-list="providerTypes"
						:automation-id="getAutomationId('health-claim-details')"
						@save="saveAdditionalService"
						@cancel="cancelAddService()"
					/>
				</BaseCard>
			</BCol>
		</BRow>
		<BRow>
			<BCol class="mb-3 ml-1">
				<!-- Only show this button if the member has less that 10 services and are not editing or adding a service -->
				<BaseButton
					v-if="!showAddService && services.length < 10 && editServiceIndex === null"
					:label="btnAddServiceLabel"
					:icon="['fal', 'plus-circle']"
					icon-position="left"
					variant="link"
					icon-size="lg"
					class="pl-0 btn-add-services"
					automation-id="health-service-type-add-service"
					@click="enableAddService"
				/>
			</BCol>
		</BRow>
		<!-- Confirm Remove Modal -->
		<ErrorModal
			class="remove-error-modal"
			:data-test-automation-id="getAutomationId('remove-error-modal')"
			:modal-show="showConfirmRemoveServiceModal"
			:error-title="$t('modal.removeTitle')"
			:has-close-button="false"
			:cant-close-on-esc="true"
		>
			<BRow v-if="serviceToRemove">
				<BCol>
					<HealthServiceListItem
						:value="serviceToRemove"
						:member="getMember(serviceToRemove.participant)"
						:hide-buttons="true"
						:automation-id="`service-list-remove`"
					/>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<BaseButton
						:label="$t('error.btn.no')"
						variant="primary"
						class="mr-2"
						automation-id="service-type-error-no"
						@click="cancelRemove()"
					/>
					<BaseButton
						:label="$t('error.btn.yes')"
						class="mr-auto"
						automation-id="service-type-error-yes"
						@click="removeService()"
					/>
				</BCol>
			</BRow>
		</ErrorModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Components
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import HealthServiceListItem from '@/components/claim/claimService/HealthServiceListItem';
import HealthServiceFields from '@/components/submit-claim/claim-details/HealthServiceFields.vue';
import ErrorModal from '@/components/common/ErrorModal';
import BaseForm from '@/components/common/base/BaseForm';
// Mixin
import IdMixin from '@/mixins/id';
// Utils
import { formatDateForUi } from '@/utils/date';

@Component({
	name: 'HealthClaimDetails',
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		member: {
			type: Object,
			default: () => {}
		},
		participants: {
			type: Array,
			default: null
		},
		providerTypes: {
			type: Array,
			default: null
		},
		claimTypeDescription: {
			type: String,
			default: null
		}
	},
	components: {
		BRow,
		BCol,
		BaseButton,
		BaseCard,
		BaseForm,
		HealthServiceListItem,
		HealthServiceFields,
		ErrorModal
	}
})
export default class HealthClaimDetails extends Vue {
	showAddService = false;
	editedServiceValue = null;
	serviceAlreadyExists = false;
	editServiceIndex = null;
	showConfirmRemoveServiceModal = false;
	serviceToRemoveIndex = null;
	serviceToRemove = null;
	checkCurrencyInputslocal = false;

	// ---------------------------- COMPUTED PROPERTIES ------------------------------

	get claim() {
		return this.value ? this.value : {};
	}

	get services() {
		return this.claim?.manualClaimServiceItems ? this.claim.manualClaimServiceItems : [];
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get btnAddServiceLabel() {
		return this.$t('buttonAdd', { serviceType: this.claimTypeDescription });
	}

	// ---------------------------------------- SAVE ADDITIONAL SERVICES -------------------------------------------

	/**
	 * create new service and set default for items in both locale
	 */
	enableAddService() {
		this.showAddService = true;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This method will cancel adding a service, and clear any values entered by the member.
	 */
	cancelAddService() {
		this.showAddService = false;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This method is used when the member clicks save while adding an additional service.
	 * It saves the new service to the manual Claim service item.
	 */
	saveAdditionalService(newService) {
		this.claim.manualClaimServiceItems.push(newService);
		this.cancelAddService();
	}

	// ---------------------------------------- EDIT EXISTING SERVICES -------------------------------------------

	/**
	 * Set the editedServiceValue to be passed into the service card component.
	 * Also sets the editServiceIndex to know which service to edit, and to display that service when looping through list of services.
	 */
	editService(index) {
		this.editedServiceValue = this.claim.manualClaimServiceItems[index];
		this.editServiceIndex = index;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This method saves the service the member updated to the array of services, and clears the edited values.
	 */
	saveEditedService(editedService) {
		this.claim.manualClaimServiceItems[this.editServiceIndex] = editedService;
		this.editServiceIndex = null;
		this.changeNextStatus(this.checkNextStatus());
	}

	/**
	 * This will remove the editServiceIndex so that no services will be edited.
	 */
	cancelEdit() {
		this.editServiceIndex = null;
		this.changeNextStatus(this.checkNextStatus());
	}

	// ---------------------------------------- REMOVE SERVICES -------------------------------------------

	confirmRemoveService(service, index) {
		this.showConfirmRemoveServiceModal = true;
		this.serviceToRemove = service;
		this.serviceToRemoveIndex = index;
	}
	/**
	 * Remove service from list and also change availability of items
	 * @param id Integer service location in array
	 */
	removeService() {
		//remove service from array
		this.claim.manualClaimServiceItems.splice(this.serviceToRemoveIndex, 1);
		this.showConfirmRemoveServiceModal = false;
		this.serviceToRemoveIndex = null;
	}

	/**
	 * Cancel removing the service and hide the modal.
	 */
	cancelRemove() {
		this.showConfirmRemoveServiceModal = false;
		this.serviceToRemove = null;
		this.serviceToRemoveIndex = null;
	}

	// ---------------------------------------- ADDITIONAL METHODS. -------------------------------------------

	/**
	 * This method retrieves the participants first name and date of birth to be used in the list of service items.
	 */
	getMember(participantId) {
		const participant = this.participants?.find(
			(participant) => participant.participantId === participantId
		);
		let member = {};
		member.firstName = participant.firstName;
		member.fullName = `${participant.firstName} ${participant.lastName}`;
		member.dob = formatDateForUi(participant.dateOfBirth, this.$store.state.i18n.locale);
		return member;
	}

	checkNextStatus() {
		// If the member is editing a service then disable the next button.
		if (this.editServiceIndex !== null || this.showAddService === true) {
			return false;
		}
		return true;
	}

	changeNextStatus(state) {
		this.$emit('changeNextStatus', state);
	}
}
</script>
<style lang="scss" scoped>
* ::v-deep .org-name-or-member-name {
	font-family: $font-family-headline;
	color: $black;
}
.border-top {
	border-top: 1px solid $gray-light;
}
.remove-error-modal ::v-deep(.modal-dialog) {
	max-width: 590px;
}
.remove-error-modal ::v-deep(.modal-content) {
	width: 100%;
}
.remove-error-modal ::v-deep(.service-list-item) {
	background-color: $very-light-grey;
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 20px;
}

.service-card ::v-deep(.card-body) {
	padding: 0;
}
.edit-service-card {
	padding: 30px;
	border-top: 1px solid $gray-light;
}
</style>
<i18n>
{
  "en": {
		"buttonAdd": "Add another {serviceType} claim for any plan member",
		"editTitle": "Edit claim",
		"modal": {
			"removeTitle": "Are you sure you want to remove this from your claim?"
		},
		"error": {
			"btn": {
				"yes": "Yes",
				"no": "No"
			}
		}
  },
  "fr": {
		"buttonAdd": "Ajouter une demande de règlement pour des services de {serviceType} pour n'importe quel adhérent",
		"editTitle": "Modifier votre demande",
		"modal": {
			"removeTitle": "Voulez-vous vraiment supprimer cela de votre demande de règlement?"
		},
		"error": {
			"btn": {
				"yes": "Oui",
				"no": "Non"
			}
		}
  }
}
</i18n>
