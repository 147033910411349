var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseForm',{ref:"service-card-form",attrs:{"data-tracking-id":_vm.getAutomationId('service-card-form'),"automation-id":_vm.getAutomationId('service-card-form')},on:{"submit":_vm.saveService}},[_c('BRow',[(_vm.showSaveButtons && !_vm.hideDatePicker)?_c('BCol',{attrs:{"cols":"10"}},[_c('BaseDatePicker',{ref:"toDate",staticClass:"datepicker input-margin",attrs:{"datepicker-id":"claim-date-id","value":_vm.formattedDate,"label":_vm.datePickerLabel,"placeholder":_vm.$t('placeholder'),"start-date":false,"required":true,"max-error-date":_vm.getMaxDate(),"max-selectable-date":_vm.getMaxDate(),"required-error-msg":_vm.$t('error.requiredClaimDate'),"before-max-date-error-msg":_vm.$t('error.future'),"pill":"","is-left":"","automation-id":_vm.getAutomationId('service-card')},on:{"input":_vm.updateDateOfService}})],1):_vm._e()],1),_c('BRow',[_c('BCol',{attrs:{"cols":"10"}},[_c('BFormGroup',{staticClass:"m-0"},[_c('ServiceSelect',{staticClass:"input",attrs:{"label":_vm.serviceLabel,"value":_vm.descriptionRbKey,"services":_vm.availableServices,"validation-rules":{ required: true },"validation-messages":{ required: _vm.$t('error.serviceRequired') },"placeholder":_vm.$t('label.placeholder'),"automation-id":_vm.getAutomationId('service-card')},on:{"change":_vm.serviceChanged}}),(_vm.showMoreThanOneServiceError)?_c('ValidationProvider',{attrs:{"id":"service-exists-validation","rules":"required"}},[_c('div',{staticClass:"d-block error-message",attrs:{"id":"service-exists-error"}},[_vm._v(" "+_vm._s(_vm.$t('error.serviceExists'))+" ")])]):_vm._e()],1)],1)],1),_c('BRow',{staticClass:"input-row"},[_c('BCol',{attrs:{"cols":"10"}},[_c('CurrencyInput',{staticClass:"input",attrs:{"id":"amount-input","label":_vm.currencyLabel,"value":_vm.amountPaid,"symbole":true,"min-value":-1,"allow-zero":"","validation-rules":{ required: true, isMinValue: 0.01 },"validation-messages":{
					required: _vm.$t('error.currencyRequired'),
					isMinValue: _vm.$t('error.valid')
				},"automation-id":_vm.getAutomationId('service-card')},on:{"change":_vm.currencyChanged}})],1)],1),(_vm.requirePrescription || _vm.showRequirePrescription)?_c('BRow',{staticClass:"input-row"},[_c('BCol',{attrs:{"cols":"10"}},[_c('YesNoToggle',{staticClass:"mb-0",attrs:{"value":_vm.hasPrescription,"label":_vm.$t('label.prescription'),"stacked":"","is-required":"","required-error-msg":_vm.$t('error.required'),"automation-id":_vm.getAutomationId('service-card-prescription')},on:{"input":_vm.savePrescription}})],1)],1):_vm._e(),(_vm.showPaidByOtherPolicy)?_c('BRow',{staticClass:"input-row"},[_c('BCol',{attrs:{"cols":"10"}},[_c('YesNoToggle',{staticClass:"mb-0",attrs:{"value":_vm.paidByOtherPolicy,"label":_vm.$t('label.paidByOtherPolicy'),"stacked":"","is-required":"","required-error-msg":_vm.$t('error.required'),"automation-id":_vm.getAutomationId('service-card-paid-other-policy')},on:{"input":_vm.onPaidByOtherInput}})],1)],1):_vm._e(),(_vm.paidByOtherPolicy)?_c('BRow',{staticClass:"input-row"},[_c('BCol',{attrs:{"cols":"12"}},[_c('CurrencyInput',{staticClass:"currency-input input",attrs:{"id":"cob-amount-input","min-value":-1,"label":_vm.$t('label.amountPaidByOtherPolicy'),"placeholder":_vm.$t('label.amountPlaceholder'),"value":_vm.amountPaidByOtherCarrier,"validation-rules":{
					required: true,
					isANumber: true,
					isMinValue: 0.01
				},"validation-messages":{
					required: _vm.$t('error.cobAmountCovered'),
					isMinValue: _vm.$t('error.cobAmountCovered')
				},"automation-id":_vm.getAutomationId('service-card-cob')},on:{"change":_vm.checkCobAmount}})],1)],1):_vm._e(),(_vm.paidByOtherPolicy === false)?_c('BRow',{staticClass:"input-row"},[_c('BCol',{attrs:{"cols":"10"}},[_c('BRow',[_c('BCol',[_c('BaseSelect',{staticClass:"mb-0 input",attrs:{"label":_vm.$t('label.reasonNotCovered'),"placeholder":_vm.$t('label.placeholder'),"validation-rules":{ required: true },"validation-messages":{
							required: _vm.$t('error.reasonRequired')
						},"value":_vm.selectClaimNotCovered.value,"options":_vm.selectClaimNotCovered.options,"automation-id":_vm.getAutomationId('service-card-cob')},on:{"change":_vm.onCOBReasonsChange}})],1)],1),(_vm.showCobReasonRequireNote)?_c('BRow',{staticClass:"other-reason-row"},[_c('BCol',[_c('BaseInput',{staticClass:"mb-0 input",attrs:{"label":_vm.$t('label.requireNote'),"value":_vm.value.cobOtherReasonNote,"max-length":250,"validation-rules":{ required: true, max: 250 },"validation-messages":{
							required: _vm.$t('error.reasonNotCovered'),
							max: _vm.$t('error.cobNoteMaxLength', [250])
						},"automation-id":_vm.getAutomationId('service-card')},on:{"input":_vm.onCOBReasonNoteChange}})],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.showSaveButtons)?_c('BRow',[_c('BaseButton',{staticClass:"button button-save p-0",attrs:{"label":_vm.$t('button.save'),"aria-label":_vm.$t('button.save'),"variant":"link","automation-id":_vm.getAutomationId('service-card-save'),"type":"submit"}}),_c('BaseButton',{staticClass:"button p-0",attrs:{"label":_vm.$t('button.cancel'),"aria-label":_vm.$t('button.cancel'),"variant":"link","automation-id":_vm.getAutomationId('service-card-cancel')},on:{"click":_vm.cancelService}})],1):_vm._e(),_c('ErrorModal',{attrs:{"data-test-automation-id":_vm.getAutomationId('prescription-required-modal'),"modal-show":_vm.showErrorModal,"error-title":_vm.$t('error.maxValueHeader'),"error-message":_vm.$t('error.maxValue'),"has-close-button":true},on:{"change":_vm.closeModal}},[_c('BaseButton',{staticClass:"mt-2",attrs:{"label":_vm.$t('label.close'),"aria-label":_vm.$t('label.close'),"variant":"primary","automation-id":"noClaimHistoryButton"},on:{"click":_vm.closeModal}})],1),_c('ErrorModal',{attrs:{"automation-id":"CobClaimErrorModal","error-title":_vm.claimCancelledError.title,"error-message":_vm.claimCancelledError.message,"modal-show":_vm.showCobModal,"no-hide-on-blur":""},on:{"change":_vm.closeCancelClaimModal}},[_c('BaseButton',{attrs:{"automation-id":"cancelClaimModalButton","label":_vm.$t('label.close'),"variant":"primary"},on:{"click":_vm.closeCancelClaimModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }