var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseCard',[_c('ProviderCard',{staticClass:"mb-0",attrs:{"member":_vm.member,"show-provider":false,"automation-id":"claim-details-hsa-provider"}}),(_vm.showReminder)?_c('BaseAlert',{staticClass:"reminder",attrs:{"variant":"warning","alternate":""},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(_vm._s(_vm.$t('label.submitCobFirst')))]},proxy:true}],null,false,3229233775)},[_vm._v(" "+_vm._s(_vm.$t('label.friendlyReminder'))+" ")]):_vm._e()],1),_c('BaseCard',[_c('HsaServiceListItem',{staticClass:"service-list-item",attrs:{"value":_vm.services,"lob-labels":_vm.labels,"automation-id":`claim-details-hsa-service`}})],1),(_vm.showOtherCarrierPaid)?_c('BaseCard',[_c('BRow',[_c('BCol',{attrs:{"cols":"10"}},[_c('YesNoToggle',{staticClass:"mb-0",attrs:{"value":_vm.newValues.wasSubmittedToOtherFirst,"label":_vm.$t('label.wasSubmittedToOtherFirst'),"stacked":"","is-required":"","required-error-msg":_vm.$t('error.required'),"automation-id":_vm.getAutomationId('service-card-paid-other-policy')},on:{"input":_vm.updateWasSubmittedToOtherFirst}})],1)],1)],1):_vm._e(),(_vm.newValues.wasSubmittedToOtherFirst)?_c('BaseCard',[_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('CurrencyInput',{staticClass:"currency-input input",attrs:{"id":"cob-amount-input","min-value":-1,"label":_vm.$t('label.amountPaidByOtherPolicy'),"placeholder":_vm.$t('label.amountPlaceholder'),"value":_vm.newValues.amountPaidByOtherCarrier,"validation-rules":{
						required: true,
						isANumber: true,
						isMinValue: 0.01,
						isMaxValue: _vm.costOfService
					},"validation-messages":{
						required: _vm.$t('error.cobAmountCovered'),
						isMinValue: _vm.$t('error.valid'),
						isMaxValue: _vm.$t('error.max')
					},"automation-id":_vm.getAutomationId('service-card-cob')},on:{"change":_vm.updateAmountPaidByOtherCarrier}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }