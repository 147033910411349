<template>
	<div class="success-claim">
		<Result
			:automation-id="getAutomationId('success-claim')"
			class="mt-4 mb-0"
			:title="$t('success.title')"
			icon-color="success-color"
			:hide-button="true"
		>
			<span v-if="contentSimple" class="content-simple">{{ content }}</span>
			<div v-else class="content">
				<h2>{{ content.title }}</h2>
				<ul>
					<li v-for="item in content.listItems" :key="item">{{ item }}</li>
				</ul>
			</div>
		</Result>
		<div class="d-flex flex-wrap">
			<BaseButton
				class="mb-2 mr-3"
				:label="$t('button.claim')"
				variant="primary"
				:automation-id="getAutomationId('result-claim')"
				@click="toClaims()"
			/>
			<BaseButton
				class="mb-2"
				:label="$t('button.homepage')"
				variant="link"
				:automation-id="getAutomationId('result-homepage')"
				@click="toHomepage()"
			/>
		</div>
	</div>
</template>

<script>
// This component renders the successful registration screen.
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id';
import { CLAIM_TYPE, DASHBOARD } from '@/constants/Routes';
import { TRAVEL, DCI, LADD } from '@/constants/ClaimTypes.js';
import Claims from '@/models/claims/Claims';

// @vue/component
@Component({
	name: 'ClaimSuccessPage',
	components: {
		Result,
		BaseButton
	},
	mixins: [IdMixin]
})
export default class SuccessClaimPage extends Vue {
	contentSimple = null;
	claim = null;

	async created() {
		// Set the claim object on the component to know which content to show based on the claim type.
		this.claim = await Claims.getClaimByEmail(sessionStorage.getItem('email'));
		// Clear the claim from vuex orm/vue store so the member can start fresh with a new claim on their next claim submittal.
		await Claims.clearClaim();
		this.$store.dispatch('submitClaimFiles', []);
		this.$store.dispatch('submitClaimCOB', {});
		this.$store.dispatch('submitClaimAdditionalDocuments', {});
		this.$store.dispatch('submitClaimAdditionalQs', {});
	}

	/**
	 * Navigation methods.
	 */
	toClaims() {
		this.$router.push({ name: CLAIM_TYPE });
	}

	toHomepage() {
		this.$router.push({ name: DASHBOARD });
	}

	/**
	 * computed variable to determine which content to display.
	 */
	get content() {
		switch (this.claim?.claimType) {
			case TRAVEL:
				this.contentSimple = true;
				return this.$t('success.contentTravel');
			case DCI:
			case LADD:
				this.contentSimple = false;
				return {
					title: this.$t('success.dciTitle'),
					listItems: [this.$t('success.dciListItem1'), this.$t('success.dciListItem2')]
				};
			default:
				this.contentSimple = true;
				return this.$t('success.content');
		}
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
}
</script>

<style lang="scss" scoped>
h2 {
	font-size: 24px;
}
.success-claim {
	max-width: 540px;
	margin: 0 auto;
}
.content-simple {
	font-family: $josefin-font-family;
	font-size: 18px;
}
</style>

<i18n>
{
    "en": {
        "success": {
            "title": "Your claim has been submitted!",
            "content": "You'll be notified by email of any decisions made on your claim.",
            "contentTravel": "Thank you for submitting your claim for processing with Blue Cross Travel Assistance.",
			"dciTitle": "What's next?",
			"dciListItem1": "You should see a confirmation message in your email inbox shortly.",
			"dciListItem2": "A Medavie Blue Cross representative will contact you within 10 business days to continue processing your claim."
        },
        "button": {
            "claim": "Submit another claim",
            "homepage": "Return to homepage >"
        }
    },
    "fr": {
        "success": {
            "title": "Votre demande de règlement a été soumise!",
            "content": "Nous vous informerons par courriel lorsqu'une décision aura été prise quant à votre demande de règlement.",
            "contentTravel": "Votre demande de règlement a bien été soumise à l'Assistance voyage Croix Bleue.",
			"dciTitle": "Quelle est la prochaine étape?",
			"dciListItem1": "Vous devriez bientôt recevoir un message de confirmation dans votre boîte de courriels. ",
			"dciListItem2": "Une personne-ressource de Croix Bleue Medavie communiquera avec vous d'ici 10 jours ouvrables pour poursuivre le traitement de votre demande de prestations."
        },
        "button": {
            "claim": "Soumettre une autre demande de règlement",
            "homepage": "Retour à la page d'accueil >"
        }
    }
}
</i18n>
