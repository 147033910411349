<!--

  Display a checkbox icon with text and an optional description.

  Example:

    <CheckListItem>
			Do you have any other required documents you might need?
			<template #description>
        For some benefits, you might need to also send us a prescription, itemized statement, or
        medical evaluation. Not sure if this applies to you? You can find the specific requirements
        for each benefit under their coverage description.
      </template>
		</CheckListItem>

 -->

<template>
	<div class="d-flex check-list-item">
		<FontAwesomeIcon
			class="check-icon"
			:icon="['fal', 'check-square']"
			aria-hidden="true"
		></FontAwesomeIcon>
		<span class="display-group" :data-test-automation-id="getAutomationId('displayGroup')">
			<label :for="`check-item-${slotText}`" class="display-group-label">
				<slot></slot>
				<span v-if="$slots['icon']" class="label-icon"><slot name="icon"></slot></span>
			</label>
			<section
				v-if="$slots['description']"
				:id="`check-item-${slotText}`"
				class="display-group-text"
			>
				<slot name="description"></slot>
			</section>
		</span>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
export default {
	name: 'CheckListItem',
	components: {},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		// Id to be used if no slot text exists to create a unique Id.
		// These id's are used by automation to target specific fields,
		id: {
			type: String,
			default: null
		}
	},

	computed: {
		// The slot text is used to map the label to the text for accessibility.
		slotText() {
			if (this.$slots?.default) {
				const slotText = this.$slots?.default[0]?.text;
				if (slotText) {
					return slotText.replace(/\s/g, '-');
				} else if (this.id) {
					return this.id;
				}
			}
			return null;
		}
	}
};
</script>

<style lang="scss" scoped>
.check-icon {
	font-size: 18px;
	margin-top: 2px;
	margin-right: 10px;
	color: $gray;
}

// Make the icon inline because the modals use "div" (block) and push the icon down.
.label-icon > div {
	display: inline;
}

// The modal icons had a BS pl-2 (with important).  Need to shift icon left to match design.
::v-deep .label-icon .svg-inline--fa {
	margin-left: -5px;
}

.display-group {
	margin-top: 0 !important; // Important used to override the card-body nth-child display-group margin.
	margin-bottom: 20px;
}

.display-group-label {
	line-height: 1.2;
}
</style>
