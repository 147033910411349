<template>
	<button class="card-button mb-3" @click="chooseProvider">
		<BaseCard class="m-0">
			<BRow>
				<BCol cols="10" xl="11" lg="11" md="11">
					<h3 class="mb-0">{{ providerFullName }}</h3>
				</BCol>
				<BCol cols="2" xl="1" lg="1" md="1">
					<font-awesome-icon
						:icon="['fal', 'chevron-right']"
						class="ml-auto icon"
					></font-awesome-icon>
				</BCol>
			</BRow>
			<p>{{ provider.providerTypeName }}</p>
			<div class="d-block d-sm-flex flex-wrap">
				<div class="flex-grow-1 provider-info">
					<div v-if="provider.organizationName && provider.firstName">
						{{ provider.organizationName }}
					</div>
					<div>{{ provider.addressLine1 }}</div>
					<div :v-if="provider.addressLine2">
						{{ provider.addressLine2 }}
					</div>
					<div>{{ provider.city }}, {{ provider.province }} {{ formattedPostal }}</div>
				</div>
				<div>
					{{ formattedNumber }}
				</div>
			</div>
		</BaseCard>
	</button>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { formatPhoneNumberWithParentheses } from '@/utils/commonUtils.js';
import BaseCard from '@/components/common/card/BaseCard';
import { BRow, BCol } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'ProviderSearchResult',
	components: {
		BaseCard,
		BCol,
		BRow
	},
	mixins: [IdMixin],
	props: {
		provider: {
			type: Object,
			default: () => {}
		}
	}
})
export default class ProviderSearchResult extends Vue {
	chooseProvider() {
		this.$emit('choice');
	}
	get formattedPostal() {
		const pCode = this.provider.postalCode;
		return `${pCode.substring(0, 3)} ${pCode.substring(3, 6)}`;
	}
	get formattedNumber() {
		if (this.provider.phoneNumber) {
			return formatPhoneNumberWithParentheses(this.provider.phoneNumber);
		}
		return '';
	}
	get providerFullName() {
		if (!this.provider.firstName) {
			return this.provider.organizationName;
		}
		return `${this.provider.firstName} ${this.provider.lastName}`;
	}
}
</script>

<style lang="scss" scoped>
h3 {
	color: $blue;
}
div,
p {
	font-weight: 300;
}
.provider-info > div:first-child {
	font-weight: 400;
}
.card-button {
	text-align: left;
	background: transparent;
	border: none;
	border-radius: 10px;
	margin: 0;
	padding: 0;
	width: 100%;
	&:hover {
		& > div {
			box-shadow: 0px 0px 10px $blue-light;
		}
		& h3 {
			color: $dark-blue;
		}
	}
}
.icon {
	font-size: 1.5em;
}
.icon ::v-deep path {
	color: $blue;
}
</style>
