<template>
	<ErrorModal
		:data-test-automation-id="getAutomationId('wrong-provider-type-modal')"
		:modal-show="showWrongProviderTypeModal"
		:has-close-button="true"
		:error-title="$t('wrongProviderType.title')"
		:error-message="$t('wrongProviderType.textOne')"
		@change="closeModal"
	>
		<p class="wrong-provider-paragraph-two">{{ $t('wrongProviderType.textTwo') }}</p>
		<BaseButton
			variant="primary"
			class="yes-change-provider-button"
			:label="$t('wrongProviderType.buttonYes')"
			pill
			is-right
			type="submit"
			:automation-id="getAutomationId('wrong-provider-type-modal-yes')"
			data-tracking-id="wrong-provider-type-modal-yes-button"
			@click="changeSavedValueAndGoToNextPage"
		></BaseButton>
		<BaseButton
			variant="secondary"
			class="let-me-pick-again-button"
			:label="$t('wrongProviderType.buttonNo')"
			pill
			is-right
			type="submit"
			:automation-id="getAutomationId('wrong-provider-type-modal-no')"
			data-tracking-id="wrong-provider-type-modal-no-button"
			@click="goBackToProviderTypeChoice"
		></BaseButton>
	</ErrorModal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton.vue';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'WrongProviderTypeModal',
	components: {
		BaseButton,
		ErrorModal
	},
	mixins: [IdMixin],
	props: {
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			default: ''
		},

		showWrongProviderTypeModal: {
			type: Boolean,
			default: false
		}
	}
})
export default class WrongProviderTypeModal extends Vue {
	closeModal() {
		this.$emit('closeModal');
	}
	changeSavedValueAndGoToNextPage() {
		this.$emit('changeSavedValueAndGoToNextPage');
	}
	goBackToProviderTypeChoice() {
		this.$emit('goBackToProviderTypeChoice');
	}
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
}
</script>

<style lang="scss" scoped>
.wrong-provider-paragraph-two {
	margin-bottom: 30px;
}
.yes-change-provider-button {
	margin-bottom: 10px;
	display: block;
}
.let-me-pick-again-button {
	padding: 0.6rem 1rem !important;
}
</style>
<i18n>
	{
    "en": {
        "wrongProviderType": {
            "title": "Is this the right provider type?",
            "textOne": "This provider is listed under a different type than what you initially selected.",
            "textTwo": "Would you like us to change your selection to this new provider type?",
            "buttonYes": "Yes, change it for me",
            "buttonNo": "No, let me choose again"
        }
	},
	"fr": {
        "wrongProviderType": {
            "title": "S’agit-il du bon type de fournisseur?",
            "textOne": "Ce fournisseur est associé à un type différent de celui que vous avez initialement sélectionné.",
            "textTwo": "Souhaitez-vous que nous modifiions votre sélection pour le nouveau type de fournisseur?",
            "buttonYes": "Oui, changez le type pour moi",
            "buttonNo": "Non, laissez-moi choisir à nouveau"
        }
	}
}
</i18n>
