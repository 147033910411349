<template>
	<BaseForm
		ref="service-card-form"
		:data-tracking-id="getAutomationId('service-card-form')"
		:automation-id="getAutomationId('service-card-form')"
		@submit="saveService"
	>
		<BRow>
			<!-- The date picker only shows up if the member is adding a second service in the claim flow service. -->
			<BCol v-if="showSaveButtons && !hideDatePicker" cols="10">
				<BaseDatePicker
					ref="toDate"
					datepicker-id="claim-date-id"
					class="datepicker input-margin"
					:value="formattedDate"
					:label="datePickerLabel"
					:placeholder="$t('placeholder')"
					:start-date="false"
					:required="true"
					:max-error-date="getMaxDate()"
					:max-selectable-date="getMaxDate()"
					:required-error-msg="$t('error.requiredClaimDate')"
					:before-max-date-error-msg="$t('error.future')"
					pill
					is-left
					:automation-id="getAutomationId('service-card')"
					@input="updateDateOfService"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="10">
				<BFormGroup class="m-0">
					<ServiceSelect
						class="input"
						:label="serviceLabel"
						:value="descriptionRbKey"
						:services="availableServices"
						:validation-rules="{ required: true }"
						:validation-messages="{ required: $t('error.serviceRequired') }"
						:placeholder="$t('label.placeholder')"
						:automation-id="getAutomationId('service-card')"
						@change="serviceChanged"
					/>
					<ValidationProvider
						v-if="showMoreThanOneServiceError"
						id="service-exists-validation"
						rules="required"
					>
						<div id="service-exists-error" class="d-block error-message">
							{{ $t('error.serviceExists') }}
						</div>
					</ValidationProvider>
				</BFormGroup>
			</BCol>
		</BRow>
		<BRow class="input-row">
			<BCol cols="10">
				<CurrencyInput
					id="amount-input"
					class="input"
					:label="currencyLabel"
					:value="amountPaid"
					:symbole="true"
					:min-value="-1"
					allow-zero
					:validation-rules="{ required: true, isMinValue: 0.01 }"
					:validation-messages="{
						required: $t('error.currencyRequired'),
						isMinValue: $t('error.valid')
					}"
					:automation-id="getAutomationId('service-card')"
					@change="currencyChanged"
				/>
			</BCol>
		</BRow>
		<!-- Prescription question (Only displayed if the service the member selected requires a prescription) -->
		<BRow v-if="requirePrescription || showRequirePrescription" class="input-row">
			<BCol cols="10">
				<YesNoToggle
					:value="hasPrescription"
					:label="$t('label.prescription')"
					class="mb-0"
					stacked
					is-required
					:required-error-msg="$t('error.required')"
					:automation-id="getAutomationId('service-card-prescription')"
					@input="savePrescription"
				/>
			</BCol>
		</BRow>
		<!-- OTHER COVERAGE QUESTIONS -->
		<!-- Toggle to ask the member if this claim was paid by the other carrier on file. -->
		<BRow v-if="showPaidByOtherPolicy" class="input-row">
			<BCol cols="10">
				<YesNoToggle
					:value="paidByOtherPolicy"
					:label="$t('label.paidByOtherPolicy')"
					stacked
					is-required
					class="mb-0"
					:required-error-msg="$t('error.required')"
					:automation-id="getAutomationId('service-card-paid-other-policy')"
					@input="onPaidByOtherInput"
				></YesNoToggle>
			</BCol>
		</BRow>
		<!-- Show a currency input if the member selected that the other carrier paid on this claim. -->
		<BRow v-if="paidByOtherPolicy" class="input-row">
			<BCol cols="12">
				<CurrencyInput
					id="cob-amount-input"
					class="currency-input input"
					:min-value="-1"
					:label="$t('label.amountPaidByOtherPolicy')"
					:placeholder="$t('label.amountPlaceholder')"
					:value="amountPaidByOtherCarrier"
					:validation-rules="{
						required: true,
						isANumber: true,
						isMinValue: 0.01
					}"
					:validation-messages="{
						required: $t('error.cobAmountCovered'),
						isMinValue: $t('error.cobAmountCovered')
					}"
					:automation-id="getAutomationId('service-card-cob')"
					@change="checkCobAmount"
				/>
			</BCol>
		</BRow>
		<!-- If the claimw as not paid by the other carrier show a drop down asking the member to select a reason why it wasn't.   -->
		<BRow v-if="paidByOtherPolicy === false" class="input-row">
			<BCol cols="10">
				<BRow>
					<BCol>
						<BaseSelect
							class="mb-0 input"
							:label="$t('label.reasonNotCovered')"
							:placeholder="$t('label.placeholder')"
							:validation-rules="{ required: true }"
							:validation-messages="{
								required: $t('error.reasonRequired')
							}"
							:value="selectClaimNotCovered.value"
							:options="selectClaimNotCovered.options"
							:automation-id="getAutomationId('service-card-cob')"
							@change="onCOBReasonsChange"
						/>
					</BCol>
				</BRow>
				<BRow v-if="showCobReasonRequireNote" class="other-reason-row">
					<BCol>
						<BaseInput
							class="mb-0 input"
							:label="$t('label.requireNote')"
							:value="value.cobOtherReasonNote"
							:max-length="250"
							:validation-rules="{ required: true, max: 250 }"
							:validation-messages="{
								required: $t('error.reasonNotCovered'),
								max: $t('error.cobNoteMaxLength', [250])
							}"
							:automation-id="getAutomationId('service-card')"
							@input="onCOBReasonNoteChange"
						/>
					</BCol>
				</BRow>
			</BCol>
		</BRow>
		<BRow v-if="showSaveButtons">
			<BaseButton
				:label="$t('button.save')"
				:aria-label="$t('button.save')"
				class="button button-save p-0"
				variant="link"
				:automation-id="getAutomationId('service-card-save')"
				type="submit"
			/>
			<BaseButton
				:label="$t('button.cancel')"
				:aria-label="$t('button.cancel')"
				class="button p-0"
				variant="link"
				:automation-id="getAutomationId('service-card-cancel')"
				@click="cancelService"
			/>
		</BRow>
		<!-- Error modal for when other carrier pays more than total amount of claim -->
		<ErrorModal
			:data-test-automation-id="getAutomationId('prescription-required-modal')"
			:modal-show="showErrorModal"
			:error-title="$t('error.maxValueHeader')"
			:error-message="$t('error.maxValue')"
			:has-close-button="true"
			@change="closeModal"
		>
			<BaseButton
				class="mt-2"
				:label="$t('label.close')"
				:aria-label="$t('label.close')"
				variant="primary"
				automation-id="noClaimHistoryButton"
				@click="closeModal"
			/>
		</ErrorModal>
		<!-- Error modal for cob other reasons that cancel a claim -->
		<ErrorModal
			automation-id="CobClaimErrorModal"
			:error-title="claimCancelledError.title"
			:error-message="claimCancelledError.message"
			:modal-show="showCobModal"
			no-hide-on-blur
			@change="closeCancelClaimModal"
		>
			<BaseButton
				automation-id="cancelClaimModalButton"
				:label="$t('label.close')"
				variant="primary"
				@click="closeCancelClaimModal"
			>
			</BaseButton>
		</ErrorModal>
	</BaseForm>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// Constants
import { HEALTH, PRACTITIONER } from '@/constants/ClaimTypes';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import * as Claimtypes from '@/constants/ClaimTypes';
// Components
import { BRow, BCol, BFormGroup } from 'bootstrap-vue';
import CurrencyInput from '@/components/common/CurrencyInput';
import YesNoToggle from '@/components/common/YesNoToggle';
import ServiceSelect from '@/components/claim/claimService/ServiceSelect';
import BaseButton from '@/components/common/base/BaseButton';
import BaseInput from '@/components/common/base/BaseInput';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseForm from '@/components/common/base/BaseForm';
import BaseSelect from '@/components/common/base/BaseSelect';
import ErrorModal from '@/components/common/ErrorModal';
import { ValidationProvider } from 'vee-validate';
// Models
import COB from '@/models/claims/CoordinationOfBenefits';
//utils
import { format } from 'date-fns';
import { parseNumber } from '@/utils/NumberUtil';
import { enCA, frCA } from 'date-fns/locale';

@Component({
	name: 'ServiceCard',
	mixins: [IdMixin],
	components: {
		CurrencyInput,
		ServiceSelect,
		BRow,
		BCol,
		BFormGroup,
		BaseButton,
		BaseForm,
		YesNoToggle,
		BaseInput,
		BaseDatePicker,
		BaseSelect,
		ErrorModal,
		ValidationProvider
	},
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		coordinationOfBenefits: {
			type: Object,
			default: () => {}
		},
		serviceTypes: {
			type: Array,
			default: () => []
		},
		serviceDate: {
			type: String,
			default: ''
		},
		canDelete: {
			type: Boolean,
			default: false
		},
		claimType: {
			type: String,
			default: 'XX-HEAL'
		},
		// Flag to determine if we ask the member if the claim was paid by a previous policy.
		hasCobOnFile: {
			type: Boolean,
			default: false
		},
		// Flag to determine if the member requires a prescription for this service. If they do then we need to show a toggle to ask them for it.
		requirePrescription: {
			type: Boolean,
			default: false
		},
		// Prop is used when the member is editing/adding a service. It will display a save and cancel button
		showSaveButtons: {
			type: Boolean,
			default: false
		},
		hideDateOnService: {
			type: Boolean,
			default: false
		},
		hideDatePicker: {
			type: Boolean,
			default: false
		},
		// This is used to know if we display an error under the service select when the member has a duplicte service for the same date.
		showMoreThanOneServiceError: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			default: '',
			require: true
		}
	}
})
export default class ServiceCard extends Vue {
	selectedReason = null;
	paidByOtherPolicy = null;
	showPaidByOtherPolicy = false;
	selectClaimNotCovered = {
		options: [],
		value: ''
	};

	showErrorModal = false;
	maxAcceptedCurrencyAmount = 99999.99;
	minimumCurrencyAmount = 1;
	currencyInputTarget = null;
	showCobModal = false;
	showRequirePrescription = false;
	claimCancelledError = {
		title: null,
		message: null
	};
	// Vue Hooks

	async created() {
		// Get the Cob reasons from the API to let the member choose why their other carrier did not pay.
		await this.getCOBReasons();
		// Check if the member has already said the claim was paid by another policy
		await this.checkCobValues();
	}

	// Computed Variables

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get dateOfService() {
		return this.serviceDate ? this.serviceDate : this.convertDate(new Date());
	}

	get amountPaid() {
		return this.value?.amountPaid;
	}

	get amountPaidByOtherCarrier() {
		return this.value?.amountPaidByOtherCarrier;
	}

	get descriptionRbKey() {
		return this.value?.descriptionRbKey ? this.value?.descriptionRbKey : this.value?.description;
	}

	get hasPrescription() {
		return this.value?.hasPrescription;
	}

	get formattedDate() {
		return this.value?.dateOfService && this.value?.dateOfService?.length !== 10
			? format(
					new Date(
						this.value?.dateOfService.substr(0, 4),
						this.value?.dateOfService.substr(4, 2) - 1,
						this.value?.dateOfService.substr(6, 2)
					),
					'yyyy-MM-dd'
			  )
			: null;
	}

	get formattedDateForLabel() {
		const sDate = new Date(
			this.value?.dateOfService.substr(0, 4),
			this.value?.dateOfService.substr(4, 2) - 1,
			this.value?.dateOfService.substr(6, 2)
		);
		const dateLocale = this.$store.state.i18n.locale === 'en' ? enCA : frCA;
		const dateFormat = this.$store.state.i18n.locale === 'en' ? 'MMMM d, yyyy' : 'd MMMM yyyy';
		return format(sDate, dateFormat, {
			locale: dateLocale
		});
	}

	get availableServices() {
		return this.serviceTypes?.filter((service) => {
			return service.isEnabled || this.value?.descriptionRbKey === service.value;
		});
	}

	get serviceLabel() {
		if (this.hideDateOnService) {
			if (this.isPWA) {
				return this.$t('label.typeOfServicePWANoDate');
			} else {
				return this.$t('label.typeofServiceNoDate');
			}
		} else {
			return this.claimType === HEALTH || this.claimType === PRACTITIONER
				? this.$t('label.typeofService', { date: this.formattedDateForLabel })
				: this.$t('label.typeOfServicePWA', { date: this.formattedDateForLabel });
		}
	}

	get currencyLabel() {
		return this.claimType === HEALTH || this.claimType === PRACTITIONER
			? this.$t('label.currencyInput')
			: this.$t('label.currencyInputPWA');
	}

	get showCobReasonRequireNote() {
		if (this.selectedReason?.doesRequireNote) return true;
		this.value.cobOtherReasonNote = null;
		return false;
	}

	get isPWA() {
		return this.claimType === Claimtypes.PWA;
	}

	get datePickerLabel() {
		return this.isPWA ? this.$t('label.purchaseDate') : this.$t('label.date');
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	// METHODS.

	/**
	 * Method is called when the member changes the COB reason.
	 * Emits the value to the parent component, and determines if we need to show an error modal.
	 */
	onCOBReasonsChange(value) {
		// Set the coordination of benefits values to be emited to parent component.
		this.selectedReason = this.cobReasons.filter((reason) => reason.id === value)[0];
		// Set the value of the dropdown
		this.selectClaimNotCovered.value = value.toString();
		this.value.cobOtherReason = value;
		this.$emit('input', this.value);
		if (this.selectedReason.cancelsClaim) {
			if (this.selectedReason.id === 4) {
				this.claimCancelledError.title = this.$t('error.cancelReasonPrescriptionTitle');
				this.claimCancelledError.message = this.$t('error.cancelReasonPrescriptionError');
			} else if (this.selectedReason.id === 5) {
				this.claimCancelledError.title = this.$t('error.cancelReasonPriorAuthTitle');
				this.claimCancelledError.message = this.$t('error.cancelReasonPriorAuthError');
			}
			this.showCobModal = true;
		}
	}

	convertDate(date) {
		return date.toLocaleString(this.locale + '-CA', {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		});
	}

	// Emit the new service to the parent component.
	serviceChanged(descriptionRbKey) {
		const updatedValue = { ...this.value, descriptionRbKey };
		this.$emit('input', updatedValue);
	}

	/**
	 * Emit the new amount paid to the parent component.
	 */
	currencyChanged(amountPaid) {
		if (this.amountPaidByOtherCarrier !== null && this.amountPaidByOtherCarrier !== undefined) {
			if (
				parseNumber(this.amountPaidByOtherCarrier, this.$root.$i18n.locale) >=
				parseNumber(amountPaid, this.$root.$i18n.locale)
			) {
				this.showErrorModal = true;
			}
		}
		this.value.amountPaid = amountPaid;
		this.$emit('input', this.value);
	}

	/**
	 * function to update the paidByotherPolicy flag, and emit value.
	 */
	onPaidByOtherInput(value) {
		this.value.wasSubmittedToOtherFirst = value;
		// Clear the values so amounts or cob reasons don't persit if member changes toggle.
		if (value === true) {
			this.value.cobOtherReason = null;
			this.value.cobOtherReasonNote = null;
			this.$emit('input', this.value);
		} else {
			this.value.amountPaidByOtherCarrier = null;
			this.$emit('input', this.value);
		}

		this.paidByOtherPolicy = value;
	}

	onCOBReasonNoteChange(value) {
		this.value.cobOtherReasonNote = value;
		this.$emit('input', this.value);
	}

	updateDateOfService(dateOfService) {
		this.value.dateOfService = dateOfService;
		this.$emit('input', this.value);
	}

	savePrescription(hasPrescription) {
		this.value.hasPrescription = hasPrescription;
		this.$emit('input', this.value);
	}

	/**
	 * This method will emit a save event, and pass the value, and coordination of benefits to be saved.
	 */
	saveService() {
		this.$emit('save', this.value);
	}

	/**
	 * This method will just emit a cancel event.
	 * A parent component will use it to cancel adding a service.
	 */
	cancelService() {
		this.$emit('cancel');
	}

	/**
	 * Retrieve all the Cob reasons a claim might not be covered from the API so the member can select why it wasn't covered.
	 */
	async getCOBReasons() {
		const email = sessionStorage.getItem('email');
		const locale = this.$root.$i18n.locale;
		const isHSAClaim = this.claimType === 'XX-HSA';
		this.cobReasons = await COB.getCOBReasons(email, isHSAClaim, locale);
		for (const reason of this.cobReasons) {
			this.selectClaimNotCovered.options.push({
				value: reason.id,
				text: reason.reason
			});
		}
	}

	/**
	 * Check if values exist for COB, if they do then set the values, and alose determine the value for paidByOtherPolicy.
	 */
	async checkCobValues() {
		// Don't as for other cob if the claim is PWA.
		if (!this.isPWA) {
			if (this.hasCobOnFile) {
				let brand = await JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
				// Check if the Member is Medavie/SBC, and has medavie/SBC as the other carrier. If they are then do not show toggle asking if other carrier covered the claim
				const isMBCCarrier = await this.isMedavieCarrier(brand);
				const isSBCCarrier = await this.isSBCCarrier(brand);

				this.showPaidByOtherPolicy = false;
				if (
					this.coordinationOfBenefits?.extCovPrimaryInd === true ||
					this.coordinationOfBenefits?.extCovPrimaryInd.toUpperCase() === 'Y'
				) {
					if (isMBCCarrier) {
						if (brand && brand.toUpperCase() !== 'MBC') {
							this.showPaidByOtherPolicy = true;
						}
					} else if (isSBCCarrier) {
						if (brand && brand.toUpperCase() !== 'SBC') {
							this.showPaidByOtherPolicy = true;
						}
					} else {
						this.showPaidByOtherPolicy = true;
					}
				}
			}

			if (this.hasCobOnFile && this.value?.amountPaidByOtherCarrier) {
				this.paidByOtherPolicy = true;
			} else if (this.hasCobOnFile && this.value?.cobOtherReason) {
				this.paidByOtherPolicy = false;
				this.selectClaimNotCovered.value = this.value?.cobOtherReason;
				this.selectedReason = this.cobReasons.filter(
					(reason) => reason.id === this.selectClaimNotCovered.value
				)[0];
			} else {
				this.paidByOtherPolicy = null;
			}
		}
	}

	/**
	 * Check if the member is an Medavie member and if their other carrier is Medavie.
	 */
	async isMedavieCarrier() {
		return this.coordinationOfBenefits?.extCovCarrierCode === 'BC' ||
			this.coordinationOfBenefits?.extCovCarrierCode === 'CB'
			? true
			: false;
	}

	/**
	 * Check if the member is an SBC member and if their other carrier is SBC.
	 */
	async isSBCCarrier() {
		return this.coordinationOfBenefits?.extCovCarrierCode === 'SK' ? true : false;
	}

	checkCobAmount(value) {
		this.value.amountPaidByOtherCarrier = value;
		this.$emit('input', this.value);
		if (
			parseNumber(this.value.amountPaidByOtherCarrier, this.$root.$i18n.locale) >=
			parseNumber(this.value.amountPaid, this.$root.$i18n.locale)
		) {
			this.showErrorModal = true;
		}
	}

	closeCancelClaimModal() {
		this.showCancelModal = false;
		this.$store.dispatch('isOkToLeaveClaimSubmission', true);
		this.$router.push({ name: SUBMIT_CLAIM.CLAIM_TYPE });
	}

	closeModal() {
		this.showErrorModal = false;
		this.$emit('checkNextStatus', false);
	}
}
</script>

<style lang="scss" scoped>
.datepicker ::v-deep(input),
.datepicker ::v-deep(.btn) {
	height: 42px;
}
.input-row {
	margin-bottom: 1.5rem;
}
.input-row ::v-deep(.validation-provider) {
	margin-bottom: 1.5rem;
}
.other-reason-row {
	margin-top: 1.5rem;
}
.input ::v-deep select.form-control,
input {
	height: 42px;
}
.input ::v-deep input {
	height: 42px;
}
.button {
	font-family: $lato-font-family;
	font-weight: 400;
	text-decoration: underline;
}
.button-save {
	margin-left: 15px;
	margin-right: 15px;
}
.error-message {
	text-align: left;
	font-size: 14px;
	color: #ad0900;
	line-height: 17px;
	font-weight: 400;
	background-color: #fff6f6;
	border-color: #ad0900;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: -20px;
	margin-bottom: 1.5rem;
}
</style>

<i18n>
{
	"en": {
		"button": {
			"save": "Save",
			"cancel": "Cancel"
		},
		"label": {
			"currencyInput": "Enter full cost of service (not including tip)",
			"currencyInputPWA": "Amount you want to claim (not including tip)",
			"typeofService": "Type of service received on {date}",
			"typeofServiceNoDate": "Type of service received",
			"typeOfServicePWA": "Items or services purchased on {date}",
			"typeOfServicePWANoDate": "Items or service purchased",
			"date": "Date of visit",
			"purchaseDate": "Date of purchase",
			"placeholder": "Please select...",
			"prescription": "Do you have a prescription for this service?",
			"paidByOtherPolicy": "Was this claim paid by the other policy?",
			"amountPaidByOtherPolicy": "Amount covered by the other policy",
			"amountPlaceholder": "$0.00",
			"reasonNotCovered": "Please select the reason why this claim wasn't covered by the other plan.",
			"requireNote": "Please enter a reason",
			"close": "Close"
		},
		"placeholder": "Please select…",
		"error": {
			"requiredClaimDate": "Please select a date.",
	  		"future": "The date you've entered is in the future. Please check the date and try again.",
			"cancelReasonPrescriptionTitle": "Sorry, your claim can't be processed.",
			"cancelReasonPrescriptionError": "A prescription is required to complete this claim submission.",
			"cancelReasonPriorAuthTitle": "Sorry, your claim can't be processed.",
			"cancelReasonPriorAuthError": "Prior authorization is required to complete this claim submission.",
			"currencyRequired": "Please enter the cost of the service.",
			"cobAmountCovered": "Please enter the amount covered by the other policy.",
			"serviceRequired": "Please choose items or services purchased.",
			"maxValueHeader": "Please review your claim amounts",
			"maxValue": "The other carrier cannot have paid an amount that is equal to, or higher than the cost of the service provided. Please review the amounts you entered and try again.",
			"cobNoteMaxLength": "This is a bit long. We limit our notes to {0} characters.",
			"reasonRequired": "Please select the reason the other policy did not cover this claim.",
			"reasonNotCovered": "Please provide a reason the other policy did not cover this claim.",
			"required": "Please select an option to continue.",
			"valid": "Please enter a valid amount.",
			"serviceExists": "You've already added this service type on this date. Please review your input and try again."
		}
	},
	"fr": {
		"button": {
			"save": "Enregistrer",
			"cancel": "Annuler"
		},
		"label": {	
			"currencyInput": "Entrez le coût complet du service (pourboire non compris)",
			"currencyInputPWA": "Montant de la demande de règlement (pourboire non compris)",
			"date": "Date de la visite",
			"purchaseDate": "Date d'achat",
			"typeofService": "Type de service fourni le {date}",
			"typeofServiceNoDate": "Type de service fourni",
			"typeOfServicePWA": "Articles ou services achetés le {date}",
			"typeOfServicePWANoDate": "Articles ou services achetés",
			"placeholder": "Veuillez sélectionner...",
			"prescription": "Avez-vous une ordonnance pour ce service ?",
			"paidByOtherPolicy": "Cette demande de règlement a-t-elle été payée en vertu de l'autre police ?",
			"amountPaidByOtherPolicy": "Veuillez entrer les montants couverts par l’autre régime.",
			"amountPlaceholder": "0.00 $",
			"reasonNotCovered": "Veuillez sélectionner la raison pour laquelle cette demande de règlement n'a pas été remboursée par l'autre régime.",
			"requireNote": "Veuillez entrer une raison",
			"close": "Fermer"
		},
		"placeholder": "Veuillez sélectionner…",
		"error": {
			"requiredClaimDate": "Veuillez selectionner une date.",
			"future": "La date que vous avez entrée est ultérieure à la date d'aujourd'hui. Veuillez corriger la date et essayer à nouveau.",
			"cancelReasonPrescriptionTitle": "Désolé, votre demande de règlement ne peut pas être traitée",
			"cancelReasonPrescriptionError": "Une ordonnance est requise pour traiter cette demande de règlement.",
			"cancelReasonPriorAuthTitle": "Désolé, votre demande de règlement ne peut pas être traitée",
			"cancelReasonPriorAuthError": "Une autorisation préalable est requise pour traiter cette demande de règlement.",
			"currencyRequired": "Veuillez entrez le coût du service.",
			"cobAmountCovered": "Veuillez entrer le montant couvert par l'autre police.",
			"serviceRequired": "Veuillez choisir les articles ou services payés.",
			"maxValueHeader": "Veuillez vérifier les montants de votre demande de règlement",
			"maxValue": "L’autre assureur ne peut avoir remboursé un montant égal ou supérieur au coût du service fourni. Veuillez corriger les montants que vous avez entrés et réessayer.",
			"cobNoteMaxLength": "Votre texte est un peu trop long. Nos notes sont limitées à {0} caractères.",
			"reasonRequired": "Veuillez sélectionner la raison pour laquelle l'autre police ne couvrait pas cette demande de règlement.",
			"reasonNotCovered": "Veuillez indiquer la raison pour laquelle l'autre police ne couvrait pas cette demande de règlement.",
			"required": "Veuillez sélectionner une option pour continuer.",
			"valid": "Veuillez entrer un montant valide.",
			"serviceExists": "Vous avez déjà ajouté ce type de service à cette date. Veuillez modifier votre sélection et réessayer."
		}
	}
}
</i18n>
