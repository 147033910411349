<template>
	<SubmitClaimTemplate class="template" :percent-complete="43" :step-title="$t('title.step')">
		<BaseForm v-slot="{ invalid }" automation-id="claimAccidentForm'">
			<BaseCard>
				<BRow>
					<BCol cols="11">
						<YesNoToggle
							:class="!isAccidentThirdPartyComputed ? 'toggle' : ''"
							:value="isAccidentThirdPartyComputed"
							:label="$t('label.thirdParty')"
							:is-required="true"
							:required-error-msg="$t('error.required')"
							automation-id="accidentThirdPartyToggle"
							@input="updateThirdParty"
						/>

						<template v-if="isAccidentThirdPartyComputed">
							<YesNoToggle
								:value="claim.isFirstClaimForAccident"
								:label="$t('label.reported')"
								:is-required="isAccidentThirdPartyComputed"
								:required-error-msg="$t('error.required')"
								automation-id="accidentReportedToggle"
								@input="updateReportedToBlueCross"
							/>

							<YesNoToggle
								class="toggle"
								:value="claim.isWorkersCompEligible"
								:label="$t('label.workersComp')"
								:is-required="isAccidentThirdPartyComputed"
								:required-error-msg="$t('error.required')"
								automation-id="workersCompToggle"
								@input="updateWorkersComp"
							/>
						</template>
					</BCol>
				</BRow>
			</BaseCard>

			<WizardButtonBar
				:disable-next="invalid"
				@navigate-back="navigateBack()"
				@navigate-next="navigateNext()"
			></WizardButtonBar>
		</BaseForm>

		<BaseModal
			automation-id="before-start-base-modal"
			css-content-class="workers-comp-modal-content"
			css-body-class="workers-comp-modal-body"
			:is-static="true"
			:close-button="false"
			cant-close-on-esc
			no-hide-on-blur
			show-exclamation
			:modal-show="showModal"
		>
			<template #contentBody>
				<p class="modal-title">{{ $t('modal.title') }}</p>
				<p class="modal-text">{{ $t('modal.text') }}</p>
			</template>
			<template #modal-footer>
				<BaseButton
					automation-id="modal-button-1"
					variant="primary"
					class="mt-3"
					:label="$t('modal.button1')"
					@click="goToDashboard()"
				/>
				<BaseButton
					automation-id="modal-button-2"
					variant="link"
					class="mt-3 text-left"
					:label="$t('modal.button2')"
					:icon="['fal', 'angle-right']"
					@click="goToClaimLander()"
				/>
			</template>
		</BaseModal>
	</SubmitClaimTemplate>
</template>

<script>
// constants
import { SUBMIT_CLAIM, DASHBOARD } from '@/constants/Routes.js';
import { HEALTH } from '@/constants/ClaimTypes.js';
// models
import Page from '@/models/claims/Page';
import UserBenefits from '@/models/UserBenefits';
// pages
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
// components
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseForm from '@/components/common/base/BaseForm';
import BaseModal from '@/components/common/base/BaseModal.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import YesNoToggle from '@/components/common/YesNoToggle';
import { BRow, BCol } from 'bootstrap-vue';

export default {
	name: 'TreatmentForAccidentPage',
	components: {
		BaseForm,
		BaseButton,
		BaseCard,
		YesNoToggle,
		BaseModal,
		SubmitClaimTemplate,
		WizardButtonBar,
		BRow,
		BCol
	},
	data() {
		return {
			page: {},
			pageName: 'treatmentForAccident',
			claim: {},
			userBenefits: {},
			securityPolicy: {},
			showModal: false
		};
	},
	computed: {
		isHealth() {
			return this.claim?.claimType === HEALTH;
		},
		isAccidentThirdPartyComputed() {
			return this.claim?.isAccidentThirdParty;
		}
	},
	async created() {
		this.$store.dispatch('updateLoading', true);
		this.securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
		this.userBenefits = await UserBenefits.getBenefitService(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		await this.getOrCreatePage();
		this.$store.dispatch('updateLoading', false);
	},
	methods: {
		/** Called when member navigates "back".  Used to delete this claim submit step's data. */
		async deleteStepData() {
			Page.deleteById(this.pageName);
		},
		navigateBack() {
			this.deleteStepData();
		},
		async navigateNext() {
			if (this.claim.isWorkersCompEligible) {
				this.$store.dispatch('isOkToLeaveClaimSubmission', true);
				this.showModal = true;
			} else {
				this.$store.dispatch('isOkToLeaveClaimSubmission', false);
				await Page.updatePage(this.page, this.claim);
				this.$router.push({ name: SUBMIT_CLAIM.OTHER_COVERAGE });
			}
		},

		updateThirdParty(isAccidentThirdParty) {
			this.claim.isAccidentThirdParty = isAccidentThirdParty;
			if (!isAccidentThirdParty) {
				this.$store.dispatch('isOkToLeaveClaimSubmission', false);
				this.claim.isFirstClaimForAccident = null;
				this.claim.isWorkersCompEligible = null;
			}
		},
		updateReportedToBlueCross(isReportedToBlueCross) {
			this.claim.isFirstClaimForAccident = isReportedToBlueCross;
		},
		updateWorkersComp(isWorkersComp) {
			this.claim.isWorkersCompEligible = isWorkersComp;
		},
		goToDashboard() {
			this.$router.push({ name: DASHBOARD });
		},
		/**
		 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
		 * If no page exists it will create a new one.
		 */
		async getOrCreatePage() {
			this.page = await Page.getPageById(this.pageName);
			if (this.page === null) {
				// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
				this.claim = structuredClone(await Page.getClaimFromLastPage());
				this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
			} else {
				this.claim = this.page?.claims[0];
			}
		},

		goToClaimLander() {
			this.$router.push({ name: SUBMIT_CLAIM.CLAIM_TYPE });
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .card-body {
	& > div:last-child {
		margin-bottom: 0;
	}
}
.modal-title {
	font-family: $font-family-headline;
	font-size: 21px;
	margin-bottom: 0.5em;
}
.modal-text {
	font-family: $font-family-regular;
	font-size: 17px;
	font-weight: 300;
}
.toggle {
	margin-bottom: 0;
}
.template {
	width: 90%;
}
::v-deep .workers-comp-modal-content {
	width: 77%;
}
::v-deep .workers-comp-modal-body {
	padding-top: 0;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "step": "Treatment for Accident"
    },
    "label": {
      "thirdParty": "Is this claim a result of an accident where a third party is involved or may have been at fault? For example, another driver or your employer.",
      "reported": "Is this your first claim resulting from this accident?",
      "workersComp": "Is this claim eligible through the Workers' Compensation Board or CNESST?"
    },
	"modal": {
		"title": "Sorry, your claim can't be processed",
		"text": "Please submit your claim to the Workers' Compensation Board or CNESST for consideration, as it may be eligible under their guidelines.",
		"button1": "OK, go to homepage",
		"button2": "Submit a new claim"
	},
	"error": {
		"required": "Please select an option to continue."
	}
  },
  "fr": {
    "title": {
      "step": "Traitement en cas d'accident"
    },
    "label": {
      "thirdParty": "Cette demande de règlement résulte-t-elle d'un accident impliquant une tierce partie ou causé par une tierce partie (par exemple, un(e) autre conducteur(trice) ou votre employeur)?",
      "reported": "S'agit-il de votre première demande de règlement résultant de cet accident?",
      "workersComp": "Cette demande de règlement est-elle admissible par l'entremise de la Commission des accidents du travail ou de la CNESST?"
    },
	"modal": {
		"title": "Malheureusement, nous ne pouvons pas traiter votre demande de règlement.",
      	"text": "Veuillez la soumettre à la Commission des accidents du travail ou à la CNESST aux fins d'évaluation, elle pourrait être admissible en fonction des lignes directrices de ces organismes.",
		"button1": "OK, retourner à la page d'accueil",
		"button2": "Soumettre une nouvelle demande de prestations"
	},
	"error": {
		"required": "Veuillez sélectionner une option pour continuer."
	}
  }
}
</i18n>
