import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class UserService extends Model {
	static entity = 'userServices';

	static loaded = false;

	/**
	 * user available benefits fields
	 */
	static primaryKey = 'id';
	static fields() {
		return {
			id: this.uid(),
			email: this.string,
			TRAVEL: this.string(),
			OPTBEN: this.string(),
			HOSPITAL: this.string(),
			DRUGS: this.string(),
			DENTAL: this.string(),
			EHB: this.string(),
			HSA: this.string(),
			PWA: this.string()
		};
	}

	static async createNewBenefitService() {
		var benefit = await super.new();

		benefit.$save();
		return benefit;
	}

	static async getBenefitService(email, token, locale) {
		let response = await axios.get(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/benefits`,
			{
				headers: {
					'content-type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				},
				data: {}
			}
		);
		return response.data;
	}
}
