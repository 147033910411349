<template>
	<SubmitClaimTemplate
		:percent-complete="57"
		:step-title="$t('subTitle')"
		:fluid="isHSA ? true : false"
	>
		<BaseForm v-slot="{ invalid }">
			<ClaimDetails
				:value="claim"
				:service-types="serviceTypes"
				:service-date="serviceDate"
				:show-service-list="false"
				:member="member"
				:show-prescription-modal="showPrescriptionModal"
				:invalid-services="invalid || isNextDisabled"
				:provider-type="providerType"
				:check-currency-inputs="checkCurrencyInputs"
				automation-id="claim-details"
				@updateServiceTypeStatus="updateServiceTypeStatus"
				@changeNextStatus="disableNext"
				@input="updateClaim"
				@saveService="navigateNext()"
				@closeModal="closeModal"
			/>
			<WizardButtonBar
				:disable-next="invalid || isNextDisabled"
				:auto-back="!isHealth && !isDrugOrDental"
				show-back-button
				@navigate-back="navigateBack()"
				@navigate-next="checkCurrency()"
			></WizardButtonBar>
		</BaseForm>
	</SubmitClaimTemplate>
</template>
<script>
import Vue from 'vue';
//Components
import Component from 'vue-class-component';
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import ClaimDetails from '@/components/submit-claim/claim-details/ClaimDetails';
import BaseForm from '@/components/common/base/BaseForm';

// Models
import Dependent from '@/models/Dependent.js';
import Page from '@/models/claims/Page';
import ServiceTypes from '@/models/claims/ServiceTypes';
import ClaimTypes from '@/models/claims/ClaimTypes';
import UserBenefits from '@/models/UserBenefits';
import ProviderTypeSearch from '@/models/ProviderTypeSearch';
// UTILs
import { formatDateForUi } from '@/utils/date';
// Routes
import { SUBMIT_CLAIM } from '@/constants/Routes';
// Constants
import * as claimtypes from '@/constants/ClaimTypes';

@Component({
	name: 'ClaimDetailsPage',
	components: {
		SubmitClaimTemplate,
		WizardButtonBar,
		ClaimDetails,
		BaseForm
	}
})
export default class ClaimDetailsPage extends Vue {
	pageName = 'claimDetails';
	page = {};
	isNextDisabled = false;
	serviceTypes = [];
	claim = {};
	serviceDate = '';
	isHSAEnabled = false;
	security = {};
	availableClaimTypes = [];
	specialtyCode = null;
	showPrescriptionModal = false;
	member = {
		firstName: null,
		fullName: null,
		dob: null
	};
	participants = null;
	searchCode = '';
	providerType = null;
	showGoBackAlert = false;
	originalBenefitServices = null;
	checkCurrencyInputs = false;
	claimTypeDescription = null;
	providerTypes = [];

	get isPractitioner() {
		return this.claim?.claimType === claimtypes.PRACTITIONER ? true : false;
	}
	get isHealth() {
		return this.claim?.claimType === claimtypes.HEALTH ? true : false;
	}
	get isDrugOrDental() {
		return this.claim?.claimType === claimtypes.DRUGS || this.claim?.claimType === claimtypes.DENTAL
			? true
			: false;
	}
	get isVision() {
		return this.claim?.claimType === claimtypes.VISION ? true : false;
	}
	get isPWA() {
		return this.claim?.claimType === claimtypes.PWA ? true : false;
	}

	get isHSA() {
		return this.claim?.claimType === claimtypes.HSA ? true : false;
	}

	async mounted() {
		this.$store.dispatch('updateLoading', true);
		await this.getOrCreatePage();
		await this.getMemberInfo();

		this.security = JSON.parse(sessionStorage.getItem('securityPolicy'));

		const userBenefits = await UserBenefits.getBenefitService(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		this.isHSAEnabled = 'HSA' in userBenefits;
		if (this.claim) {
			this.benefitServiceLength = this.claim.benefitServices.length;
			this.serviceDate = this.claim.benefitServices[0]?.dateOfService;
			this.specialtyCode = this.claim.benefitServices[0].specialtyCode;
			this.searchCode = this.claim.benefitServices[0].specialtyCode;
		}
		await this.getProviderType();
		// to save on api calls when the lang toggle is press both service list are brought on mount
		// this will also helpw ith the transition for enbaled and disabled list items
		if (this.claim && this.claim.benefitServices[0]) {
			const serviceTypeResponse = await ServiceTypes.getServices(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale,
				this.claim.lob,
				this.claim.benefitServices[0].dateOfService,
				this.searchCode
			);
			let instance = this;
			serviceTypeResponse.forEach(async (service) => {
				if (service.description === 'Other') {
					service.descriptionRbKey = 'Other';
				} else if (service.description === 'Autre') {
					service.descriptionRbKey = 'Autre';
				}
				let enabled = true;
				if (
					instance.claim.benefitServices.find((benefitService) => {
						return benefitService.descriptionRbKey === service.descriptionRbKey;
					})
				) {
					enabled = false;
				}
				instance.serviceTypes.push({
					value: service.descriptionRbKey,
					service: service,
					text: service.description,
					isEnabled: enabled
				});
			});
		}
		// end loading animation
		this.$store.dispatch('updateLoading', false);
	}

	async created() {
		this.availableClaimTypes = await ClaimTypes.loadClaimType(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		if (this.isHealth || this.isDrugOrDental) {
			const result = this.availableClaimTypes.filter((obj) => {
				if (obj.claimType === this.claim.claimType) {
					return obj;
				}
			});
			this.claimTypeDescription = result[0].description;
		}
	}

	/**
	 * Retrieve the member name and dob using the claim particpant ID.
	 * This displays in the provider card.
	 */
	async getMemberInfo() {
		await Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			this.participants = response.data;
			var participant = response.data.filter((participants) => {
				return participants.participantId === this.claim.participantId;
			});
			this.member.firstName = participant[0].firstName;
			this.member.fullName = `${participant[0].firstName} ${participant[0].lastName}`;
			this.member.dob = formatDateForUi(participant[0].dateOfBirth, this.$store.state.i18n.locale);
		});
	}

	/**
	 * This method will retrieve the provider types for the provider type dropdown.
	 */
	async getProviderType() {
		await ProviderTypeSearch.getProviderTypes(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			const result = response.data.filter((providerType) => {
				return providerType.claimType === this.specialtyCode;
			});
			this.providerType = result[0]?.description;
		});
	}

	disableNext(isDisabled) {
		this.isNextDisabled = !isDisabled;
	}

	updateServiceTypeStatus(serviceType) {
		this.serviceTypes[serviceType.index].isEnabled = serviceType.isEnabled;
	}

	checkCurrency() {
		this.checkCurrencyInputs = true;
	}

	navigateBack() {
		this.$store.dispatch('submitClaimAdditionalQs', {});
		Page.deleteById(this.pageName);
	}

	async updateClaim(claim) {
		this.claim = claim;
	}

	async navigateNext() {
		let hasPrescription = this.claim?.benefitServices[0]?.hasPrescription;
		// Check if the hasPrescription is not null and it is is equal to false. This flag will only be set to false if the member requires a prescription
		if (hasPrescription != null && hasPrescription === false) {
			this.$store.dispatch('isOkToLeaveClaimSubmission', true);
			this.showPrescriptionModal = true;
		} else {
			// If the flow is practitioner and the app is not showing the service list then navigate to the next screen.

			this.$router.push({ name: SUBMIT_CLAIM.SERVICE_LIST });
			await Page.updatePage(this.page, this.claim);
		}
	}

	closeModal() {
		this.checkCurrencyInputs = false;
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
			this.claim = structuredClone(await Page.getClaimFromLastPage());
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
		}
	}
}
</script>

<i18n>
{
  "en": {
		"subTitle": "Claim Details"
  },
  "fr": {
		"subTitle": "Détails"
  }
}
</i18n>
