<template>
	<BaseCard>
		<BRow>
			<BCol cols="12" sm="11" md="11" lg="11" xl="11">
				<BRow>
					<BCol cols="12">
						<BaseDatePicker
							ref="toDate"
							datepicker-id="claim-date-id"
							class="datepicker input-margin"
							:value="value.claimDate"
							:label="datePickerLabel"
							:placeholder="$t('placeholder')"
							:start-date="false"
							:required="true"
							:max-error-date="getMaxDate()"
							:max-selectable-date="getMaxDate()"
							:required-error-msg="$t('error.requiredClaimDate')"
							:before-max-date-error-msg="$t('error.future')"
							pill
							is-left
							:automation-id="getAutomationId('provider-fields')"
							@input="updateClaimDate"
						/>
					</BCol>
				</BRow>
				<BRow>
					<BCol cols="12">
						<div v-if="!onlyParticipant">
							<label
								:class="[
									'participant-label',
									!value.claimDate || participantDisabled ? 'disabled' : ''
								]"
								for="participant-dropdown"
								>{{ $t('label.participant') }}</label
							><br />
							<ParticipantDropdown
								id="participant-dropdown"
								:value="value.participant"
								class="participant-dropdown input-margin"
								:placeholder="$t('placeholder')"
								:validation-rules="{ required: true }"
								:validation-messages="{
									required: $t('error.required')
								}"
								:show-placeholder="showPlaceholder || participantDisabled"
								:show-icon="showIcon"
								:show-dob="!showPlaceholder"
								:participants="members"
								:title-text="$t('placeholder')"
								:selected-participant="value.participant"
								:automation-id="getAutomationId('provider-fields')"
								@blur="checkParticipantValue"
								@click="updateParticipant"
							/>
							<ValidationProvider
								v-if="showParticipantErrorComputed"
								id="service-exists-validation"
								rules="required"
							>
								<div id="service-exists-error" class="d-block error-message">
									{{ $t('error.required') }}
								</div>
							</ValidationProvider>
						</div>
						<div v-else>
							<label class="participant-label" for="participant">{{
								$t('label.participant')
							}}</label>
							<p id="participant" class="participant-name">
								{{ onlyParticipant.firstName }} {{ onlyParticipant.lastName }}
							</p>
						</div>
					</BCol>
				</BRow>

				<!-- HEALTH CLAIM FLOW -->
				<BRow v-if="!isVision && !isPWA && !isDrugsOrDental">
					<BCol cols="12">
						<BaseSelect
							ref="select"
							:value="value.providerType ? value.providerType : ''"
							:label="$t('label.claimFor')"
							:placeholder="$t('placeholder')"
							:options="providerTypeList"
							:class="[
								'provider-styles',
								'input-margin',
								!value.participant || participantDisabled ? 'disabled' : ''
							]"
							:validation-rules="{ required: value.participant !== null }"
							:validation-messages="{
								required: $t('error.serviceRequired')
							}"
							:disabled="!value.participant"
							:automation-id="'provider-type-dropdown'"
							@change="updateProviderType"
						/>
					</BCol>
				</BRow>
				<BRow v-if="showOtherInput">
					<BCol cols="12">
						<BaseInput
							class="mb-0 input"
							:label="$t('label.otherProvider')"
							:value="otherProviderType"
							:validation-rules="{ required: true }"
							:validation-messages="{
								required: $t('error.otherRequired')
							}"
							:max-length="50"
							:automation-id="getAutomationId('provider-fields')"
							@input="updateOtherProviderType"
						/>
					</BCol>
				</BRow>

				<template v-if="isPWA">
					<BRow>
						<BCol cols="12">
							<ServiceSelect
								ref="select"
								:class="['input', isServiceDisabled ? 'disabled' : '']"
								:label="$t('label.pwaService')"
								:value="value.service"
								:services="pwaServices"
								:validation-rules="{ required: !isServiceDisabled }"
								:validation-messages="{ required: $t('error.servicePwaRequired') }"
								:placeholder="$t('placeholder')"
								:automation-id="getAutomationId('provider-fields')"
								:disabled="isServiceDisabled"
								@change="updateService"
							/>
						</BCol>
					</BRow>
					<BRow class="input-row">
						<BCol cols="12">
							<CurrencyInput
								:id="'currencyInput'"
								ref="currency"
								:class="['currency-input', isCurrencyDisabled ? 'disabled' : '']"
								:label="$t('label.currencyInput')"
								:value="value.amountPaid"
								:symbole="true"
								:min-value="-1"
								:disabled="isCurrencyDisabled"
								allow-zero
								:validation-rules="{ required: !isCurrencyDisabled, isMinValue: 0.01 }"
								:validation-messages="{
									required: $t('error.currencyRequired'),
									isMinValue: $t('error.valid')
								}"
								:automation-id="getAutomationId('provider-fields')"
								@change="updateAmount"
							/>
						</BCol>
					</BRow>
				</template>
			</BCol>
		</BRow>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// components
import { BRow, BCol } from 'bootstrap-vue';
import ParticipantDropdown from '@/components/submit-claim/provider-type/ClaimParticipantDropdown.vue';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseSelect from '@/components/common/base/BaseSelect';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseInput from '@/components/common/base/BaseInput';
import CurrencyInput from '@/components/common/CurrencyInput';
import ServiceSelect from '@/components/claim/claimService/ServiceSelect';
import { ValidationProvider } from 'vee-validate';

// Constants
import * as Claimtypes from '@/constants/ClaimTypes';
//utils
import { format } from 'date-fns';

// @vue/component
@Component({
	name: 'ProviderTypeChoiceFields',
	components: {
		BRow,
		BCol,
		BaseDatePicker,
		BaseSelect,
		ParticipantDropdown,
		ValidationProvider,
		CurrencyInput,
		ServiceSelect,
		BaseCard,
		BaseInput
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: null
		},
		members: {
			type: Array,
			default: null
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		providerTypeList: {
			type: Array,
			default: () => []
		},
		onlyParticipant: {
			type: Object,
			default: null
		},
		// This is used to determine what fields to show based on claimtype.
		claimType: {
			type: String,
			default: null
		},
		// This prop determines if we need to display the other text input.
		showOtherInput: {
			type: Boolean,
			default: false
		},
		// this is the value the member enters in the other input.
		otherProviderType: {
			type: String,
			default: null
		},
		// List of services for pwa claims.
		pwaServices: {
			type: Array,
			default: null
		}
	}
})
export default class ProviderTypeChoiceFields extends Vue {
	participantDisabled = true;
	showParticipantError = false;

	get selectedParticipant() {
		return this.value.participant ? this.value.participant : this.members[0];
	}
	/**
	 * If the claim date is not set then make the participant dropdown show the placeholder.
	 */
	get showPlaceholder() {
		return this.value.claimDate && this.members ? false : true;
	}

	/**
	 * This computed props are used to know if the PWA fields are enabled or disabled.
	 * It also enables and disables the required validation.
	 */
	get isCurrencyDisabled() {
		return this.value?.service === null;
	}

	get isServiceDisabled() {
		return this.pwaServices?.length === 0 || this.value.participant === null;
	}

	/**
	 * Only show the icon if the participant dropdown has a value selected.
	 */
	get showIcon() {
		return this.value?.participant ? true : false;
	}

	get isVision() {
		return this.claimType === Claimtypes.VISION;
	}

	get isPWA() {
		return this.claimType === Claimtypes.PWA;
	}

	get isDrugsOrDental() {
		return this.claimType === Claimtypes.DRUGS || this.claimType === Claimtypes.DENTAL;
	}

	get datePickerLabel() {
		return this.isPWA ? this.$t('label.purchaseDate') : this.$t('label.claimDate');
	}

	get showParticipantErrorComputed() {
		return this.showParticipantError;
	}

	set showParticipantErrorComputed(value) {
		this.showParticipantError = value;
	}

	updateClaimDate(claimDate) {
		this.$emit('claimDate', claimDate);
		// If the date is valid or empty then emit it. so the parent component can update the screen.
		if (this.isValidDate(claimDate)) {
			this.participantDisabled = false;
		} else {
			this.participantDisabled = true;
		}
	}

	updateParticipant(value) {
		// Clear all validation from the inputs.
		if (this.isPWA) {
			this.$refs?.select?.$children[0]?.$children[0]?.reset();
		} else {
			this.$refs?.select?.$children[0]?.reset();
		}
		this.$emit('participant', value.participant);
	}

	checkParticipantValue() {
		if (!this.value.participant) {
			this.showParticipantErrorComputed = true;
		} else {
			this.showParticipantErrorComputed = false;
		}
	}

	updateProviderType(providerType) {
		this.$emit('providerType', providerType);
	}

	updateService(service) {
		// Clear all validation from the inputs.
		this.$refs?.currency?.$children[0]?.$children[0]?.reset();
		this.$emit('service', service);
	}

	updateOtherProviderType(otherProviderType) {
		this.$emit('healthProviderType', otherProviderType);
	}

	updateAmount(amountPaid) {
		this.$emit('amount', amountPaid);
	}

	getMinDate() {
		return format(new Date(new Date().getFullYear() - 5, 0, 1), 'yyyy-MM-dd');
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}
	isValidDate(selectedDate) {
		return (
			!isNaN(new Date(selectedDate)) &&
			selectedDate.length === 10 &&
			selectedDate <= this.getMaxDate()
		);
	}
}
</script>

<style lang="scss" scoped>
.provider-choice-card {
	width: 100%;
}
.bottom-explanation {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
}
.date-suffix {
	font-size: 17px;
	font-family: $font-family-regular;
	font-weight: 300;
}
.datepicker ::v-deep(input),
.datepicker ::v-deep(.btn) {
	height: 42px;
}
.currency-input ::v-deep(.form-control) {
	height: 42px;
}
.datepicker ::v-deep(.form-control::placeholder) {
	letter-spacing: 0px;
}
.provider-styles ::v-deep select.form-control {
	height: 42px;
}

.input ::v-deep select.form-control {
	height: 42px;
}

.input-margin {
	margin-bottom: 1em;
}

.additional-service-card ::v-deep .validationGroup {
	margin-bottom: 0;
}
.participant-label {
	font-family: $font-family-headline;
	font-size: 18px;
}
.participant-name {
	font-family: $font-family-regular;
	font-size: 15px;
}
.disabled {
	opacity: 0.25;
}
.error-message {
	text-align: left;
	font-size: 14px;
	color: #ad0900;
	line-height: 17px;
	font-weight: 400;
	background-color: #fff6f6;
	border-color: #ad0900;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: -20px;
	margin-bottom: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "label": {
      "claimFor": "What is the claim for?",
	  "participant": "Who is this claim for?",
	  "claimDate": "Date of service",
	  "purchaseDate": "Date of purchase",
	  "otherProvider": "Please specify (e.g. \"knee brace,\" \"liver function test\")",
	  "pwaService": "Items or service purchased",
	  "currencyInput": "Amount you want to claim (not including tip)"
    },
    "placeholder": "Please select…",
    "error": {
	  "requiredClaimDate": "Please select a date.",
	  "future": "The date you've entered is in the future. Please check the date and try again.",
	  "required": "Please choose a plan member.",
	  "otherRequired": "Please describe the item or service in this claim.",
	  "serviceRequired": "Please choose what this claim is for.",
	  "servicePwaRequired": "Please choose which item or service you purchased.",
	  "currencyRequired": "Please enter the amount paid.",
	  "valid": "Please enter a valid amount."
    }
  },
  "fr": {
    "label": {
      "claimFor": "Sur quoi la demande de règlement porte-t-elle ?",
	  "participant": "Pour qui est cette demande ?",
	  "claimDate": "Date de service",
	  "purchaseDate": "Date d'achat",
	  "otherProvider": "Veuillez préciser (p. ex. attelle de genou, test pour fonction hépatique)",
	  "pwaService": "Articles ou services achetés",
	  "currencyInput": "Montant de la demande de règlement (pourboire non compris)"
    },
    "placeholder": "Veuillez sélectionner…",
    "error": {
	  "requiredClaimDate": "Veuillez selectionner une date.",
	  "future": "La date que vous avez entrée est ultérieure à la date d'aujourd'hui. Veuillez corriger la date et essayer à nouveau.",
	  "required": "Veuillez sélectionnez une personne assurée.",
	  "otherRequired": "Veuillez décrire l'article ou le service dans cette demande de règlement.",
	  "serviceRequired": "Précisez sur quoi porte la demande de règlement.",
	  "servicePwaRequired": "Choisissez l'article ou le service acheté.",
	  "currencyRequired": "Veuillez entrer le montant payé.",
	  "valid": "Veuillez entrer un montant valide."
    }
  }
}
</i18n>
