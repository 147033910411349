<template>
	<BaseCard class="card" automation-id="providerSearchFields">
		<BaseForm automation-id="providerSearchFields-form" @submit="hasClicked">
			<BaseInput
				:value="lastName"
				:label="$t('label.lastName')"
				:placeholder="$t('label.lastNamePlaceholder')"
				class="input-height"
				:validation-rules="{ required: true, alphaCharacters: true }"
				:validation-messages="{
					required: $t('error.lastNameRequired'),
					alphaCharacters: $t('error.alphaNum')
				}"
				:max-length="40"
				automation-id="provider-last-name"
				@input="updateLastName"
			/>
			<PhoneNumberInput
				:value="phone"
				:label="$t('label.phone')"
				automation-id="provider-phone"
				@input="updatePhone"
			/>
			<CityInput
				:label="$t('label.city')"
				:value="city"
				:is-required="true"
				automation-id="provider-city"
				:field-required-error-message="$t('error.cityRequired')"
				@input="updateCity"
			/>
			<ProvinceSelect
				:value="province"
				:required-error-msg="$t('error.province')"
				required
				automation-id="provider-address"
				@change="updateProvince"
			/>
			<BaseButton
				:label="$t('label.search')"
				:aria-label="$t('label.search')"
				variant="primary"
				:disabled="disableTheButton"
				:automation-id="getAutomationId('providerSearchSearchButton')"
				type="submit"
			/>
		</BaseForm>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseInput from '@/components/common/base/BaseInput.vue';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';
import CityInput from '@/components/common/CityInput';
import ProvinceSelect from '@/components/common/ProvinceSelect';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseForm from '@/components/common/base/BaseForm';
import { extend } from 'vee-validate';
extend('alphaCharacters', {
	validate: (value) => {
		const regex = /^[0-9A-Za-zàâçéèêëîïôûùüÿñæœ¿'.\s\d-]+$/i;
		return regex.test(value);
	},
	message: '{_field_} input cannot have special characters.'
});

// @vue/component
@Component({
	name: 'ProviderSearchFields',
	components: {
		BaseInput,
		PhoneNumberInput,
		CityInput,
		ProvinceSelect,
		BaseCard,
		BaseButton,
		BaseForm
	},
	mixins: [IdMixin],
	props: {
		lastName: {
			type: String,
			default: ''
		},
		phone: {
			type: String,
			default: ''
		},
		province: {
			type: String,
			default: ''
		},
		city: {
			type: String,
			default: ''
		}
	}
})
export default class ProviderSearchFields extends Vue {
	disableTheButton = true;
	/**
	 * Update city
	 * @param {string} city The city
	 */
	updateCity(city) {
		this.$emit('city', city);
		this.$nextTick(() => {
			this.checkFields();
		});
	}
	/**
	 * Update province
	 * @param {string} province The province
	 */
	updateProvince(province) {
		this.$emit('province', province);
		this.$nextTick(() => {
			this.checkFields();
		});
	}
	/**
	 * Update last name
	 * @param {string} lastName The last name
	 */
	updateLastName(lastName) {
		this.$emit('lastName', lastName);
		this.$nextTick(() => {
			this.checkFields();
		});
	}

	updatePhone(phone) {
		this.$emit('phone', phone);
		this.$nextTick(() => {
			this.checkFields();
		});
	}

	hasClicked() {
		this.$emit('searchForProvider');
	}

	checkFields() {
		let regex =
			/^[(]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[-\s]{0,1}[-\s]{0,1}[0-9]{4}$/;
		if (!this.lastName || !this.city || !this.province || (this.phone && !regex.test(this.phone))) {
			this.disableTheButton = true;
		} else {
			this.disableTheButton = false;
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep input,
::v-deep select {
	max-width: 100%;
	height: 2.8em;
}
::v-deep select.form-control {
	height: 2.8em;
}
</style>

<i18n>
{
  "en": {
    "label": {
      "lastName": "Last name of health professional",
	  "lastNamePlaceholder": "Enter a full or partial last name.",
      "phone": "Phone number of health professional",
	  "city": "City",
	  "search": "Search"
    },
    "error": {
	  "lastNameRequired": "Please enter your provider's last name.",
	  "cityRequired": "Please tell us which city your provider is in.",
	  "alphaNum": "Oops, it seems you've entered an invalid character. Please review and try again.",
	  "province": "Please tell us which province your provider is in."
    }
  },
  "fr": {
    "label": {
      "lastName": "Nom de famille du prof. de la santé",
	  "lastNamePlaceholder": "Entrez un nom de famille complet ou partiel.",
      "phone": "Numéro de téléphone du professionnel de la santé",
	  "city": "Ville",
	  "search": "Rechercher"
    },
    "error": {
	  "lastNameRequired": "Entrez le nom de famille de votre fournisseur.",
	  "cityRequired": "Indiquez la ville de votre fournisseur.",
	  "alphaNum": "Oups! Il semble que vous ayez entré un caractère invalide. Veuillez vérifier et essayer de nouveau.",
	  "province": "Indiquez la province de votre fournisseur"
    }
  }
}
</i18n>
