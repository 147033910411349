var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseForm',{ref:"health-service-card-form",attrs:{"data-tracking-id":_vm.getAutomationId('health-service-card-form'),"automation-id":_vm.getAutomationId('health-service-card-form')},on:{"submit":_vm.saveService}},[_c('BRow',[_c('BCol',{attrs:{"cols":"12","sm":"11","md":"11","lg":"11","xl":"11"}},[_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('BaseDatePicker',{ref:"toDate",staticClass:"datepicker input-margin",attrs:{"datepicker-id":"claim-date-id","value":_vm.healthService.dateOfService,"label":_vm.$t('label.claimDate'),"placeholder":_vm.$t('placeholder'),"start-date":false,"required":true,"max-error-date":_vm.getMaxDate(),"max-selectable-date":_vm.getMaxDate(),"required-error-msg":_vm.$t('error.requiredClaimDate'),"before-max-date-error-msg":_vm.$t('error.future'),"pill":"","is-left":"","automation-id":_vm.getAutomationId('provider-fields')},on:{"input":_vm.updateClaimDate}})],1)],1),_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[(!_vm.onlyParticipant)?_c('div',[_c('label',{class:[
								'participant-label',
								!_vm.healthService.dateOfService || _vm.participantDisabled ? 'disabled' : ''
							],attrs:{"for":"participant-dropdown"}},[_vm._v(_vm._s(_vm.$t('label.participant')))]),_c('br'),_c('ParticipantDropdown',{staticClass:"participant-dropdown input-margin",attrs:{"id":"participant-dropdown","value":_vm.healthService.participant,"placeholder":_vm.$t('placeholder'),"validation-rules":{ required: true },"validation-messages":{
								required: _vm.$t('error.required')
							},"show-placeholder":_vm.showPlaceholder || _vm.participantDisabled,"show-icon":_vm.showIcon,"show-dob":!_vm.showPlaceholder,"participants":_vm.activeParticipants,"title-text":_vm.$t('placeholder'),"selected-participant":_vm.healthService.participant,"automation-id":_vm.getAutomationId('provider-fields')},on:{"blur":_vm.checkParticipantValue,"click":_vm.updateParticipant}}),(_vm.showParticipantErrorComputed)?_c('ValidationProvider',{attrs:{"id":"service-exists-validation","rules":"required"}},[_c('div',{staticClass:"d-block error-message",attrs:{"id":"service-exists-error"}},[_vm._v(" "+_vm._s(_vm.$t('error.required'))+" ")])]):_vm._e()],1):_c('div',[_c('label',{staticClass:"participant-label",attrs:{"for":"participant"}},[_vm._v(_vm._s(_vm.$t('label.participant')))]),_c('p',{staticClass:"participant-name",attrs:{"id":"participant"}},[_vm._v(" "+_vm._s(_vm.onlyParticipant.firstName)+" "+_vm._s(_vm.onlyParticipant.lastName)+" ")])])])],1),_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('BaseSelect',{ref:"select",class:[
							'provider-styles',
							'input-margin',
							!_vm.healthService.participant || _vm.participantDisabled ? 'disabled' : ''
						],attrs:{"value":_vm.healthService.whatIsClaimFor ? _vm.healthService.whatIsClaimFor : '',"label":_vm.$t('label.claimFor'),"placeholder":_vm.$t('placeholder'),"options":_vm.providerTypeList,"validation-rules":{ required: _vm.healthService.participant !== null },"validation-messages":{
							required: _vm.$t('error.serviceRequired')
						},"disabled":!_vm.healthService.participant,"automation-id":'provider-type-dropdown'},on:{"change":_vm.updateProviderType}})],1)],1),(_vm.showOtherInput)?_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('BaseInput',{staticClass:"input",attrs:{"label":_vm.$t('label.otherProvider'),"value":_vm.healthService.specify,"validation-rules":{ required: true },"validation-messages":{
							required: _vm.$t('error.otherRequired')
						},"max-length":50,"automation-id":_vm.getAutomationId('provider-fields')},on:{"input":_vm.updateOtherProviderType}})],1)],1):_vm._e(),_c('BRow',[_c('BaseButton',{staticClass:"button button-save p-0",attrs:{"label":_vm.$t('button.save'),"aria-label":_vm.$t('button.save'),"variant":"link","automation-id":_vm.getAutomationId('service-card-save'),"type":"submit"}}),_c('BaseButton',{staticClass:"button p-0",attrs:{"label":_vm.$t('button.cancel'),"aria-label":_vm.$t('button.cancel'),"variant":"link","automation-id":_vm.getAutomationId('service-card-cancel')},on:{"click":_vm.cancelService}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }