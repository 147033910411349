<template>
	<SubmitClaimTemplate :percent-complete="14" :step-title="$t('title.step')">
		<TravelCheckListCard automation-id="beforeStartTravel" />

		<WizardButtonBar
			:show-back-button="false"
			:next-button-label="$t('label.next')"
			@navigate-next="navigateNext()"
		></WizardButtonBar>
	</SubmitClaimTemplate>
</template>

<script>
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import TravelCheckListCard from '@/components/submit-claim/before-start/TravelCheckListCard.vue';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';

export default {
	name: 'BeforeStartTravelPage',
	components: {
		SubmitClaimTemplate,
		WizardButtonBar,
		TravelCheckListCard
	},

	methods: {
		navigateNext() {
			this.$router.push({ name: SUBMIT_CLAIM.VERIFY_INFORMATION });
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": {
      "step": "Before you start..."
    },
    "label": {
      "next": "I'm ready"
    }
  },
  "fr": {
    "title": {
      "step": "Avant de commencer..."
    },
    "label": {
      "next": "Commencer"
    }
  }
}
</i18n>
