<template>
	<SubmitClaimTemplate
		:percent-complete="64"
		:step-title="$t('subTitle')"
		:fluid="isHSA ? true : false"
	>
		<BaseForm v-slot="{ invalid }">
			<div v-if="isHealth">
				<HealthClaimDetails
					:value="claim"
					:member="member"
					:participants="participants"
					:provider-types="providerTypes"
					:claim-type-description="claimTypeDescription"
					automation-id="claim-details"
					@changeNextStatus="disableNext"
				/>
			</div>
			<div v-else-if="isDrugOrDental">
				<DrugDentalClaimDetails
					:value="claim"
					:member="member"
					:participants="participants"
					:claim-type-description="claimTypeDescription"
					automation-id="claim-details"
					@changeNextStatus="disableNext"
				/>
			</div>
			<div v-else-if="isHSA">
				<HsaClaimDetails
					:value="claim"
					:member="member"
					automation-id="claim-details"
					@updateCob="updateClaim"
					@blur="updateHsaCOBAmount"
				/>
			</div>
			<div v-else>
				<ClaimDetails
					:value="claim"
					:service-types="serviceTypes"
					:service-date="serviceDate"
					:show-service-list="true"
					:member="member"
					:show-prescription-modal="showPrescriptionModal"
					:invalid-services="invalid || isNextDisabled"
					:provider-type="providerType"
					:check-currency-inputs="checkCurrencyInputs"
					automation-id="claim-details"
					@updateServiceTypeStatus="updateServiceTypeStatus"
					@showPrescriptionModal="togglePrescriptionModal"
					@changeNextStatus="disableNext"
					@input="updateClaim"
					@closeModal="closeModal"
				/>
			</div>
			<ErrorModal
				:data-test-automation-id="'go-back-modal'"
				:modal-show="showGoBackAlert"
				:error-title="$t('modal.goBackAlertTitle')"
				:error-message="$t('modal.goBackAlertText')"
				:has-close-button="false"
				:cant-close-on-esc="true"
				@change="showGoBackAlert = false"
			>
				<BRow>
					<BCol>
						<BaseButton
							:label="$t('modal.no')"
							variant="primary"
							class="mr-2"
							automation-id="go-back-no"
							@click="showGoBackAlert = false"
						/>
						<BaseButton
							:label="$t('modal.yes')"
							class="mr-auto"
							automation-id="go-back-yes"
							@click="confirmedNavigateBack()"
						/>
					</BCol>
				</BRow>
			</ErrorModal>
			<ErrorModal
				:data-test-automation-id="'min-amount-modal'"
				:modal-show="showAmountErrorModal"
				:error-title="$t('error.minTitle')"
				:error-message="$t('error.min')"
				no-hide-on-blur
				:has-close-button="false"
			>
				<template #default>
					<BRow>
						<BCol>
							<BaseButton
								:label="$t('error.btn.no')"
								variant="primary"
								class="ml-auto mr-2"
								automation-id="service-list-error-no"
								@click="focusZeroCurrency()"
							/>
							<BaseButton
								:label="$t('error.btn.yes')"
								class="mr-auto"
								automation-id="service-list-error-yes"
								@click="navigateNext"
							/>
						</BCol>
					</BRow>
				</template>
			</ErrorModal>
			<WizardButtonBar
				:disable-next="invalid || isNextDisabled"
				:auto-back="false"
				show-back-button
				@navigate-back="navigateBack()"
				@navigate-next="isHSA ? checkHsaCurrency() : navigateNext()"
			></WizardButtonBar>
		</BaseForm>
	</SubmitClaimTemplate>
</template>
<script>
import Vue from 'vue';
//Components
import Component from 'vue-class-component';
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import ClaimDetails from '@/components/submit-claim/claim-details/ClaimDetails';
import HealthClaimDetails from '@/components/submit-claim/claim-details/HealthClaimDetails';
import DrugDentalClaimDetails from '@/components/submit-claim/claim-details/DrugDentalClaimDetails';
import HsaClaimDetails from '@/components/submit-claim/claim-details/HsaClaimDetails';

import BaseForm from '@/components/common/base/BaseForm';
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import ErrorModal from '@/components/common/ErrorModal';

// Models
import Dependent from '@/models/Dependent.js';
import Page from '@/models/claims/Page';
import ServiceTypes from '@/models/claims/ServiceTypes';
import ClaimTypes from '@/models/claims/ClaimTypes';
import UserBenefits from '@/models/UserBenefits';
import MemberCardInfo from '@/models/MemberCardInfo';
import ProviderTypeSearch from '@/models/ProviderTypeSearch';
// UTILs
import { formatDateForUi } from '@/utils/date';
// Routes
import { SUBMIT_CLAIM } from '@/constants/Routes';
// Constants
import * as claimtypes from '@/constants/ClaimTypes';

@Component({
	name: 'ClaimDetailsPage',
	components: {
		SubmitClaimTemplate,
		BRow,
		BCol,
		WizardButtonBar,
		ClaimDetails,
		HealthClaimDetails,
		DrugDentalClaimDetails,
		HsaClaimDetails,
		BaseForm,
		BaseButton,
		ErrorModal
	}
})
export default class ClaimDetailsPage extends Vue {
	pageName = 'serviceList';
	page = {};
	isNextDisabled = false;
	serviceTypes = [];
	claim = {};
	serviceDate = '';
	isHSAEnabled = false;
	security = {};
	availableClaimTypes = [];
	specialtyCode = null;
	showPrescriptionModal = false;
	member = {
		firstName: null,
		fullName: null,
		dob: null
	};
	participants = null;
	searchCode = '';
	providerType = null;
	showGoBackAlert = false;
	showAmountErrorModal = false;
	originalBenefitServices = null;
	checkCurrencyInputs = false;
	claimTypeDescription = null;
	providerTypes = [];

	get isPractitioner() {
		return this.claim?.claimType === claimtypes.PRACTITIONER ? true : false;
	}
	get isHealth() {
		return this.claim?.claimType === claimtypes.HEALTH ? true : false;
	}
	get isDrugOrDental() {
		return this.claim?.claimType === claimtypes.DRUGS || this.claim?.claimType === claimtypes.DENTAL
			? true
			: false;
	}
	get isVision() {
		return this.claim?.claimType === claimtypes.VISION ? true : false;
	}
	get isPWA() {
		return this.claim?.claimType === claimtypes.PWA ? true : false;
	}

	get isHSA() {
		return this.claim?.claimType === claimtypes.HSA ? true : false;
	}

	async mounted() {
		this.$store.dispatch('updateLoading', true);
		await this.getOrCreatePage();
		await this.getMemberInfo();

		if (!this.isHSA) {
			if (this.isHealth || this.isDrugOrDental) {
				await this.getResourceUrls();
			} else {
				this.security = JSON.parse(sessionStorage.getItem('securityPolicy'));

				const userBenefits = await UserBenefits.getBenefitService(
					sessionStorage.getItem('email'),
					sessionStorage.getItem('apiToken'),
					this.$store.state.i18n.locale
				);
				this.isHSAEnabled = 'HSA' in userBenefits;
				if (this.claim) {
					this.benefitServiceLength = this.claim.benefitServices.length;
					this.serviceDate = this.claim.benefitServices[0]?.dateOfService;
					this.specialtyCode = this.claim.benefitServices[0].specialtyCode;
					this.searchCode = this.claim.benefitServices[0].specialtyCode;
				}
				await this.getProviderType();
				// to save on api calls when the lang toggle is press both service list are brought on mount
				// this will also helpw ith the transition for enbaled and disabled list items
				if (this.claim && this.claim.benefitServices[0]) {
					if (this.claim.claimType === 'XX-PSA') {
						this.searchCode = 'XX-PSA';
					}
					const serviceTypeResponse = await ServiceTypes.getServices(
						sessionStorage.getItem('email'),
						sessionStorage.getItem('apiToken'),
						this.$store.state.i18n.locale,
						this.claim.lob,
						this.claim.benefitServices[0].dateOfService,
						this.searchCode
					);
					let instance = this;
					// this is required since PWA doesn't have a specialty code set in prior screens like the health flow does
					if (this.claim.claimType === 'XX-PSA') {
						this.specialtyCode = serviceTypeResponse[0].specialtyCode;
						this.claim.benefitServices[0].specialtyCode = serviceTypeResponse[0].specialtyCode;
					}
					serviceTypeResponse.forEach(async (service) => {
						if (service.description === 'Other') {
							service.descriptionRbKey = 'Other';
						} else if (service.description === 'Autre') {
							service.descriptionRbKey = 'Autre';
						}
						let enabled = true;

						instance.serviceTypes.push({
							value: service.descriptionRbKey,
							service: service,
							text: service.description,
							isEnabled: enabled
						});
					});
				}
			}
		}
		// end loading animation
		this.$store.dispatch('updateLoading', false);
	}

	async created() {
		this.availableClaimTypes = await ClaimTypes.loadClaimType(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		if (this.isHealth || this.isDrugOrDental) {
			const result = this.availableClaimTypes.filter((obj) => {
				if (obj.claimType === this.claim.claimType) {
					return obj;
				}
			});
			this.claimTypeDescription = result[0].description;
		}
	}

	/**
	 * Retrieve the member name and dob using the claim particpant ID.
	 * This displays in the provider card.
	 */
	async getMemberInfo() {
		await Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			var participantId;
			if (this.claim?.participantId) {
				participantId = this.claim?.participantId;
			} else if (this.claim?.manualClaimServiceItems?.length > 0) {
				participantId = this.claim?.manualClaimServiceItems[0]?.participant;
			}
			this.participants = response.data;
			var participant = response.data.filter((participants) => {
				return participants.participantId === participantId;
			});
			this.member.firstName = participant[0].firstName;
			this.member.fullName = `${participant[0].firstName} ${participant[0].lastName}`;
			this.member.dob = formatDateForUi(participant[0].dateOfBirth, this.$store.state.i18n.locale);
		});
	}

	/**
	 * This method will retrieve the provider types for the provider type dropdown.
	 */
	async getProviderType() {
		await ProviderTypeSearch.getProviderTypes(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			const result = response.data.filter((providerType) => {
				return providerType.claimType === this.specialtyCode;
			});
			this.providerType = result[0]?.description;
		});
	}

	/**
	 * Retrieve the resource urls from the API to use the urls to get the provider types
	 */
	async getResourceUrls() {
		await MemberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		)?.then((response) => {
			if (response && (response.status === 201 || response.status === 200)) {
				// Retrieve the updated date for the privacy policy from S3.
				let secureUrl = response.data.json_claimNonRtaUrl.replace('http://', 'https://');
				fetch(secureUrl)
					.then((response) => response.json())
					.then((data) => {
						for (let i = 0; i < data?.length; i++) {
							this.providerTypes.push({
								value: data[i].typeName,
								text: data[i].typeName,
								isOther: data[i].isOther
							});
						}
					})
					.catch((error) => {
						if (error) {
							this.$store.dispatch('error', {
								name: 'generic'
							});
						}
					});
			}
		});
	}

	disableNext(isDisabled) {
		this.isNextDisabled = !isDisabled;
	}

	updateServiceTypeStatus(serviceType) {
		this.serviceTypes[serviceType.index].isEnabled = serviceType.isEnabled;
	}
	updateHsaCOBAmount(value) {
		this.claim.hsaEligibleServices[0].wasSubmittedToOtherFirst = value.wasSubmittedToOtherFirst;
		this.claim.hsaEligibleServices[0].amountPaidByOtherCarrier = value.amountPaidByOtherCarrier;
	}

	navigateBack() {
		this.showGoBackAlert = true;
	}

	async confirmedNavigateBack() {
		this.$router.back();
		this.$store.dispatch('submitClaimAdditionalQs', {});
		Page.deleteById(this.pageName);
	}

	async updateClaim(claim) {
		this.claim = claim;
	}

	async navigateNext() {
		await Page.updatePage(this.page, this.claim);
		const { autoHSA } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		if (
			(this.isPractitioner || this.isVision || this.isHealth || this.isDrugOrDental) &&
			this.availableClaimTypes.find((obj) => obj.claimType === 'XX-HSA') &&
			!autoHSA
		) {
			this.$router.push({
				name: SUBMIT_CLAIM.HSA
			});
		} else {
			if (this.isHSA) {
				if (this.claim.coordinationOfBenefits && this.claim.hsaEligibleServices?.length > 1) {
					this.$router.push({ name: SUBMIT_CLAIM.ATTACH_DOCUMENTS });
				} else if (
					this.claim?.hsaEligibleServices[0]?.wasSubmittedToOtherFirst &&
					!isNaN(this.claim?.hsaEligibleServices[0]?.amountPaidByOtherCarrier)
				) {
					this.$router.push({ name: SUBMIT_CLAIM.ATTACH_DOCUMENTS });
				} else {
					this.$router.push({ name: SUBMIT_CLAIM.CLAIM_SUMMARY });
				}
			} else {
				this.$router.push({ name: SUBMIT_CLAIM.ATTACH_DOCUMENTS });
			}
		}
	}

	closeModal() {
		this.checkCurrencyInputs = false;
	}

	togglePrescriptionModal() {
		this.$store.dispatch('isOkToLeaveClaimSubmission', true);
		this.showPrescriptionModal = true;
	}

	/**
	 * Used to give focus to the currencyInput which the user has prompted was wrong
	 */
	focusZeroCurrency() {
		this.showAmountErrorModal = false;
		this.$emit('closeModal');
		let inputs = document.querySelectorAll('input');
		let target = null;
		inputs.forEach((input) => {
			let formattedValue = input.value.replace('$', '');
			if (input.type === 'text' && formattedValue < 1) {
				target = input;
			}
		});
		// if (this.currencyInputTarget) target = document.getElementById(this.currencyInputTarget);
		this.$nextTick(() => {
			setTimeout(function () {
				if (target) target.focus();
			}, 200);
		});
	}

	checkHsaCurrency() {
		if (this.claim?.hsaEligibleServices[0].wasSubmittedToOtherFirst) {
			if (
				!isNaN(this.claim?.hsaEligibleServices[0].amountPaidByOtherCarrier) &&
				this.claim?.hsaEligibleServices[0].amountPaidByOtherCarrier < 1 &&
				this.claim?.hsaEligibleServices[0].amountPaidByOtherCarrier !== 0
			) {
				this.showAmountErrorModal = true;
				return;
			}
		}
		this.navigateNext();
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
			this.claim = structuredClone(await Page.getClaimFromLastPage());
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
		}
	}
}
</script>

<i18n>
{
  "en": {
		"subTitle": "Claim Details",
		"modal": {
			"goBackAlertTitle": "Are you sure you want to go back?",
			"goBackAlertText": "Any edits you've made or additional services you've added will be lost.",
			"no": "No",
			"yes": "Yes"
		},
		"error": {
			"minTitle": "This cost seems low.",
			"min" : "You've entered an amount less than $1.00. Is that correct?",
			"btn": {
				"yes": "Yes",
				"no": "No"
			}
		}
  },
  "fr": {
		"subTitle": "Détails",
		"modal": {
			"goBackAlertTitle": "Voulez-vous vraiment revenir en arrière?",
			"goBackAlertText": "Le cas échéant, les modifications apportées ou les services ajoutés seront perdus.",
			"no": "Non",
			"yes": "Oui"
		},
		"error": {
			"minTitle": "Ce coût semble trop bas.",
			"min" : "Vous avez entré un montant de moins de 1 $. Est-ce le bon montant?",
			"btn": {
				"yes": "Oui",
				"no": "Non"
		}
	}
  }
}
</i18n>
