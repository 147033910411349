<template>
	<div>
		<SubmitClaimTemplate
			v-if="!showModal && loaded"
			:percent-complete="14"
			:step-title="$t('title.step')"
		>
			<DrugCheckListCard automation-id="beforeStartDrug" />

			<WizardButtonBar
				:show-back-button="false"
				:next-button-label="$t('label.next')"
				@navigate-next="navigateNext()"
			></WizardButtonBar>
		</SubmitClaimTemplate>
		<ErrorModal
			:modal-show="showModal"
			has-close-button
			:error-title="$t('noClaimTypesModal.title')"
			@change="closeModal"
		>
			<BaseButton
				:automation-id="'modal-button'"
				variant="primary"
				:label="$t('noClaimTypesModal.button')"
				@click="closeModal"
			></BaseButton>
		</ErrorModal>
	</div>
</template>

<script>
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton.vue';
import DrugCheckListCard from '@/components/submit-claim/before-start/DrugCheckListCard.vue';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import Benefits from '@/models/coverage/BenefitsScepter';

export default {
	name: 'BeforeStartDrugs',
	components: {
		SubmitClaimTemplate,
		WizardButtonBar,
		DrugCheckListCard,
		ErrorModal,
		BaseButton
	},
	data() {
		return {
			showModal: false,
			loaded: false
		};
	},
	async created() {
		const { rta } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		if (!rta) {
			const benefits = await Benefits.getBenefitsCheckNoContent(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			);
			if (!benefits) {
				this.showModal = true;
			}
		}
		this.loaded = true;
	},
	methods: {
		navigateNext() {
			this.$router.push({ name: SUBMIT_CLAIM.VERIFY_INFORMATION });
		},
		closeModal() {
			this.showModal = false;
			this.$router.go(-1);
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": {
      "step": "Before you start..."
    },
    "label": {
      "next": "I'm ready"
    },
	"noClaimTypesModal": {
	  "title": "You currently don't have any coverage that supports online claim submissions.",
	  "button": "OK"
	}
  },
  "fr": {
    "title": {
      "step": "Avant de commencer..."
    },
    "label": {
      "next": "Commencer"
    },
	"noClaimTypesModal": {
	  "title": "Vous n'avez actuellement aucune couverture qui permet la soumission des demandes de règlement en ligne.",
	  "button": "OK"
	}
  }
}
</i18n>
