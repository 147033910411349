<!--/**
 * This component is called to show when the user does not have any PWA funds
 */-->
<template>
	<ErrorModal
		:modal-show="modalShow"
		:has-close-button="false"
		:cant-close-on-esc="true"
		no-hide-on-blur
		:error-title="$t('message.title')"
		:error-message="$t('message.body.part1')"
	>
		<BaseButton
			:label="$t('button.ok')"
			:automation-id="'btn-ok'"
			variant="primary"
			@click="onClick"
		/>
	</ErrorModal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
@Component({
	name: 'PwaFundError',
	components: {
		ErrorModal,
		BaseButton
	},
	props: {
		modalShow: {
			type: Boolean,
			default: false
		}
	}
})
export default class PwaFundError extends Vue {
	onClick() {
		this.$router.push({ name: Routes.CLAIM_TYPE });
	}
}
</script>
<i18n>
{
    "en": {
		"message": {
			"title": "No PWA Funds Available",
			"body": {
				"part1": "This claim can not be processed since all the PWA funds available in your account have been used."
			}
		},
		"button": {
			"ok": "OK"
		}
    },
    "fr": {
		"message" : {
			"title": "Aucun fonds dans le CGM",
			"body": {
				"part1":"Cette demande de règlement ne peut être traitée puisque tous les fonds de votre CGM ont été utilisés. "
			}
		},
		"button": {
			"ok": "OK"
		}
	}
}
</i18n>
