<template>
	<SubmitClaimTemplate :percent-complete="14" :step-title="$t('title.step')">
		<PWACheckListCard automation-id="beforeStartPWA" />

		<WizardButtonBar
			:show-back-button="false"
			:next-button-label="$t('label.next')"
			@navigate-next="navigateNext()"
		></WizardButtonBar>
		<PwaFundError :modal-show="modalShow" />
	</SubmitClaimTemplate>
</template>

<script>
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import PWACheckListCard from '@/components/submit-claim/before-start/PWACheckListCard.vue';
import PwaFundError from '@/components/submit-claim/before-start/PwaFundError.vue';
import PwaClaims from '@/models/claims/PwaClaims';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';

export default {
	name: 'BeforeStartClaim',
	components: {
		SubmitClaimTemplate,
		WizardButtonBar,
		PWACheckListCard,
		PwaFundError
	},
	data() {
		return {
			modalShow: false,
			hasFunds: true
		};
	},
	methods: {
		async getPwaFund() {
			this.$store.dispatch('updateLoading', true);
			this.hasFunds = await PwaClaims.hasPwaFunds(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			);
			this.$store.dispatch('updateLoading', false);
		},
		async navigateNext() {
			await this.getPwaFund();
			if (!this.hasFunds) {
				this.modalShow = true;
			} else {
				this.$router.push({ name: SUBMIT_CLAIM.VERIFY_INFORMATION });
			}
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": {
      "step": "Before you start..."
    },
    "label": {
      "next": "I'm ready"
    }
  },
  "fr": {
    "title": {
      "step": "Avant de commencer..."
    },
    "label": {
      "next": "Commencer"
    }
  }
}
</i18n>
