<template>
	<BCard>
		<CheckListItem>
			{{ $t('check.receipt') }}
			<template #description>{{ $t('description.receipt') }}</template>
		</CheckListItem>
		<CheckListItem>
			{{ $t('check.documents') }}
			<template #description>{{ $t('description.documents') }}</template>
		</CheckListItem>
		<CheckListItem>
			{{ $t('check.otherInsurance') }}
			<template #description>{{ $t('description.otherInsurance') }}</template>
		</CheckListItem>
		<CheckListItem class="checklist">
			{{ $t('check.healthProfessional') }}
			<template #icon>
				{{ $t('check.healthProfessionalLastWord') }}
				<InfoModal automation-id="beforeStartGeneric" :modal-title="$t('modal.title')">
					{{ $t('modal.text') }}
				</InfoModal>
			</template>
		</CheckListItem>
	</BCard>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import CheckListItem from '@/components/common/CheckListItem';
import InfoModal from '@/components/common/InfoModal';

export default {
	name: 'GenericCheckListCard',
	components: {
		BCard,
		CheckListItem,
		InfoModal
	},
	mixins: [IdMixin]
};
</script>

<style lang="scss" scoped>
.card-body {
	padding-bottom: 15px;
	width: 90%;
}

.checklist ::v-deep span {
	width: auto;
	display: inline-block;
}
</style>

<i18n>
{
  "en": {
    "check": {
      "receipt": "Do you have your itemized receipt handy?",
      "documents": "Do you have any other required documents you might need?",
      "otherInsurance": "Was your claim submitted to another insurer first?",
      "healthProfessional": "Is your claim from a Blue Cross-approved health",
      "healthProfessionalLastWord": "professional?"
    },
    "description": {
      "receipt": "This receipt should show the service you received, the name of the provider, the date it occurred, and that it has been paid in full.",
      "documents": "For some benefits, you might need to send us a prescription, itemized statement, or medical evaluation. Not sure if this applies to you? You can find specific requirements for each benefit under its coverage description.",
      "otherInsurance": "Make sure you have the claim statement from that insurer."
    },
    "modal": {
      "title": "What is a Blue Cross-approved health professional?",
      "text": "If the health professional or professional association that you've chosen is approved by Blue Cross, it means that you can submit claims and be reimbursed for services you receive from them.  If you're not sure if the health professional you've chosen is approved by Blue Cross, you can search for them in our Health Professional Finder, ask your provider, or contact us."
    }
  },
  "fr": {
    "check": {
      "receipt": "Avez-vous votre reçu détaillé à portée de main?",
      "documents": "Avez-vous les autres documents requis?",
      "otherInsurance": "Votre demande de règlement a-t-elle d'abord été soumise à un autre assureur?",
      "healthProfessional": "Votre demande de règlement vise-t-elle un professionnel de la santé approuvé par",
      "healthProfessionalLastWord": "Croix Bleue?"
    },
    "description": {
      "receipt": "Votre reçu doit comprendre certains renseignements comme le service rendu, le nom du fournisseur, la date et la confirmation du paiement complet.",
      "documents": "Pour certaines garanties, vous pourriez devoir nous envoyer une ordonnance, un relevé détaillé ou une évaluation médicale. Comment savoir si c’est le cas pour vous? Vous pouvez trouver les exigences particulières dans la description de la couverture pour chaque garantie.",
      "otherInsurance": "Assurez-vous d’avoir le relevé de demande de règlement de cet assureur."
    },
    "modal": {
      "title": "Qu’est-ce qu’un professionnel de la santé approuvé par Croix Bleue?",
      "text": "Si l’association professionnelle ou le professionnel de la santé auprès duquel vous avez obtenu des services est approuvé par Croix Bleue, cela signifie que vous pouvez soumettre des demandes de règlement et obtenir un remboursement pour ces services. Pour savoir si le professionnel de la santé que vous avez choisi est approuvé par Croix Bleue, vous pouvez le chercher au moyen de notre outil « Trouver un professionnel de la santé », le lui demander ou communiquer avec nous."
    }
  }
}
</i18n>
