import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class ProviderTypeSearch extends Model {
	static async getProviderTypes(email, token, locale) {
		let response = await axios.get(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + `/provider-types`,
			{
				headers: {
					'content-type': 'application/json',
					'Accept-language': locale,
					Authorization: token
				}
			}
		);
		sessionStorage.setItem('providerTypes', JSON.stringify(response.data));
		return response;
	}

	static async getRecentProviders(email, token, locale) {
		let response = await axios.get(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + `/recent-providers`,
			{
				headers: {
					'content-type': 'application/json',
					'Accept-language': locale,
					Authorization: token
				}
			}
		);
		return response;
	}
}
