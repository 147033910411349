<template>
	<BaseCard>
		<h3 class="h5">{{ $t('title') }}</h3>
		<CheckListItem v-if="typeOfClaims.shortTermDisability" id="shortTerm" class="check-list-item">
			<i18n path="disability.shortTerm.title" tag="span">
				<template #shortTermDisability>
					<b>{{ $t('disability.shortTerm.shortTermDisability') }}</b>
				</template> </i18n
			><template #description>
				<div class="mt-1">
					<ul>
						<li>{{ $t('disability.shortTerm.bullet1') }}</li>
					</ul>
				</div>
			</template></CheckListItem
		>
		<CheckListItem v-if="typeOfClaims.LongTermDisability" id="longTerm" class="check-list-item">
			<i18n path="disability.longTerm.title" tag="span">
				<template #longTermDisability>
					<b>{{ $t('disability.longTerm.longTermDisability') }}</b>
				</template> </i18n
			><template #description>
				<div class="mt-1">
					<ul>
						<li>{{ $t('disability.longTerm.bullet1') }}</li>
					</ul>
					<ul>
						<li>{{ $t('disability.longTerm.bullet2') }}</li>
					</ul>
				</div>
			</template></CheckListItem
		>
		<CheckListItem v-if="typeOfClaims.criticalIllness" id="criticial" class="check-list-item">
			<i18n path="disability.critical.title" tag="span">
				<template #criticalIllnessClaim>
					<b>{{ $t('disability.critical.criticalIllnessClaim') }}</b>
				</template> </i18n
			><template #description>
				<div class="mt-1">
					<ul>
						<li>{{ $t('disability.critical.bullet1') }}</li>
					</ul>
				</div>
			</template></CheckListItem
		>
		<CheckListItem class="checklist-item">
			<h4 class="mb-0 h6">{{ $t('forms.title') }}</h4>
			<template #description>
				<div :class="['mt-1', !isEnglish ? 'form-description-fr' : '']">
					{{ $t('forms.description') }}
				</div>
				<div class="mt-1">
					<ul>
						<li>{{ $t('forms.bullet1') }}</li>
					</ul>
				</div>
				<div class="mt-1">
					<ul>
						<li>{{ $t('forms.bullet2') }}</li>
					</ul>
				</div>
				<BaseButton
					automation-id="dci-checklist-button"
					variant="link"
					:label="$t('formsButton')"
					:icon="['fal', 'external-link-alt']"
					icon-position="left"
					class="p-1 pt-2 text-left"
					@click="openFormLink()"
				/>
			</template>
		</CheckListItem>
	</BaseCard>
</template>

<script>
// components
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import CheckListItem from '@/components/common/CheckListItem';

export default {
	name: 'DCICheckListCard',
	components: {
		BaseButton,
		BaseCard,
		CheckListItem
	},
	props: {
		// Prop is used to know which check list items to show.
		// Types used on this component are: shortTermDisability, longTermDisability, and critical Illness
		typeOfClaims: {
			type: Object,
			default: null
		}
	},
	computed: {
		isEnglish() {
			return this.$store.state.i18n.locale === 'en' ? true : false;
		}
	},
	methods: {
		openFormLink() {
			if (this.$root.$i18n.locale === 'fr') {
				window.open('https://www.medaviebc.ca/fr/adherents/formulaires', 'blank');
			} else {
				window.open(`https://www.medaviebc.ca/en/members/forms`, 'blank');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.check-list-item ul {
	margin-bottom: 0;
}
.check-list-item ::v-deep(.display-group-label) {
	font-family: $lato-font-family;
	font-size: 14px;
}
.check-list-item ::v-deep(.display-group-text) {
	font-size: 14px;
}
.check-list-item ::v-deep(.check-icon) {
	font-size: 22px;
}
.checklist-item {
	& ul:last-child,
	&:last-child > ::v-deep span {
		margin-bottom: 0;
	}
}
.form-description-fr {
	@media (max-width: 1200px) {
		width: 99%;
	}
}
</style>

<i18n>
{
  "en": {
   "title": "Do you have the required documents on hand?",
    "disability": {
		"shortTerm": {
			"title": "For a {shortTermDisability}, you will need to upload:",
			"shortTermDisability": "Short-Term Disability or Salary Continuance claim",
			"bullet1": "A completed Employee Statement (FORM - 400)"
		},
		"longTerm": {
			"title": "For a {longTermDisability}, you will need to upload:",
			"longTermDisability": "Long Term Disability claim",
			"bullet1": "A completed Employee Statement (FORM - 400)",
			"bullet2": "Proof of age, such as a driver's license, birth certificate or passport"
		},
		"critical": {
			"title": "For a {criticalIllnessClaim}, you will need to upload:",
			"criticalIllnessClaim": "Critical Illness claim",
			"bullet1": "A completed Critical Condition Claim form (FORM - 182)"
		}
    },
    "forms": {
		"title": "Do you have any other documents you might need?",
		"description": "If you have other relevant documents, please have these ready to upload. These may include:",
		"bullet1": "An Attending Physician's Statement (FORM - 401)",
		"bullet2": "Any other medical documentation related to your claim"
		
    },
    "formsButton": "Find forms and more information"
  },
  "fr": {
   "title": "Avez-vous les documents requis à votre disposition?",
    "disability": {
		"shortTerm": {
			"title": "Pour une {shortTermDisability}, vous devez téléverser les documents suivants :",
			"shortTermDisability": "demande de prestations d'invalidité de courte durée ou de maintien du salaire",
			"bullet1": "Le formulaire Demande de prestations - déclaration de l'employé dûment rempli (FORM-400F)"
		},
		"longTerm": {
			"title": "Pour une {longTermDisability}, vous devez téléverser les documents suivants :",
			"longTermDisability": "demande de prestations d'invalidité de longue durée",
			"bullet1": "Le formulaire Demande de prestations - déclaration de l'employé dûment rempli (FORM-400F)",
			"bullet2": "Une preuve d'âge (permis de conduire, acte de naissance ou passeport)"
		},
		"critical": {
			"title": "Pour une {criticalIllnessClaim}, vous devez téléverser les documents suivants :",
			"criticalIllnessClaim": "demande de prestations pour maladies graves",
			"bullet1": "Le formulaire Demande de prestation pour maladie grave dûment rempli (FORM-182F)"
		}
    },
    "forms": {
		"title": "Avez-vous d'autres documents dont vous pourriez avoir besoin?",
		"description": "Si vous avez d'autres documents pertinents, préparez-les pour les téléverser. Exemples de documents :",
		"bullet1": "La Déclaration du médecin traitant - général (FORM-401F)",
		"bullet2": "Tout autre document médical associé à votre demande de prestations"
		
    },
    "formsButton": "Formulaires et renseignements supplémentaires"
  }
}
</i18n>
