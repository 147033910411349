<template>
	<SubmitClaimTemplate :percent-complete="35" :step-title="$t('title.step')" :fluid="false">
		<BCol class="p-0" cols="12" xl="11">
			<ProviderSearchFields
				:last-name="lastName"
				:phone="phone"
				:province="province"
				:city="city"
				@city="updateCity"
				@province="updateProvince"
				@lastName="updateLastName"
				@phone="updatePhone"
				@searchForProvider="searchForProvider"
			/>
			<div v-if="providers && providers.length > 0" id="providerResultsSection" class="mb-2">
				<h2 v-scroll-to class="results-header">{{ $t('label.results') }}</h2>
				<p class="bottom-explanation">{{ chooseProviderType }}</p>
				<div
					v-for="(provider, index) in providers"
					:key="index"
					:automation-id="`provider${index}`"
				>
					<ProviderSearchResult :provider="provider" @choice="chooseProvider(provider)" />
				</div>
			</div>
			<WizardButtonBar
				:next-button-label="computedNextButtonText"
				:show-next-button="showNextButton"
				:next-button-variant="nextButtonVariant"
				:next-button-icon="null"
				@navigate-back="clearProviderSearch()"
				@navigate-next="navigateNext()"
			></WizardButtonBar>
			<WrongProviderTypeModal
				automation-id="provider-results-modal"
				:show-wrong-provider-type-modal="showWrongProviderTypeModal"
				@closeModal="closeModal"
				@changeSavedValueAndGoToNextPage="changeSavedValueAndGoToNextPage"
				@goBackToProviderTypeChoice="navigateBack()"
			/>
			<ErrorModal
				:error-title="$t('modal.title')"
				:error-message="$t('modal.message')"
				:has-close-button="false"
				:no-hide-on-blur="true"
				:cant-close-on-esc="true"
				:modal-show="showErrorModal"
			>
				<BaseButton
					class="mt-2"
					:label="$t('label.ok')"
					:aria-label="$t('label.ok')"
					variant="primary"
					automation-id="okIWroteTheCityWrong"
					@click="closeErrorModal"
				/>
			</ErrorModal>
		</BCol>
	</SubmitClaimTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import ProviderSearchCalls from '@/models/ProviderSearchCalls';
import ProviderTypeSearch from '@/models/ProviderTypeSearch';
import ContactInfo from '@/models/ContactInfo';
import { BCol } from 'bootstrap-vue';
import ProviderSearchFields from '@/components/claim/ProviderSearchFields';
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import BenefitProvider from '@/models/claims/BenefitProvider';
import ProviderSearchResult from '@/components/claim/ProviderSearchResult';
import WrongProviderTypeModal from '@/components/claim/WrongProviderTypeModal';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BenefitService from '@/models/claims/BenefitService';
import Page from '@/models/claims/Page';

// @vue/component
@Component({
	name: 'ProviderSearch',
	components: {
		ProviderSearchFields,
		SubmitClaimTemplate,
		WizardButtonBar,
		ProviderSearchResult,
		WrongProviderTypeModal,
		ErrorModal,
		BCol,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class ProviderSearch extends Vue {
	pageName = 'providerSearch';
	age = {};
	lastName = '';
	searchedLastName = '';
	phone = '';
	searchedPhone = null;
	province = '';
	searchedProvince = '';
	city = '';
	searchedCity = '';
	memberInfo = '';
	claim = {};
	providers = [];
	noResults = false;
	showResults = false;
	nextButtonText = null;
	nextButtonVariant = 'primary';
	showWrongProviderTypeModal = false;
	chosenProvider = {};
	showNextButton = false;
	showErrorModal = false;
	providerType = null;
	benefitProviderSearchInfo = {
		organizationName: '',
		phoneNumber: '',
		city: '',
		province: '',
		providerType: ''
	};

	get computedNextButtonText() {
		return this.nextButtonText ? this.nextButtonText : this.$t('label.next');
	}
	set computedNextButtonText(value) {
		this.nextButtonText = value;
	}

	async mounted() {
		this.$store.dispatch('updateLoading', true);
		await ContactInfo.getUserInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
		const searchedProvider = await ProviderSearchCalls.providerSearch(
			sessionStorage.getItem('email')
		);
		if (searchedProvider) {
			this.providers = searchedProvider.ProviderResults;
			this.lastName = searchedProvider.searchParameters.nameOfPerson;
			this.phone = searchedProvider.searchParameters.phone;
			this.city = searchedProvider.searchParameters.city;
			this.province = searchedProvider.searchParameters.province;
			this.noMatchingResultsAfterSearchSteps();
		}

		this.memberInfo = await ContactInfo.getUser(sessionStorage.getItem('email'));
		if (this.memberInfo) {
			this.province = this.memberInfo.province;
		}
		await this.getProviderType();
		this.$store.dispatch('updateLoading', false);
	}

	async created() {
		await this.getOrCreatePage();
	}

	/** Called when member navigates "back".  Used to delete this claim submit step's data. */
	async deleteStepData() {
		if (this.claim && this.claim.benefitProvider !== null) {
			this.claim.benefitProvider = null;
		}
	}

	/**
	 * Clear the provider search when navigating to the previous page.
	 */
	clearProviderSearch() {
		Page.deleteById(this.pageName);
		ProviderSearchCalls.clearProviderSearch();
	}

	navigateBack() {
		Page.deleteById(this.pageName);
		this.clearProviderSearch();
		this.$router.back();
	}

	/**
	 * Call the Provider search API to return a list of providers based on the search criteria the member entered.
	 */
	async searchForProvider() {
		this.providers = [];
		this.chosenProvider = {};
		this.$store.dispatch('updateLoading', true);
		var data = [];
		//Added searched fields just in case they change the fields and then click provider not found buttons.
		this.searchedLastName = this.lastName;
		this.searchedPhone = this.phone ? this.removeBraketsAndSpaces(this.phone) : '';
		this.searchedProvince = this.province;
		this.searchedCity = this.city;
		await ProviderSearchCalls.searchProviders(
			this.claim.benefitServices[0].specialtyCode,
			this.lastName,
			this.searchedPhone,
			this.city,
			this.province,
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			?.then((response) => {
				if (response.status === 204) {
					this.noResultsSteps();
				} else {
					this.noMatchingResultsAfterSearchSteps();
					data = response.data;
					this.providers = data;
				}
			})
			.catch(() => {
				this.$store.dispatch('error');
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
			});
	}

	/**
	 * This method will check that a member selected a correct provider based on the provider type selected on a previous screen.
	 * Then it will save the provider and navigate to the next screen.
	 */
	async chooseProvider(provider) {
		this.chosenProvider = provider;
		if (this.claim?.benefitServices[0].specialtyCode !== provider.providerType) {
			this.showWrongProviderTypeModal = true;
		} else {
			await this.createFoundBenefitProvider(this.chosenProvider);
			await Page.updatePage(this.page, this.claim);
			this.$router.push({ name: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT });
		}
	}

	/**
	 * If the member did not select a provider and instead found no results then create a new provider object and navigate to the next screen.
	 */
	navigateNext() {
		if (this.noResults) {
			this.claim.benefitProvider = null;
			this.createNotFoundBenefitProvider();
			Page.updatePage(this.page, this.claim);
			this.$router.push({ name: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT });
		} else {
			this.$store.dispatch('error');
		}
	}

	closeModal() {
		this.showWrongProviderTypeModal = false;
	}

	closeErrorModal() {
		this.showErrorModal = false;
	}

	/**
	 * This method is called when the member is prompted with a modal telling them their selected provider does not match the provider type, but they would like to proceed anyways.
	 */
	async changeSavedValueAndGoToNextPage() {
		this.claim.benefitServices[0].specialtyCode = this.chosenProvider.providerType;
		await BenefitService.updateBenefitServiceById(this.claim?.benefitServices[0]);
		this.showWrongProviderTypeModal = false;

		this.chooseProvider(this.chosenProvider);
	}

	get locale() {
		return this.$root.$i18n ? this.$root.$i18n.locale : 'en';
	}

	get chooseProviderType() {
		return `${this.$t('label.choose')} ${this.providerType} ${this.$t('label.list')} `;
	}

	noResultsSteps() {
		this.showErrorModal = true;
		this.showNextButton = true;
		this.noResults = true;
		this.computedNextButtonText = this.$t('label.noResults');
		this.nextButtonVariant = 'secondary';
	}

	noMatchingResultsAfterSearchSteps() {
		this.showNextButton = true;
		this.noResults = true;
		this.computedNextButtonText = this.$t('label.notListed');
		this.nextButtonVariant = 'secondary';
	}

	/**
	 * This method creates a "not found" provider object based on the criteria the member has entered.
	 */
	createNotFoundBenefitProvider() {
		this.claim.isProviderNotFound = true;
		this.benefitProviderSearchInfo.organizationName = this.searchedLastName;
		this.benefitProviderSearchInfo.phoneNumber = this.searchedPhone;
		this.benefitProviderSearchInfo.city = this.searchedCity;
		this.benefitProviderSearchInfo.province = this.searchedProvince;
		this.benefitProviderSearchInfo.providerType = this.claim?.benefitServices[0].specialtyCode;
		this.claim.benefitProviderSearchInfo = this.benefitProviderSearchInfo;
	}

	async createFoundBenefitProvider(provider) {
		this.claim.isProviderNotFound = false;
		this.claim.benefitProvider = await BenefitProvider.insertBenefitProvider(
			provider,
			sessionStorage.getItem('email')
		);
	}

	/**
	 * This member gets the selected provider type from the previous screen and uses the description to
	 * create a dynamic message for the member.
	 */
	async getProviderType() {
		let providers = null;
		await ProviderTypeSearch.getProviderTypes(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			?.then((response) => {
				providers = response.data;
			})
			.catch((err) => {
				this.$store.dispatch('error', err);
			});
		var result = providers?.filter((provider) => {
			return provider.claimType === this.claim?.benefitServices[0].specialtyCode;
		});
		if (result) {
			this.providerType = result[0]?.description;
		}
	}

	removeBraketsAndSpaces(text) {
		return text.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '');
	}

	/**
	 * Update city
	 * @param {string} city The city
	 */
	updateCity(city) {
		this.city = city;
	}
	/**
	 * Update province
	 * @param {string} province The province
	 */
	updateProvince(province) {
		this.province = province;
	}
	/**
	 * Update last name
	 * @param {string} lastName The last name
	 */
	updateLastName(lastName) {
		this.lastName = lastName;
	}

	updatePhone(phone) {
		this.phone = phone;
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
			this.claim = structuredClone(await Page.getClaimFromLastPage());
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
		}
	}
}
</script>
<style lang="scss" scoped>
.results-header {
	font-family: $font-family-headline;
	font-size: 24px;
	font-weight: 400;
	margin-top: 28px;
	margin-bottom: 0px;
	margin-right: 0px;
	margin-left: 0px;
}
.bottom-explanation {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
	margin-top: 10px;
	margin-bottom: 17px;
	margin-right: 0px;
	margin-left: 0px;
}
</style>
<i18n>
{
  "en": {
    "title": {
      "step": "Find your Health Professional"
	},
	"label": {
		"next": "Next",
		"noResults": "No results?",
		"notListed": "I don't see my provider",
		"results": "Results",
		"choose": "Choose your ",
		"list": "from the list.",
		"ok": "OK"
	},
	"modal": {
		"title": "No results found.",
		"message": "Please make sure the name and city you entered are valid."
	}
  },
  "fr": {
	"title": {
      "step": "Professionnels de la santé"
    },
	"label": {
		"next": "Suivant",
		"noResults": "Aucun résultat trouvé?",
		"notListed": "Je ne vois pas mon fournisseur",
		"results": "Résultats",
		"choose": "Sélectionnez votre ",
		"list": "dans la liste.",
		"ok": "OK"
	},
	"modal": {
		"title": "Aucun résultat trouvé.",
		"message": "Veuillez vous assurer que le nom et la ville que vous avez entrés sont valides."
	}
  }
}
</i18n>
