<template>
	<BCard>
		<CheckListItem class="receipt-checklist">
			{{ $t('check.receipt') }}
			<template #description
				>{{ $t('description.receipt') }}
				<ul class="m-0">
					<li>{{ $t('description.receiptBulletOne') }}</li>
					<li>{{ $t('description.receiptBulletTwo') }}</li>
					<li>{{ $t('description.receiptBulletThree') }}</li>
					<li>{{ $t('description.receiptBulletFour') }}</li>
				</ul>
			</template>
		</CheckListItem>
		<CheckListItem>
			{{ $t('check.otherInsurance') }}
			<template #description>{{ $t('description.otherInsurance') }}</template>
		</CheckListItem>
		<CheckListItem class="checklist">
			{{ $t('check.healthProfessional') }}
			<template #icon>
				{{ $t('check.healthProfessionalLastWord') }}
				<InfoModal automation-id="beforeStartGeneric">
					{{ $t('modal.text') }}
				</InfoModal>
			</template>
		</CheckListItem>
	</BCard>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import CheckListItem from '@/components/common/CheckListItem';
import InfoModal from '@/components/common/InfoModal';

export default {
	name: 'DrugCheckListCard',
	components: {
		BCard,
		CheckListItem,
		InfoModal
	},
	mixins: [IdMixin]
};
</script>

<style lang="scss" scoped>
.card-body {
	padding-bottom: 15px;
	width: 90%;
}
.receipt-checklist {
	width: 90%;
}

.checklist ::v-deep span {
	width: auto;
	display: inline-block;
}
</style>

<i18n>
{
  "en": {
    "check": {
      "receipt": "Do you have your official pharmacy receipt?",
      "otherInsurance": "Was your claim submitted to another insurer first?",
      "healthProfessional": "Is your claim from a Blue Cross-approved health",
      "healthProfessionalLastWord": "professional?"
    },
    "description": {
      "receipt": "The prescription receipt for your medication is not the same as the transaction receipt. It will show:",
      "receiptBulletOne": "Who the prescription is for",
      "receiptBulletTwo": "The Drug Identification Number (DIN)",
      "receiptBulletThree": "The quantity dispensed",
      "receiptBulletFour": "The date you received it",
      "otherInsurance": "Make sure you have the claim statement from that insurer."
    },
    "modal": {
      "text": "If the health professional or professional association that you've chosen is approved by Blue Cross, it means that you can submit claims and be reimbursed for services you receive from them.  If you're not sure if the health professional you've chosen is approved by Blue Cross, you can search for them in our Health Professional Finder, ask your provider, or contact us."
    }
  },
  "fr": {
    "check": {
      "receipt": "Avez-vous votre reçu officiel de la pharmacie?",
      "otherInsurance": "Votre demande de règlement a-t-elle d'abord été soumise à un autre assureur?",
      "healthProfessional": "Votre demande de règlement vise-t-elle un professionnel de la santé approuvé par",
      "healthProfessionalLastWord": "Croix Bleue?"
    },
    "description": {
      "receipt": "Le reçu d'ordonnance de votre médicament n'est pas le même que le reçu de la transaction. Il indique :",
      "receiptBulletOne": "le nom de la personne à qui est destinée l'ordonnance",
      "receiptBulletTwo": "Le numéro d'identification du médicament (DIN)",
      "receiptBulletThree": "La quantité délivrée",
      "receiptBulletFour": "La date à laquelle vous l'avez reçue",
      "otherInsurance": " Assurez-vous d'avoir le relevé de demande de règlement de cet assureur."
    },
    "modal": {
      "text": "Si l’association professionnelle ou le professionnel de la santé auprès duquel vous avez obtenu des services est approuvé par Croix Bleue, cela signifie que vous pouvez soumettre des demandes de règlement et obtenir un remboursement pour ces services. Pour savoir si le professionnel de la santé que vous avez choisi est approuvé par Croix Bleue, vous pouvez le chercher au moyen de notre outil « Trouver un professionnel de la santé », le lui demander ou communiquer avec nous."
    }
  }
}
</i18n>
