<template>
	<SubmitClaimTemplate :percent-complete="14" :step-title="$t('title.step')">
		<HSACheckListCard automation-id="beforeStartGeneric" />

		<WizardButtonBar
			:show-back-button="false"
			:next-button-label="$t('label.next')"
			@navigate-next="navigateNext()"
		></WizardButtonBar>
		<slot
			><HsaFundError :modal-show="modalShow" />
			<HsaClaimError :no-claim-modal="showElgibleFundModal"
		/></slot>
	</SubmitClaimTemplate>
</template>

<script>
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import HSACheckListCard from '@/components/submit-claim/before-start/HSACheckListCard.vue';
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import HsaFundError from '../hsa/HsaFundError.vue';
import HsaClaims from '@/models/claims/HsaClaims';
import HsaClaimError from '../hsa/HsaEligibleClaimError.vue';

export default {
	name: 'BeforeStartClaim',
	components: {
		SubmitClaimTemplate,
		WizardButtonBar,
		HSACheckListCard,
		HsaFundError,
		HsaClaimError
	},
	data() {
		return {
			modalShow: false,
			hasFunds: null,
			eligibleHsaClaims: null,
			showElgibleFundModal: false
		};
	},
	methods: {
		async getHsaFund() {
			this.hasFunds = await HsaClaims.hasHsaFunds(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			);
		},

		async eligibleHsaClaim() {
			this.eligibleHsaClaims = await HsaClaims.getHsaEligibleClaims(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			);
		},

		async navigateNext() {
			this.modalShow = false;
			this.showElgibleFundModal = false;

			await this.getHsaFund();

			if (this.hasFunds) {
				await this.eligibleHsaClaim();
				if (this.eligibleHsaClaims && Object.keys(this.eligibleHsaClaims.data).length > 0) {
					this.$router.push({
						name: SUBMIT_CLAIM.VERIFY_INFORMATION
					});
				} else {
					this.showElgibleFundModal = true;
				}
			} else {
				this.modalShow = true;
			}
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": {
      "step": "Wait!"
    },
    "label": {
      "next": "I'm ready"
    }
  },
  "fr": {
    "title": {
      "step": "Attendez!"
    },
    "label": {
      "next": "Commencer"
    }
  }
}
</i18n>
