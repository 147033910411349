<!--
  Parent router view for all Submit Claim pages that are part of the submit "wizard".
-->
<template>
	<router-view ref="submitClaimRoute"></router-view>
</template>

<script>
// Needed for message box (start)
import Vue from 'vue';
import { BVModalPlugin } from 'bootstrap-vue';
Vue.use(BVModalPlugin);
// Needed for message box (end)

import { DASHBOARD, SUBMIT_CLAIM } from '@/constants/Routes.js';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager.js';
import Claims from '@/models/claims/Claims.js';
import Page from '@/models/claims/Page.js';

export default {
	name: 'SubmitClaim',
	mixins: [BreadcrumbsManager],

	/**
	 * Triggered when a member enters the submit claim "wizard" or when a member refreshes the page (F5).
	 *
	 * - When a member starts the claim wizard, initialize the route history.
	 *
	 * - When a member refreshes the page (F5 or ctrl+F5) or when a member tries to enter the submit-claim flow
	 *   with a saved bookmark, clear any claim data and send the member to the claim type selection page.
	 *
	 * - When a member tries to navigate "back" from the claim success page, do not navigate back through the claim
	 *   submission pages.  Skip those and send the member to the claim type selection page.
	 *
	 * - When a member does a browser "back button" from anywhere other than a submit claim flow page, send
	 *   the member to the claim type selection page.  We cleared the claim object when the member left and we
	 *   don't want the member the try to restart a claim without all the data.  We check this by looking for the
	 *   claimType on the Claim object.
	 */
	async beforeRouteEnter(to, from, next) {
		// Check for claim type on Claim object.
		const hasClaimType = await Claims.hasClaimType();

		// Member was on claim success page and pressed browser back button.
		const isFromClaimSuccessPage = from.name === SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS;

		// Member refreshed page or a member has bookmarked a page in submit-claim.
		const isPageRefresh = from.name === null && from.path === '/';

		if (isFromClaimSuccessPage || isPageRefresh || !hasClaimType) {
			// eslint-disable-next-line
			next((vm) => {
				Page.clearPages();
				Claims.clearClaim();
				vm.$store.dispatch('submitClaimFiles', []);
				vm.$store.dispatch('submitClaimCOB', {});
				vm.$store.dispatch('submitClaimAdditionalDocuments', {});
				vm.$store.dispatch('submitClaimAdditionalQs', {});
				vm.hasRefreshedPage = true;
				vm.$router.push({ name: SUBMIT_CLAIM.CLAIM_TYPE });
			});
		}

		next((vm) => {
			vm.routeHistory.clear();
			vm.$store.dispatch('isLocaleToggleDisabled', true);
		});
	},

	/**
	 * This executes before a new step (page) is displayed in the wizard.
	 *
	 * Add the previous page to the route history.  This allows us to check if we are going backwards
	 * (ex: member clicks "back" or back arrow in the browser). When going "back" is detected, invoke a method on
	 * the step (page) to delete the data that was entered on that step.  Also remove the step from history
	 * since we are going backwards.  The "history" is more of "Which pages were before in flow?" rather than a
	 * full history of back and forth navigation.
	 */
	async beforeRouteUpdate(to, from, next) {
		this.$store.dispatch('isOkToLeaveClaimSubmission', false); // Reset flag.
		this.routeHistory.add(from.name);

		if (this.routeHistory.has(to.name)) {
			// We are going "back" in the claim submit flow, delete step data.
			await this.$refs.submitClaimRoute?.deleteStepData?.();
			this.routeHistory.delete(from.name);
		} else {
			// Not letting moving to "next" step on browser forward button if there are no documents attached or amount of claim is not entered.
			if (from.name === SUBMIT_CLAIM.ATTACH_DOCUMENTS) {
				this.$refs.submitClaimRoute?.returnToCurrentStep?.();
			}
			if (from.name === SUBMIT_CLAIM.SERVICE_LIST) {
				await this.$refs.submitClaimRoute?.returnToCurrentStep?.();
			}
		}
		next();
	},

	/**
	 * This only executes when leaving <router-view>.  This happens naturally at the end of the submit claim flow
	 * or when a member attempts to navigate elsewhere.  Ex: A member is in middle of claims flow and clicks on My Account.
	 *
	 * We warn members that they will loose their claims data if they leave to another menu option.  There are some
	 * exceptions when the warning modal is not displayed.
	 */
	async beforeRouteLeave(to, from, next) {
		// clear claim when navigating away from claim success page
		if (from.name === SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS) await Claims.clearClaim();

		// Clear claim state objects when navigating away
		this.$store.dispatch('submitClaimCOB', {});
		this.$store.dispatch('submitClaimAdditionalDocuments', {});
		this.$store.dispatch('submitClaimAdditionalQs', {});

		// Do not show warning when leaving "before you start" pages.  There is no data on the page.
		// Do not show warning when going to the final "success" page.  All data already posted to the API.
		const doNotShowWarningPages = [
			SUBMIT_CLAIM.BEFORE_START_GENERIC,
			SUBMIT_CLAIM.BEFORE_START_DENTAL,
			SUBMIT_CLAIM.BEFORE_START_DRUGS,
			SUBMIT_CLAIM.BEFORE_START_HSA,
			SUBMIT_CLAIM.BEFORE_START_PWA,
			SUBMIT_CLAIM.BEFORE_START_TRAVEL,
			SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS
		];

		// - Do not show warning if member refreshes page.  Just send them to the select claim type page.
		// - There are various other cases when it's valid to leave a claim submission page.  For example, if a claim is for
		//   worker's comp, the claim page shows it's own modal saying we can't process the claim and the member is sent to the
		//   claim type page.  We don't want to show another warning on top of that saying "hey, you will loose you data...". In
		//   these cases, the claim page sets a Vuex flag to say it's OK to leave the page.
		const doNotShowWarning =
			doNotShowWarningPages.includes(from.name) ||
			this.hasRefreshedPage ||
			this.$store.state.isOkToLeaveClaimSubmission;

		if (doNotShowWarning) {
			// Do not show warning and leave submit claim wizard.
			if (to.name !== SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS) await Claims.clearClaim();
			this.$store.dispatch('submitClaimFiles', []);
			this.$store.dispatch('isLocaleToggleDisabled', false);
			this.$store.dispatch('isOkToLeaveClaimSubmission', false);
			next();
		} else {
			// Show warning and ask member to confirm to stay or leave.
			const isLeaveClaim = await this.displayLeaveClaimMessageBox();

			if (isLeaveClaim) {
				// Member selected to leave the submit claim flow.
				this.$store.dispatch('isLocaleToggleDisabled', false);
				await Page.clearPages();
				await Claims.clearClaim();
				this.$store.dispatch('submitClaimFiles', []);
				next();
			}
		}
	},

	data() {
		return {
			routeHistory: new Set(),
			hasRefreshedPage: false
		};
	},

	created() {
		// Breadcrumbs for all pages in this router view.  Same for all submit claim pages.
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.submitClaim', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.submitClaim', 'fr') }
			]
		);
	},

	methods: {
		/**
		 * NOTE:  THIS IS A UNIQUE SITUATION.  IT'S NOT A PATTERN THAT SHOULD BE REPEATED, UNLESS A VERY SPECIFIC
		 *        NEED IS ENCOUNTERED.
		 *
		 * This methods will display a Bootstrap Vue "modal display box".  This is an async method used to wait for user
		 * input before doing any needed navigation.  A regular BS modal did not work because the Javascript code
		 * continues to execute even though a regular modal is displayed.  Hence the need for the async rendering
		 * method.
		 *
		 * Method returned values:
		 *
		 *   - Member clicks on button to leave claim flow: true
		 *   - Member clicks on button to stay on claim flow: false
		 *   - Member presses "esc" or clicks outside the modal to close it: null
		 */
		async displayLeaveClaimMessageBox() {
			const h = this.$createElement;

			const iconVNode = h('FontAwesomeIcon', {
				class: ['icon-warning'],
				props: { icon: ['fal', 'exclamation-triangle'] }
			});

			const titleVNode = h('p', { class: ['h4', 'msg-box-title'] }, [
				`${this.$t('messageBox.title')}`
			]);

			const descriptionVNode = h(
				'p',
				{ class: ['msg-box-description'] },
				`${this.$t('messageBox.message')}`
			);

			const containerVNode = h('div', { class: 'msg-box-container' }, [
				iconVNode,
				titleVNode,
				descriptionVNode
			]);

			try {
				const memberChoice = await this.$bvModal.msgBoxConfirm([containerVNode], {
					centered: true,
					okTitle: this.$t('messageBox.button.leaveClaim'),
					cancelTitle: this.$t('messageBox.button.stay'),
					okVariant: 'outline-secondary',
					cancelVariant: 'primary',
					footerClass: 'msg-box-footer'
				});
				return memberChoice;
			} catch (err) {
				this.$store.dispatch('error', { name: 'SubmitClaim', err: this.$t('errorMsg') });
			}
		}
	}
};
</script>

<style lang="scss" scoped>
// Warning: These styles affect all components in the route view. Ensure they have unique class names.
// These styles are only needed for the message box
.icon-warning {
	display: block;
	font-size: 32px;
	margin-top: 30px;
	margin-bottom: 20px;
	& ::v-deep path {
		color: $orange-dark;
	}
}

.msg-box-title {
	margin-bottom: 15px;
}

.msg-box-description {
	margin-bottom: 0;
	margin-bottom: -5px;
}

::v-deep .msg-box-footer {
	justify-content: flex-start;
	margin-left: 30px;
	margin-bottom: 30px;
	padding: 0;

	& > .btn {
		min-width: 75px;
		border-radius: 30px;
	}

	& > .btn-outline-secondary {
		background-color: $gray-very-light;
	}

	& > .btn-outline-secondary:hover {
		background-color: $blue-dark;
	}
}
</style>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "submitClaim": "Submit a Claim"
    },
    "messageBox": {
      "title": "Your claim will not be saved.",
      "message": "Do you still want to end your session?",
      "button": {
        "leaveClaim": "Yes",
        "stay": "No"
      }
    },
    "errorMsg": "Sorry, an unexpected error has occurred."
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "submitClaim": "Soumettre une demande"
    },
    "messageBox": {
      "title": "Votre demande de règlement ne sera pas enregistrée.",
      "message": "Voulez-vous tout de même fermer votre session?",
      "button": {
        "leaveClaim": "Oui",
        "stay": "Non"
      }
    },
    "errorMsg": "Désolés, une erreur inattendue s'est produite."
  }
}
</i18n>
