<template>
	<BaseCard class="m-0 p-0 base-card">
		<h3 class="ml-4 title">{{ $t('title') }}</h3>
		<hr class="m-0" />
		<div
			v-for="(provider, index) in recentProviders"
			:key="index"
			:automation-id="`provider${index}`"
		>
			<button class="card-button" @click="chooseProvider(provider)">
				<BRow class="m-0 button-row">
					<BCol cols="10" xl="11" lg="11" md="11">
						<h4 v-if="provider.organizationName" class="mb-1 name">
							{{ provider.organizationName }}
						</h4>
						<h4 v-else class="mb-1 name">{{ provider.firstName }} {{ provider.lastName }}</h4>
						<p class="m-0 provider-type">{{ provider.providerTypeName }}</p>
					</BCol>
					<BCol class="icon-column" cols="2" xl="1" lg="1" md="1">
						<font-awesome-icon
							:icon="['fal', 'chevron-right']"
							class="ml-auto icon"
						></font-awesome-icon>
					</BCol>
				</BRow>
			</button>
			<hr class="m-0" />
		</div>
		<BaseButton
			:label="$t('label.add')"
			:aria-label="$t('label.add')"
			:icon="['fal', 'plus-circle']"
			icon-position="left"
			:pill="false"
			variant="link"
			class="m-2 add-button"
			:automation-id="getAutomationId('shareMemberCardAdd')"
			:data-tracking-id="getAutomationId('shareMemberCardAdd')"
			@click="addProvider"
		/>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard';
import BaseButton from '@/components/common/base/BaseButton';
import { BRow, BCol } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'RecentProviderList',
	components: {
		BaseCard,
		BaseButton,
		BCol,
		BRow
	},
	mixins: [IdMixin],
	props: {
		recentProviders: {
			type: Array,
			default: null
		}
	}
})
export default class RecentProviderList extends Vue {
	chooseProvider(provider) {
		this.$emit('click', provider);
	}
	addProvider() {
		this.$emit('add');
	}
}
</script>

<style lang="scss" scoped>
.base-card ::v-deep(.card-body) {
	padding: 30px 0px 0px 0px;
}
.title {
	font-family: $font-family-regular;
	font-size: 18px;
	line-height: 18px;
	font-weight: 400;
	margin-bottom: 1rem;
}
.name {
	font-family: $font-family-regular;
	font-size: 17px;
	line-height: 17px;
	color: $blue;
}
.button-row {
	padding: 15px;
}
.provider-type {
	font-size: 14px;
	font-weight: 300;
}

.card-button {
	text-align: left;
	background: transparent;
	border: none;
	border-radius: 10px;
	margin: 0;
	padding: 0;
	width: 100%;
	&:hover {
		& > div {
			box-shadow: 0px 0px 10px $blue-light;
		}
		& h3 {
			color: $dark-blue;
		}
	}
}
.icon-column {
	margin-top: auto;
	margin-bottom: auto;
}
.icon {
	font-size: 18px;
}
.icon ::v-deep path {
	color: $blue;
}
.add-button ::v-deep svg {
	font-size: 22px;
	margin-right: 10px;
}
</style>

<i18n>
{
	"en": {
		"title": "Select your provider",
		"label": {
			"add": "My provider isn't listed"
		}
	},
	"fr": {
		"title": "Sélectionner votre fournisseur",
		"label": {
			"add": "Mon fournisseur n’est pas dans la liste"
		}
	}
}
</i18n>
