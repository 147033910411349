<template>
	<SubmitClaimTemplate
		:percent-complete="percentComplete"
		:step-title="showCheckBoxes ? $t('title.question') : $t('title.step')"
	>
		<BaseForm v-slot="{ invalid }" data-tracking-id="dci-form" automation-id="dci-form">
			<!-- If member is on the Case manager stage of this screen show the radio button -->
			<template v-if="showCaseManager">
				<BaseCard>
					<YesNoToggle
						:value="caseManager"
						automation-id="dci-yesno"
						class="case-manager-toggle"
						:label="$t('label.caseManager')"
						:required-error-msg="$t('error.caseManager')"
						is-required
						@input="updateCaseManager"
					/>
				</BaseCard>
			</template>
			<!-- Once the member has selected that they do not have a case manager then show the checkboxes to determine what type of claim they will submit -->
			<template v-if="showCheckBoxes">
				<!-- Base card is used for validation to know if the member has selected at least once checkbox -->
				<BaseCard
					@click.native="checkErrorAtLeastOneValue"
					@keydown.native.tab="checkErrorAtLeastOneValue"
				>
					<!-- If the member is submitting a disability claim, show this disclaimer to alert them to go to the life claim flow. -->
					<template v-if="isDci">
						<BRow>
							<BCol cols="12">
								<FontAwesomeIcon
									class="icon"
									:icon="['fal', 'exclamation-triangle']"
									aria-hidden="true"
								/>
								<span class="terminal-illness-title">{{ $t('terminalIllness.title') }}</span>
							</BCol>
						</BRow>
						<BRow>
							<BCol cols="12">
								<p class="terminal-illness-text">{{ $t('terminalIllness.text') }}</p>
							</BCol>
						</BRow>
						<hr />
						<br />
					</template>
					<!-- Show checkboxes based on if the member is submitting a disability or life claim -->
					<template v-if="isDci">
						<BaseCheckBox
							checkbox-name="short-term-disability"
							class="checkbox"
							:checked="typeOfClaims.shortTermDisability"
							:label="$t('checkbox.shortTermDisability')"
							automation-id="short-term-disability"
							@input="updateShortTermDisability"
						/>
						<br />
						<BaseCheckBox
							checkbox-name="longer-term-disability"
							class="checkbox"
							:checked="typeOfClaims.LongTermDisability"
							:label="$t('checkbox.longTermDisability')"
							automation-id="long-term-disability"
							@input="updateLongTermDisability"
						/>
						<br />
						<BaseCheckBox
							checkbox-name="critical-illness"
							class="checkbox mb-0"
							:checked="typeOfClaims.criticalIllness"
							:label="$t('checkbox.criticalIllness')"
							automation-id="critical-illness"
							@input="updateCriticalIllness"
						/>
					</template>
					<template v-else>
						<BaseCheckBox
							checkbox-name="life"
							class="checkbox"
							:checked="typeOfClaims.life"
							:label="$t('checkbox.life')"
							automation-id="life"
							@input="updateLife"
						/>
						<br />
						<BaseCheckBox
							checkbox-name="accident-dismemberment"
							class="checkbox"
							:checked="typeOfClaims.accidentalDismemberment"
							:label="$t('checkbox.accidentDismemberment')"
							automation-id="accident-dismemberment"
							@input="updateAccidentalDismemberment"
						/>
						<br />

						<BaseCheckBox
							checkbox-name="terminal-illness"
							class="checkbox mb-0"
							:checked="typeOfClaims.terminalIllness"
							:label="$t('checkbox.terminalIllness')"
							automation-id="terminal-illness"
							@input="updateTerminalIllness"
						/>
					</template>
					<!-- This is the validation input for the checkboxes used to display an error, and know if the field is valid to be able to navigate to the next stage. -->
					<BFormGroup class="m-0">
						<ValidationProvider rules="required">
							<input v-model="atLeastOneChecked" type="hidden" />
							<div v-if="showError" class="d-block error-message">
								{{ $t('error.atLeastOneChecked') }}
							</div>
						</ValidationProvider>
					</BFormGroup>
				</BaseCard>
			</template>
			<!-- Once the member has selected a checkbox show the next stage where we go into more detail about the claim and what they will need. -->
			<template v-if="showCheckList">
				<DCICheckListCard
					v-if="isDci"
					:type-of-claims="typeOfClaims"
					automation-id="beforeStartDCI"
				/>
				<LADDCheckListCard v-else :type-of-claims="typeOfClaims" automation-id="beforeStartLADD" />
			</template>

			<WizardButtonBar
				:show-back-button="true"
				:next-button-label="$t('label.next')"
				:disable-next="invalid"
				:auto-back="false"
				@navigate-back="navigateBack()"
				@navigate-next="navigateNext()"
			/>
		</BaseForm>
		<BaseModal
			automation-id="before-start-base-modal"
			:close-button="false"
			cant-close-on-esc
			no-hide-on-blur
			show-exclamation
			:modal-show="showModal"
			:modal-title="$t('modal.title')"
		>
			<template #contentBody>
				<p>{{ $t('modal.text1') }}</p>
				<p>
					{{ $t('modal.text2') }} <span class="text-nowrap">{{ $t('modal.text3') }}</span>
				</p>
			</template>
			<template #modal-footer>
				<BaseButton
					automation-id="modal-button-1"
					variant="primary"
					class="mt-2"
					:label="$t('modal.button1')"
					@click="goToClaimTypePage()"
				/>
				<BaseButton
					automation-id="modal-button-2"
					variant="link"
					class="mt-2 text-left"
					:label="$t('modal.button2')"
					:icon="['fal', 'angle-right']"
					@click="closeModal()"
				/>
			</template>
		</BaseModal>
	</SubmitClaimTemplate>
</template>

<script>
// pages
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
// components
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseForm from '@/components/common/base/BaseForm';
import BaseModal from '@/components/common/base/BaseModal.vue';
import DCICheckListCard from '@/components/submit-claim/before-start/DCICheckListCard.vue';
import LADDCheckListCard from '@/components/submit-claim/before-start/LADDCheckListCard.vue';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import YesNoToggle from '@/components/common/YesNoToggle';
import BaseCheckBox from '@/components/common/base/BaseCheckBox';
import { BFormGroup, BCol, BRow } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate';
import Page from '@/models/claims/Page';

// constants
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import { DCI } from '@/constants/ClaimTypes.js';

export default {
	name: 'BeforeStartDCILADDClaim',
	components: {
		SubmitClaimTemplate,
		WizardButtonBar,
		DCICheckListCard,
		LADDCheckListCard,
		BaseCheckBox,
		YesNoToggle,
		BaseCard,
		BaseForm,
		BaseModal,
		BaseButton,
		BFormGroup,
		ValidationProvider,
		BCol,
		BRow
	},
	data() {
		return {
			pageName: 'beforeStart',
			claim: {},
			caseManager: null,
			claimType: null,
			state: 'case-manager',
			showCaseManager: true,
			showCheckBoxes: false,
			showCheckList: false,
			showTerminalIllnessText: false,
			typeOfClaims: {
				life: null,
				accidentalDismemberment: null,
				terminalIllness: null,
				shortTermDisability: null,
				LongTermDisability: null,
				criticalIllness: null
			},
			showModal: false,
			firstTimeLoading: true,
			showError: false,
			ignoreModal: false,
			percentComplete: 14
		};
	},
	computed: {
		// This computed prop is used to determine that at least one of the checkboxes has been selected.
		// If none are selected return null. This causes the form to be invalid and prevent the member from navigating to the next stage.
		atLeastOneChecked() {
			if (
				this.typeOfClaims.life ||
				this.typeOfClaims.accidentalDismemberment ||
				this.typeOfClaims.terminalIllness ||
				this.typeOfClaims.shortTermDisability ||
				this.typeOfClaims.LongTermDisability ||
				this.typeOfClaims.criticalIllness
			) {
				return true;
			} else {
				return null;
			}
		},
		isDci() {
			return this.claimType === DCI;
		}
	},
	async created() {
		await this.getOrCreatePage();

		this.typeOfClaims.life = this.claim?.hasLADDAccidentalDeath;
		this.typeOfClaims.accidentalDismemberment = this.claim?.hasLADDAccidentalDismemberment;
		this.typeOfClaims.terminalIllness = this.claim?.hasLADDTerminalIllness;
		this.typeOfClaims.shortTermDisability = this.claim?.hasDCIShortTermDisability;
		this.typeOfClaims.LongTermDisability = this.claim?.hasDCILongTermDisability;
		this.typeOfClaims.criticalIllness = this.claim?.hasDCICriticalIllness;

		// If claim type exists set it, if not use claim type from params
		this.claim?.claimType
			? (this.claimType = this.claim.claimType)
			: (this.claimType = this.$route.params.claimType);
		// If case manager exists use it.

		if (this.claim?.caseManager !== null) {
			this.caseManager = this.claim.caseManager;
			if (this.caseManager) {
				this.ignoreModal = true;
			}
		}

		if (this.atLeastOneChecked) {
			this.showCaseManager = false;
			this.showCheckList = true;
			this.state = 'checklist';
		}
	},
	methods: {
		/**
		 * This method is used to navigate between the stages and to the next screen.
		 * It will update flags to determine what is show on the screen based on what stage they are at.
		 */
		async navigateNext() {
			if (this.caseManager && this.ignoreModal === false) {
				this.showModal = true;
				this.$store.dispatch('isOkToLeaveClaimSubmission', true);
				return;
			} else if (this.state === 'case-manager') {
				this.showCheckBoxes = !this.showCheckBoxes;
				this.showCaseManager = !this.showCaseManager;
				this.state = 'checkboxes';
			} else if (this.state === 'checkboxes') {
				this.showCheckBoxes = !this.showCheckBoxes;
				this.showCheckList = !this.showCheckList;
				this.state = 'checklist';
			} else {
				await Page.updatePage(this.page, this.claim);
				this.$router.push({ name: SUBMIT_CLAIM.VERIFY_INFORMATION });
			}
		},
		/***
		 * This method is used to navigate back between stages and to the previous screen.
		 * It will update flags to determine what is show on the screen based on what stage they are at.
		 */
		navigateBack() {
			if (this.state === 'case-manager') {
				Page.deleteById(this.pageName);
				this.$router.back();
			} else if (this.state === 'checkboxes') {
				this.showCheckBoxes = !this.showCheckBoxes;
				this.ignoreModal = false;
				this.showCaseManager = !this.showCaseManager;
				this.clearAllCheckboxValues();
				this.showError = false;
				this.state = 'case-manager';
			} else if (this.state === 'checklist') {
				this.showCheckBoxes = !this.showCheckBoxes;
				this.showCheckList = !this.showCheckList;
				this.state = 'checkboxes';
			}
		},
		// these methods are used to update the checkbox flags, and then call the method to make sure at least one has been checked.
		// If none are checked then it will display an error and prevent the user from navigating to the next screen.
		updateLife(value) {
			this.typeOfClaims.life = value;
			this.claim.hasLADDAccidentalDeath = value;
			this.checkErrorAtLeastOneValue();
		},
		updateAccidentalDismemberment(value) {
			this.typeOfClaims.accidentalDismemberment = value;
			this.claim.hasLADDAccidentalDismemberment = value;
			this.checkErrorAtLeastOneValue();
		},
		updateTerminalIllness(value) {
			this.typeOfClaims.terminalIllness = value;
			this.claim.hasLADDTerminalIllness = value;
			this.checkErrorAtLeastOneValue();
		},
		updateShortTermDisability(value) {
			this.typeOfClaims.shortTermDisability = value;
			this.claim.hasDCIShortTermDisability = value;
			this.checkErrorAtLeastOneValue();
		},
		updateLongTermDisability(value) {
			this.typeOfClaims.LongTermDisability = value;
			this.claim.hasDCILongTermDisability = value;
			this.checkErrorAtLeastOneValue();
		},
		updateCriticalIllness(value) {
			this.typeOfClaims.criticalIllness = value;
			this.claim.hasDCICriticalIllness = value;
			this.checkErrorAtLeastOneValue();
		},
		clearAllCheckboxValues() {
			this.updateLife(null);
			this.updateAccidentalDismemberment(null);
			this.updateTerminalIllness(null);
			this.updateShortTermDisability(null);
			this.updateLongTermDisability(null);
			this.updateCriticalIllness(null);
		},
		checkErrorAtLeastOneValue() {
			if (this.atLeastOneChecked) {
				this.showError = false;
			} else {
				this.showError = true;
			}
			return this.showError;
		},
		updateCaseManager(value) {
			this.caseManager = value;
			this.claim.caseManager = value;
		},
		goToClaimTypePage() {
			this.$router.push({ name: SUBMIT_CLAIM.CLAIM_TYPE });
		},
		// Close the modal and move the member to the next stage.
		closeModal() {
			this.showModal = false;
			this.ignoreModal = true;
			this.navigateNext();
		},
		/**
		 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
		 * If no page exists it will create a new one.
		 */
		async getOrCreatePage() {
			this.page = await Page.getPageById(this.pageName);
			if (!this.page && this.page === null) {
				// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
				this.claim = structuredClone(await Page.getClaimFromLastPage());
				this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
			} else {
				this.claim = this.page?.claims[0];
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.label {
	font-family: $josefin-font-family;
}
.icon {
	font-size: 24px;
	color: $orange-dark;
}
.terminal-illness-title {
	font-family: $josefin-font-family;
	font-weight: $font-regular;
	font-size: 18px;
	margin-left: 0.8em;
}
.terminal-illness-text {
	margin-left: 2.5em;
	font-size: 16px;
}
.case-manager-toggle {
	margin-bottom: 0;
}
.checkbox ::v-deep(.custom-control-label) {
	font-family: $josefin-font-family;
	font-weight: $font-regular;
	font-size: $body-regular-button-font-size;
	padding-left: 1em;
}
.checkbox ::v-deep(.custom-control-label::before) {
	transform: scale(1.3);
}
.checkbox ::v-deep(.custom-control-input:checked ~ .custom-control-label::after) {
	transform: scale(1.5);
}
.error-message {
	text-align: left;
	font-size: 14px;
	color: #ad0900;
	line-height: 17px;
	font-weight: 400;
	background-color: #fff6f6;
	border-color: #ad0900;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: 10px;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "step": "Before you start...",
	  "question": "What type of claim are you submitting?"
    },
    "label": {
	  "next": "Next",
	  "caseManager": "Do you already have a case manager for this claim?"
    },
	"checkbox": {
		"life": "Life Insurance or Accidental Death",
		"accidentDismemberment": "Accidental Dismemberment",
		"terminalIllness": "Terminal Illness",
		"shortTermDisability": "Short-Term Disability or Salary Continuance",
		"longTermDisability": "Long Term Disability",
		"criticalIllness": "Critical Illness"
	},
	"terminalIllness": {
		"title": "Terminal Illness",
		"text": "Please do not continue on this page. You can submit Terminal Illness claims under the Life and Accidental Death & Dismemberment category."
	},
	"modal": {
		"title": "Please contact your case manager to add more documents",
		"text1": "If you already have an ongoing claim with a case manager, please do not use this form. You can reach out to your case manager directly to provide additional documents or information.",
		"text2": "If you need assistance, please call",
		"text3": "1-877-849-8509.",
		"button1": "OK, go to homepage",
		"button2": "Submit a new claim"
	},
	"error": {
		"caseManager": "Please select an option to continue.",
		"atLeastOneChecked": "Please select at least one claim type."
	}
  },
  "fr": {
    "title": {
      "step": "Avant de commencer...",
	  "question": "Quel type de demande de règlement soumettez-vous?"
    },
    "label": {
	  "next": "Suivant",
	  "caseManager": "Votre demande de prestations a-t-elle déjà été attitrée à un(e) spécialiste en gestion de l'invalidité?"
    },
	"checkbox": {
		"life": "Assurance vie ou assurance décès par accident",
		"accidentDismemberment": "Assurance mutilation par accident",
		"terminalIllness": "Maladie en phase terminale",
		"shortTermDisability": "Invalidité de courte durée ou maintien du salaire",
		"longTermDisability": "Invalidité de longue durée",
		"criticalIllness": "Maladies graves"
	},
	"terminalIllness": {
		"title": "Maladie en phase terminale",
		"text": "Ne continuez pas sur cette page. Vous pouvez soumettre les demandes de prestations pour maladie en phase terminale dans la catégorie Assurance vie et décès et mutilation par accident."
	},
	"modal": {
		"title": "Communiquez avec votre spécialiste en gestion de l'invalidité pour ajouter d'autres documents",
		"text1": "Si votre dossier est déjà pris en charge par un(e) spécialiste en gestion de l'invalidité, n'utilisez pas ce formulaire. Vous pouvez communiquer directement avec votre spécialiste en gestion de l'invalidité pour lui fournir les documents ou renseignements supplémentaires.",
		"text2": "Si vous avez besoin d'aide, téléphonez au",
		"text3": "1-877-849-8509.",
		"button1": "OK, retourner à la page d'accueil",
		"button2": "Soumettre une nouvelle demande de prestations"
	},
	"error": {
		"caseManager": "Veuillez sélectionner une option pour continuer.",
		"atLeastOneChecked": "Veuillez sélectionner au moins un type de demande de règlement."
	}
  }
}
</i18n>
