<template>
	<BaseForm
		ref="health-service-card-form"
		:data-tracking-id="getAutomationId('health-service-card-form')"
		:automation-id="getAutomationId('health-service-card-form')"
		@submit="saveService"
	>
		<BRow>
			<BCol cols="12" sm="11" md="11" lg="11" xl="11">
				<BRow>
					<BCol cols="12">
						<BaseDatePicker
							ref="toDate"
							datepicker-id="claim-date-id"
							class="datepicker input-margin"
							:value="DrugDentalService.dateOfService"
							:label="$t('label.claimDate')"
							:placeholder="$t('placeholder')"
							:start-date="false"
							:required="true"
							:max-error-date="getMaxDate()"
							:max-selectable-date="getMaxDate()"
							:required-error-msg="$t('error.requiredClaimDate')"
							:before-max-date-error-msg="$t('error.future')"
							pill
							is-left
							:automation-id="getAutomationId('provider-fields')"
							@input="updateClaimDate"
						/>
					</BCol>
				</BRow>
				<BRow>
					<BCol cols="12">
						<div v-if="!onlyParticipant">
							<label
								:class="[
									'participant-label',
									!DrugDentalService.dateOfService || participantDisabled ? 'disabled' : ''
								]"
								for="participant-dropdown"
								>{{ $t('label.participant') }}</label
							><br />

							<ParticipantDropdown
								id="participant-dropdown"
								:value="DrugDentalService.participant"
								class="participant-dropdown input-margin"
								:placeholder="$t('placeholder')"
								:validation-rules="{ required: true }"
								:validation-messages="{
									required: $t('error.required')
								}"
								:show-placeholder="showPlaceholder || participantDisabled"
								:show-icon="showIcon"
								:show-dob="!showPlaceholder"
								:participants="activeParticipants"
								:title-text="$t('placeholder')"
								:selected-participant="DrugDentalService.participant"
								:automation-id="getAutomationId('provider-fields')"
								@blur="checkParticipantValue"
								@click="updateParticipant"
							/>
							<ValidationProvider
								v-if="showParticipantErrorComputed"
								id="service-exists-validation"
								rules="required"
							>
								<div id="service-exists-error" class="d-block error-message">
									{{ $t('error.required') }}
								</div>
							</ValidationProvider>
						</div>
						<div v-else>
							<label class="participant-label" for="participant">{{
								$t('label.participant')
							}}</label>
							<p id="participant" class="participant-name">
								{{ onlyParticipant.firstName }} {{ onlyParticipant.lastName }}
							</p>
						</div>
					</BCol>
				</BRow>
				<BRow>
					<BaseButton
						:label="$t('button.save')"
						:aria-label="$t('button.save')"
						class="button button-save p-0"
						variant="link"
						:automation-id="getAutomationId('service-card-save')"
						type="submit"
					/>
					<BaseButton
						:label="$t('button.cancel')"
						:aria-label="$t('button.cancel')"
						class="button p-0"
						variant="link"
						:automation-id="getAutomationId('service-card-cancel')"
						@click="cancelService"
					/>
				</BRow>
			</BCol>
		</BRow>
	</BaseForm>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Mixins
import IdMixin from '@/mixins/id';
// components
import { BRow, BCol } from 'bootstrap-vue';
import ParticipantDropdown from '@/components/submit-claim/provider-type/ClaimParticipantDropdown.vue';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseForm from '@/components/common/base/BaseForm';
import BaseButton from '@/components/common/base/BaseButton';
import { ValidationProvider } from 'vee-validate';

//utils
import { format } from 'date-fns';

// @vue/component
@Component({
	name: 'DrugDentalServiceFields',
	components: {
		BRow,
		BCol,
		BaseDatePicker,
		ParticipantDropdown,
		BaseForm,
		BaseButton,
		ValidationProvider
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: null
		},
		members: {
			type: Array,
			default: null
		}
	}
})
export default class DrugDentalServiceFields extends Vue {
	participantDisabled = true;
	showParticipantError = false;
	DrugDentalService = {
		dateOfService: null,
		participant: null
	};
	activeParticipants = [];

	created() {
		if (this.value !== null) {
			this.DrugDentalService.participant = this.members.find(
				(participant) => participant.participantId === this.value.participant
			);
			this.DrugDentalService.dateOfService = format(
				new Date(
					this.value.dateOfService.substr(0, 4),
					this.value.dateOfService.substr(4, 2) - 1,
					this.value.dateOfService.substr(6, 2)
				),
				'yyyy-MM-dd'
			);
			this.updateActiveParticipantList(this.DrugDentalService.dateOfService);
		}
	}

	/**
	 * If the claim date is not set then make the participant dropdown show the placeholder.
	 */
	get showPlaceholder() {
		return this.DrugDentalService.dateOfService && this.members ? false : true;
	}

	get onlyParticipant() {
		return this.members?.length === 1 ? this.members[0] : null;
	}

	/**
	 * Only show the icon if the participant dropdown has a value selected.
	 */
	get showIcon() {
		return this.DrugDentalService?.participant ? true : false;
	}

	get showParticipantErrorComputed() {
		return this.showParticipantError;
	}

	set showParticipantErrorComputed(value) {
		this.showParticipantError = value;
	}

	updateClaimDate(claimDate) {
		if (!this.onlyParticipant && this.DrugDentalService.dateOfService !== claimDate) {
			this.DrugDentalService.dateOfService = claimDate;
			this.DrugDentalService.participant = null;
			this.updateActiveParticipantList(claimDate);
		}
		// If the date is valid or empty then emit it. so the parent component can update the screen.
		if (this.isValidDate(claimDate)) {
			this.participantDisabled = false;
		} else {
			this.participantDisabled = true;
		}
		this.DrugDentalService.dateOfService = claimDate;
	}

	updateParticipant(value) {
		this.DrugDentalService.participant = value.participant;
	}

	saveService() {
		// If there is only one participant use that participants ID.
		if (this.onlyParticipant) {
			this.DrugDentalService.participant = this.members[0].participantId;
		} else {
			this.DrugDentalService.participant = this.DrugDentalService?.participant?.participantId;
		}
		this.$emit('save', this.DrugDentalService);
	}

	cancelService() {
		this.$emit('cancel');
	}

	/**
	 * This method will loop through the participant list, and only display participants with active coverage during the time of the claim date.
	 */
	updateActiveParticipantList(claimDate) {
		this.activeParticipants = [];
		if (claimDate) {
			for (const index in this.members) {
				// Format the date responses for the code to format the javascript date correctly.
				let formattedTermDate = `${this.members[index]?.terminationDate.slice(0, 4)}/${this.members[
					index
				]?.terminationDate.slice(4, 6)}/${this.members[index]?.terminationDate.slice(6)}`;
				let formattedClaimDate = claimDate.split('-').join('/');
				// Only get the active participants.
				if (this.members[index].active) {
					this.activeParticipants.push(this.members[index]);

					// If the participant is not active then check to see if the date of service is before the termination date.
				} else if (new Date(formattedTermDate) >= new Date(formattedClaimDate)) {
					{
						this.activeParticipants.push(this.members[index]);
					}
				}
			}
		}
	}

	checkParticipantValue() {
		if (!this.DrugDentalService.participant) {
			this.showParticipantErrorComputed = true;
		} else {
			this.showParticipantErrorComputed = false;
		}
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}
	isValidDate(selectedDate) {
		return (
			!isNaN(new Date(selectedDate)) &&
			selectedDate.length === 10 &&
			selectedDate <= this.getMaxDate()
		);
	}
}
</script>

<style lang="scss" scoped>
.provider-choice-card {
	width: 100%;
}
.bottom-explanation {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
}
.date-suffix {
	font-size: 17px;
	font-family: $font-family-regular;
	font-weight: 300;
}
.datepicker ::v-deep(input),
.datepicker ::v-deep(.btn) {
	height: 42px;
}
.currency-input ::v-deep(.form-control) {
	height: 42px;
}
.datepicker ::v-deep(.form-control::placeholder) {
	letter-spacing: 0px;
}
.provider-styles ::v-deep select.form-control {
	height: 42px;
}

.input ::v-deep select.form-control {
	height: 42px;
}

.input-margin {
	margin-bottom: 1em;
}

.additional-service-card ::v-deep .validationGroup {
	margin-bottom: 0;
}
.participant-label {
	font-family: $font-family-headline;
	font-size: 18px;
}
.participant-name {
	font-family: $font-family-regular;
	font-size: 15px;
}
.disabled {
	opacity: 0.25;
}
.button {
	font-family: $lato-font-family;
	font-weight: 400;
	text-decoration: underline;
}
.button-save {
	margin-left: 15px;
	margin-right: 15px;
}
.error-message {
	text-align: left;
	font-size: 14px;
	color: #ad0900;
	line-height: 17px;
	font-weight: 400;
	background-color: #fff6f6;
	border-color: #ad0900;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: -20px;
	margin-bottom: 1.5rem;
}
</style>

<i18n>
{
  "en": {
    "button": {
		"save": "Save",
		"cancel": "Cancel"
	},
    "label": {
	  "participant": "Who is this claim for?",
	  "claimDate": "Date of service"
    },
    "placeholder": "Please select…",
    "error": {
	  "requiredClaimDate": "Please select a date.",
	  "future": "The date you've entered is in the future. Please check the date and try again.",
	  "required": "Please choose a plan member."
    }
  },
  "fr": {
    "button": {
		"save": "Enregistrer",
		"cancel": "Annuler"
	},
    "label": {
	  "participant": "Pour qui est cette demande ?",
	  "claimDate": "Date de service"
    },
    "placeholder": "Veuillez sélectionner…",
    "error": {
	  "requiredClaimDate": "Veuillez selectionner une date.",
	  "future": "La date que vous avez entrée est ultérieure à la date d'aujourd'hui. Veuillez corriger la date et essayer à nouveau.",
	  "required": "Veuillez sélectionnez une personne assurée."
    }
  }
}
</i18n>
