<template>
	<BCard>
		<CheckListItem>
			{{ $t('check.claimForm') }}
			<template #description>{{ $t('description.claimForm') }}</template>
		</CheckListItem>
		<CheckListItem>
			{{ $t('check.documents') }}
			<template #description>{{ $t('description.documents') }}</template>
		</CheckListItem>

		<BaseButton
			:id="getUniqueId('travelLink')"
			:pill="false"
			variant="link"
			class="btn-form mt-0 p-1"
			type="button"
			data-tracking-id="eclaimBeforeStartTravelLink"
			:automation-id="getAutomationId('travelLink')"
			:label="$t('link.title')"
			:icon="['fal', 'external-link-alt']"
			icon-position="left"
			@click="openTravelLink()"
		/>
	</BCard>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import CheckListItem from '@/components/common/CheckListItem';
import BaseButton from '@/components/common/base/BaseButton.vue';

export default {
	name: 'TravelCheckListCard',
	components: {
		BCard,
		BaseButton,
		CheckListItem
	},
	mixins: [IdMixin],

	methods: {
		openTravelLink() {
			const securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
			const locale = this.$store.state.i18n.locale;

			if (securityPolicy.brand === 'SBC') {
				window.open('https://www.sk.bluecross.ca/travel-assistance/');
			} else {
				if (locale === 'en') {
					window.open('https://canassistance.com/en/policyholder/claims');
				} else {
					window.open('https://canassistance.com/assures/reclamations');
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.card-body {
	width: 90%;
}
.btn-form {
	margin-left: 1.2em;
	font-family: $headings-font-family;
	font-size: 17px;
	color: $blue;
	& ::v-deep(.button-label) {
		margin-left: 0.3em;
	}
}
</style>

<i18n>
{
  "en": {
    "check": {
      "claimForm": "Do you have your completed travel claim form?",
      "documents": "Do you have any other required documents you might need?"
    },
    "description": {
      "claimForm": "Because travel claims can be complex, you'll need to complete a separate claim form, which you can save or print and then submit here. You can find the forms you need on our website using the button below.",
      "documents": "You'll also need to send us photos or scans of relevant things like itemized bills, detailed pharmacy receipts, proof of payment, medical reports, and so on."
    },
    "link": {
      "title": "Find forms and more information"
    }
  },
  "fr": {
    "check": {
      "claimForm": "Avez-vous un formulaire de demande de règlement d'assurance voyage dûment rempli?",
      "documents": "Avez-vous les autres documents requis?"
    },
    "description": {
      "claimForm": "Comme les demandes de règlement d'assurance voyage peuvent être complexes, vous devez remplir un formulaire de demande de règlement distinct, que vous pouvez sauvegarder ou imprimer, puis soumettre ici. Vous pouvez trouver les formulaires dont vous avez besoin sur notre site Web en cliquant sur le bouton ci-dessous.",
      "documents": "Vous devrez également nous envoyer des photos ou des copies numérisées de documents pertinents, comme des factures détaillées, des reçus de pharmacie détaillés, des preuves de paiement, des rapports médicaux, etc."
    },
    "link": {
      "title": "Pour obtenir les formulaires et des renseignements"
    }
  }
}
</i18n>
