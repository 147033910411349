<template>
	<BCard>
		<CheckListItem>
			{{ $t('check.receipt') }}
			<template #description>
				<section class="mt-0">
					<p class="mb-0">{{ $t('description.receipt') }}</p>
					<ul>
						<li>{{ $t('description.item') }}</li>
						<li>{{ $t('description.date') }}</li>
						<li>{{ $t('description.where') }}</li>
						<li>{{ $t('description.paid') }}</li>
					</ul>
				</section>
			</template>
		</CheckListItem>
		<CheckListItem class="mb-2">
			{{ $t('check.eligible') }}
		</CheckListItem>

		<BLink class="link" automation-id="PWACheckCoverageLink" :to="{ name: coverageLink }">
			{{ $t('link.title') }}
		</BLink>
	</BCard>
</template>

<script>
import { COVERAGE } from '@/constants/Routes.js';
import { BCard, BLink } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import CheckListItem from '@/components/common/CheckListItem';

export default {
	name: 'PWACheckListCard',
	components: {
		BCard,
		BLink,
		CheckListItem
	},
	mixins: [IdMixin],
	computed: {
		coverageLink() {
			return COVERAGE.PWA;
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .display-group {
	margin-bottom: 0;
}
.label {
	margin-bottom: 0;
}

.link {
	margin-left: 26px;
	font-weight: 400;
	color: $blue;
}
</style>

<i18n>
{
  "en": {
    "check": {
      "receipt": "Do you have your itemized receipt handy?",
      "eligible": "Is your claim for an eligible benefit?"

    },
    "description": {
      "receipt": "Your itemized receipt should show:",
      "item": "The item or service you purchased",
      "date": "The date of purchase",
      "where": "From where you purchased it",
      "paid": "That it was paid in full"
    },
    "link": {
      "title": "Check to see what's covered"
    }
  },
  "fr": {
    "check": {
      "receipt": "Avez-vous votre reçu détaillé en main?",
      "eligible": "Votre demande de règlement porte-t-elle sur une garantie admissible? "
    },
    "description": {
      "receipt": "Votre reçu détaillé doit comprendre les renseignements suivants :",
      "item": "L’article ou le service acheté;",
      "date": "La date de l’achat;",
      "where": "L’endroit où l’article ou le service a été acheté;",
      "paid": "Une confirmation que l’achat a été payé en totalité."
    },
    "link": {
      "title": "Vous pouvez vérifier la couverture ici."
    }
  }
}
</i18n>
