import axios from 'axios';

export default class PwaClaims {
	/**
	 * Check if member has PWA funds.  True means they have a balance and false they don't.
	 */
	static async hasPwaFunds(username, token, locale) {
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/health-spending-products/balances?type=pwa`;

			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response.data;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
}
