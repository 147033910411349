<template>
	<div>
		<div v-if="availableClaimTypes && Object.keys(availableClaimTypes).length > 0">
			<h1 class="mt-4">{{ $t('title') }}</h1>
			<h2 class="h3 mb-3">{{ $t('subTitle') }}</h2>
			<ActionButtonCardContainer>
				<BRow class="card-container-row" cols="1">
					<ActionButtonCard
						v-if="hasPractitioner"
						:title="$t('button.practitioner')"
						:description="$t('description.practitioner')"
						:line-break="false"
						:icon="['fal', 'users-medical']"
						:automation-id="getAutomationId('practitioner')"
						@click="toPractitioner"
					/>
					<ActionButtonCard
						v-if="hasVision"
						:title="$t('button.vision')"
						:line-break="false"
						:icon="['fal', 'glasses']"
						:automation-id="getAutomationId('vision')"
						@click="toVision"
					/>
					<ActionButtonCard
						v-if="hasHealth"
						:title="$t('button.health')"
						:description="$t('description.health')"
						:line-break="false"
						:icon="['fal', 'heartbeat']"
						:automation-id="getAutomationId('health-general')"
						@click="toHealthGeneral"
					/>
					<ActionButtonCard
						v-if="hasDental"
						:title="$t('button.dentalService')"
						:line-break="false"
						:icon="['fal', 'tooth']"
						:automation-id="getAutomationId('dental-service')"
						@click="toDental"
					/>
					<ActionButtonCard
						v-if="hasDrugs"
						:title="$t('button.drugs')"
						:line-break="false"
						:icon="['fal', 'capsules']"
						:automation-id="getAutomationId('drugs')"
						@click="toDrugs"
					/>
					<ActionButtonCard
						v-if="hasHSA"
						:title="$t('button.hsa')"
						:short-hand="$t('button.shortHsa')"
						:description="$t('description.hsa')"
						:line-break="false"
						:icon="['fal', 'file-invoice-dollar']"
						:automation-id="getAutomationId('hsa')"
						@click="toHSA"
					/>
					<ActionButtonCard
						v-if="hasPWA"
						:title="$t('button.pwa')"
						:short-hand="$t('button.shortPwa')"
						:description="$t('description.pwa')"
						:line-break="false"
						:icon="['fal', 'hand-holding-usd']"
						:automation-id="getAutomationId('pwa')"
						@click="toPWA"
					/>
					<ActionButtonCard
						v-if="hasTravel"
						:title="$t('button.travel')"
						:description="$t('description.travel')"
						:line-break="false"
						:icon="['fal', 'plane-departure']"
						:automation-id="getAutomationId('travel')"
						@click="toTravel"
					/>
					<ActionButtonCard
						v-if="hasDCI"
						:title="$t('button.disCrit')"
						:line-break="false"
						:icon="['fal', 'briefcase-medical']"
						:automation-id="getAutomationId('dis-crit')"
						@click="toDCI"
					/>
					<ActionButtonCard
						v-if="hasLADD"
						:title="$t('button.life')"
						:line-break="false"
						:icon="['fal', 'hand-holding-medical']"
						:automation-id="getAutomationId('lif')"
						@click="toLADD"
					/>
				</BRow>
			</ActionButtonCardContainer>
		</div>
		<ErrorModal
			:modal-show="showModal"
			has-close-button
			:error-title="$t('noClaimTypesModal.title')"
			@change="closeModal"
		>
			<BaseButton
				:automation-id="getAutomationId('modal-button')"
				variant="primary"
				:label="$t('noClaimTypesModal.button')"
				@click="closeModal"
			></BaseButton>
		</ErrorModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// components
import ActionButtonCard from '@/components/common/card/ActionButtonCard.vue';
import ActionButtonCardContainer from '@/components/common/card/ActionButtonCardContainer';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton.vue';
import UpdateFormCard from '@/components/common/card/UpdateFormCard';
// constants
import { SUBMIT_CLAIM, DASHBOARD } from '@/constants/Routes.js';
import * as claimtypes from '@/constants/ClaimTypes.js';
// models
import Claims from '@/models/claims/Claims';
import Page from '@/models/claims/Page';
import MemberCardInfo from '@/models/MemberCardInfo.js';
import ClaimTypes from '@/models/claims/ClaimTypes';

@Component({
	name: 'ClaimType',
	components: {
		ActionButtonCard,
		ActionButtonCardContainer,
		BRow,
		BCol,
		ErrorModal,
		UpdateFormCard,
		BaseButton
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class ClaimType extends Vue {
	pageName = 'claimType';
	page = {};
	claim = {};
	availableClaimTypes = {};
	memberCardInfo = {};
	showModal = false;
	securityPolicy = false;

	async created() {
		this.securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
		if (!this.securityPolicy.submitClaim) this.$router.push({ name: DASHBOARD });

		this.$store.dispatch('updateLoading', true);

		const email = sessionStorage.getItem('email');
		const token = sessionStorage.getItem('apiToken');
		const locale = this.$store.state.i18n.locale;

		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.submitClaim', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.submitClaim', 'fr') }
			]
		);

		this.availableClaimTypes = await ClaimTypes.loadClaimType(email, token, locale);

		if (
			!this.availableClaimTypes ||
			this.availableClaimTypes === undefined ||
			this.availableClaimTypes.length === 0
		) {
			this.showModal = true;
		}
		this.memberCardInfo = await MemberCardInfo.getMemberDetail(email, token, locale);
		Page.clearPages();
		this.claim = await Claims.createNewClaim(sessionStorage.getItem('email'));
		await this.getOrCreatePage();

		this.$store.dispatch('submitClaimCOB', {});
		this.$store.dispatch('submitClaimAdditionalDocuments', null);
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * check to see if the user can submit Practitioner related claims
	 */
	get hasPractitioner() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.PRACTITIONER;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Health related claims
	 */
	get hasVision() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.VISION;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Health related claims
	 */
	get hasHealth() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.HEALTH;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Dental related claims
	 */
	get hasDental() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.DENTAL;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Drugs related claims
	 */
	get hasDrugs() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.DRUGS;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Travel related claims
	 */
	get hasTravel() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.TRAVEL;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit PWA related claims
	 */
	get hasPWA() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.PWA;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit HSA related claims
	 */
	get hasHSA() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.HSA;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Disability and Critical Illness related claims
	 */
	get hasDCI() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.DCI;
			  })
			: false;
	}

	/**
	 * check to see if the user can submit Life and Accidental Death & Dismemberment related claims
	 */
	get hasLADD() {
		return this.availableClaimTypes && this.availableClaimTypes.length > 0
			? this.availableClaimTypes?.some((type) => {
					return type.claimType === claimtypes.LADD;
			  })
			: false;
	}

	/**
	 * check if the selected claim type can RTA
	 */
	canRta(claimType) {
		return this.availableClaimTypes?.some((type) => {
			if (type.claimType === claimType) return type.isRta;
		});
	}

	/**
	 * retrieve lob of a claim type
	 */
	getLob(claimType) {
		return this.availableClaimTypes?.find((type) => type.claimType === claimType).lob;
	}

	/**
	 * Returns if a claim type is disabled for a Province or not
	 */
	isDisabledForProv(claimType) {
		return this.availableClaimTypes?.some((type) => {
			if (type.claimType === claimType) return type.isDisabledForProv;
		});
	}

	toPractitioner() {
		this.claim.claimType = claimtypes.PRACTITIONER;
		const canRtaValue = this.canRta(claimtypes.PRACTITIONER);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.PRACTITIONER);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_GENERIC });
	}

	toVision() {
		this.claim.claimType = claimtypes.VISION;
		const canRtaValue = this.canRta(claimtypes.VISION);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.VISION);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_GENERIC });
	}

	toHealthGeneral() {
		this.claim.claimType = claimtypes.HEALTH;
		const canRtaValue = this.canRta(claimtypes.HEALTH);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.HEALTH);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_GENERIC });
	}

	toDental() {
		this.claim.claimType = claimtypes.DENTAL;
		const canRtaValue = this.canRta(claimtypes.DENTAL);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.DENTAL);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_DENTAL });
	}

	toDrugs() {
		this.claim.claimType = claimtypes.DRUGS;
		const canRtaValue = this.canRta(claimtypes.DRUGS);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.DRUGS);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_DRUGS });
	}

	toTravel() {
		//Members of provinces who have travel disabled need to mail in travel forms.
		if (this.isDisabledForProv(claimtypes.TRAVEL)) {
			this.$router.push({ name: SUBMIT_CLAIM.TRAVEL_CAN_NOT_PROCESS });
			return;
		}

		this.claim.claimType = claimtypes.TRAVEL;
		const canRtaValue = this.canRta(claimtypes.TRAVEL);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.TRAVEL);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_TRAVEL });
	}

	toPWA() {
		this.claim.claimType = claimtypes.PWA;
		const canRtaValue = this.canRta(claimtypes.PWA);
		this.claim.isRta = canRtaValue;
		this.claim.lob = '05';
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_PWA });
	}

	toHSA() {
		this.claim.claimType = claimtypes.HSA;
		const canRtaValue = this.canRta(claimtypes.HSA);
		this.claim.isRta = canRtaValue;
		this.claim.isHsa = true;
		this.claim.lob = this.getLob(claimtypes.HSA);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({ name: SUBMIT_CLAIM.BEFORE_START_HSA });
	}

	toDCI() {
		this.claim.claimType = claimtypes.DCI;
		const canRtaValue = this.canRta(claimtypes.DCI);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.DCI);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({
			name: SUBMIT_CLAIM.BEFORE_START_DCI_LADD,
			params: { claimType: this.claim.claimType }
		});
	}

	toLADD() {
		this.claim.claimType = claimtypes.LADD;
		const canRtaValue = this.canRta(claimtypes.LADD);
		this.claim.isRta = canRtaValue;
		this.claim.lob = this.getLob(claimtypes.LADD);
		Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		this.updatePage();
		this.$router.push({
			name: SUBMIT_CLAIM.BEFORE_START_DCI_LADD,
			params: { claimType: this.claim.claimType }
		});
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
			await Claims.updateClaim(this.claim, sessionStorage.getItem('email'));
		}
	}

	async updatePage() {
		await Page.updatePage(this.page, this.claim);
	}

	closeModal() {
		this.showModal = false;
		this.$router.go(-1);
	}
}
</script>

<style lang="scss" scoped>
.card-container-row {
	@media (max-width: 375px) {
		display: block;
		margin: 0;
		& > div {
			width: 100%;
			max-width: 100%;
			padding: 0;
		}
	}
}
.card-container-row::v-deep(.card-button) {
	text-align: left;
}
.card-container-row ::v-deep(svg) {
	width: 1em;
	font-size: 24px;
}
.card-container-row ::v-deep(.action-card) {
	width: 82%;
	@media (max-width: 992px) {
		width: 100%;
	}
}
.card-container-row ::v-deep(.action-card-text) {
	font-size: 21px;
}
.card-container-row ::v-deep(.icon) {
	margin-bottom: 0;
}
.multiple-claims {
	width: 82%;
	@media (max-width: 992px) {
		width: 100%;
	}
	& ::v-deep(.button-label) {
		margin-left: 0;
	}
}
</style>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "submitClaim": "Submit a Claim"
    },
    "title": "Submit a Claim",
    "subTitle": "What type of claim are you submitting?",
    "button": {
	  "practitioner": "Practitioner Services",
	  "vision": "Vision",
      "health": "Health",
      "dentalService": "Dental Services",
      "drugs": "Drugs",
      "travel": "Travel",
      "pwa": "Personal Wellness Account",
      "hsa": "Health Spending Account",
      "shortPwa": "(PWA)",
      "shortHsa": "(HSA)",
	  "disCrit": "Disability and Critical Illness",
	  "life": "Life and Accidental Death & Dismemberment"
    },
	"description": {
		"practitioner": "Health professionals such as massage therapists, physiotherapists, chiropractors, psychologists and others.",
    	"health": "Health care services such as medical equipment and supplies, nursing care and hospital services.",
    	"travel": "Claims for health care received while travelling outside of the province where you live.",
    	"pwa": "Your PWA covers expenses that contribute to your overall wellbeing. See the Coverage page for details on what you can submit under your PWA.",
    	"hsa": "Any health care expense not covered under your Blue Cross or provincial health plan, as long as it meets the Canada Revenue Agency definition of a medical expense."
	},
	"noClaimTypesModal": {
	  "title": "You currently don't have any coverage that supports online claim submissions.",
	  "button": "OK"
	}
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "submitClaim": "Soumettre une demande"
    },
    "title": "Soumettre une demande",
    "subTitle": "Quel type de demande de règlement soumettez-vous?",
    "button": {
	  "practitioner": "Services paramédicaux",
	  "vision": "Soins de la vue",
      "health": "Santé",
      "dentalService": "Soins dentaires",
      "drugs": "Médicaments",
      "travel": "Assurance voyage",
      "pwa": "Compte Gestion mieux-être",
      "hsa": "Compte Gestion-santé",
      "shortPwa": "(CGM)",
      "shortHsa": "(CGS)",
	  "disCrit": "Invalidité et maladies Graves",
	  "life": "Assurance vie, et décès et mutilation par accident" 
    },
	"description": {
		"practitioner": "Professionnels de la santé, comme les massothérapeutes, les physiothérapeutes, les chiropracteurs, les psychologues, etc.",
    	"health": "Services de soins de santé, comme les fournitures et l'équipement médicaux, les soins infirmiers et les services hospitaliers.",
    	"travel": "Demandes de règlement portant sur des soins reçus lors d'un voyage hors de votre province de résidence.",
    	"pwa": "Votre CGM rembourse les dépenses qui contribuent à votre bien-être général. Consultez la page Couverture pour savoir quelles dépenses vous pouvez soumettre à votre CGM.",
    	"hsa": "Toute dépense de soins de santé qui n'est pas couverte par votre régime Croix Bleue ou votre régime  provincial d'assurance maladie, tant qu'elle répond à la définition des frais médicaux admissibles de l'Agence du revenu du Canada."
	},
	"noClaimTypesModal": {
	  "title": "Vous n'avez actuellement aucune couverture qui permet la soumission des demandes de règlement en ligne.",
	  "button": "OK"
	}
  }
}
</i18n>
