<template>
	<SubmitClaimTemplate :percent-complete="28" :step-title="$t('title.step')" :fluid="false">
		<BaseForm automation-id="ProviderTypeChoiceForm">
			<ProviderTypeChoiceFields
				:value="serviceInfo"
				:provider-type-list="providerTypes"
				:members="participants"
				:only-participant="onlyParticipant"
				:claim-type="claim ? claim.claimType : null"
				:show-other-input="showOtherInput"
				:pwa-services="pwaServices"
				:other-provider-type="otherProviderType"
				@claimDate="updateClaimDate"
				@participant="updateParticipant"
				@providerType="updateProviderType"
				@healthProviderType="updateOtherProviderType"
				@service="updateService"
				@amount="updateAmount"
			/>
			<RecentProviderList
				v-if="filteredRecentProviders && filteredRecentProviders.length > 0"
				:recent-providers="filteredRecentProviders"
				@click="chooseProvider"
				@add="navigateNext()"
			/>
			<br />
			<ErrorModal
				:modal-show="showErrorModal"
				:error-title="errorTitle"
				:error-message="errorMessage"
				no-hide-on-blur
				:has-close-button="false"
			>
				<template #default>
					<BRow>
						<BCol v-if="errorType === 'min'">
							<BaseButton
								:label="$t('error.no')"
								variant="primary"
								class="ml-auto mr-2"
								automation-id="service-type-error-no"
								@click="showErrorModal = false"
							/>
							<BaseButton
								:label="$t('error.yes')"
								class="mr-auto"
								automation-id="service-type-error-yes"
								@click="navigateNextLowAmount()"
							/>
						</BCol>
					</BRow>
					<BRow>
						<BCol v-if="errorType === 'max'">
							<BaseButton
								:label="$t('error.okay')"
								variant="primary"
								automation-id="service-type-error-okay"
								@click="showErrorModal = false"
							/>
						</BCol>
					</BRow>
				</template>
			</ErrorModal>
			<WizardButtonBar
				:show-next-button="showNextButton"
				:disable-next="disableNextButton"
				@navigate-next="navigateNext()"
				@navigate-back="navigateBack()"
			></WizardButtonBar>
		</BaseForm>
	</SubmitClaimTemplate>
</template>

<script>
import Vue from 'vue';
import { nextTick } from 'vue';

import Component from 'vue-class-component';
import { format } from 'date-fns';
// mixins
import IdMixin from '@/mixins/id';
// constants
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import * as Claimtypes from '@/constants/ClaimTypes';
// models
import Page from '@/models/claims/Page';
import ProviderTypeSearch from '@/models/ProviderTypeSearch';
import BenefitService from '@/models/claims/BenefitService';
import ManualClaimServiceItems from '@/models/claims/ManualClaimServiceItems';
import BenefitProvider from '@/models/claims/BenefitProvider';
import Dependent from '@/models/Dependent.js';
import MemberCardInfo from '@/models/MemberCardInfo';
import ServiceTypes from '@/models/claims/ServiceTypes';

// components
import { BRow, BCol } from 'bootstrap-vue';
import BaseForm from '@/components/common/base/BaseForm';
import ProviderTypeChoiceFields from '@/components/claim/ProviderTypeChoiceFields';
import RecentProviderList from '@/components/claim/RecentProviderList';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';

// pages
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';

// @vue/component
@Component({
	name: 'ProviderTypeChoice',
	components: {
		BRow,
		BCol,
		ProviderTypeChoiceFields,
		RecentProviderList,
		SubmitClaimTemplate,
		WizardButtonBar,
		ErrorModal,
		BaseButton,
		BaseForm
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class ProviderTypeChoice extends Vue {
	pageName = 'providerChoice';
	page = {};
	claim = {};
	participants = [];
	providers = null;
	providerTypes = [];
	nextScreen = SUBMIT_CLAIM.PROVIDER_SEARCH;
	serviceInfo = {
		claimDate: null,
		participant: null,
		providerType: null,
		amountPaid: null,
		service: null
	};
	recentProviders = null;
	filteredRecentProviders = null;
	showNextButton = false;
	disableNextButton = false;
	showOtherInput = false;
	otherProviderType = null;
	onlyParticipant = null;
	pwaServices = [];
	services = null;
	showErrorModal = false;
	errorType = null;
	manualClaimService = new ManualClaimServiceItems();

	/**
	 * Get or create a new page to save/load the state of the claim.
	 * Get the members participants to be displayed in the participants dropwon, and then grab the providers to display all the provider types to the member.
	 *
	 */
	async mounted() {
		this.$store.dispatch('updateLoading', true);

		await this.getOrCreatePage();
		await this.getRecentProviders();

		// If the claimtype is health then get the resource urls to display in the provider type dropdown, if not then get list of provider types and recent providers.
		if (this.isHealth || this.isDrugsOrDental) {
			await this.getResourceUrls();
			if (this.claim?.manualClaimServiceItems?.length !== 0) {
				if (this.claim.manualClaimServiceItems && this.claim.manualClaimServiceItems.length > 0) {
					this.serviceInfo.claimDate = format(
						new Date(
							this.claim.manualClaimServiceItems[0].dateOfService.substr(0, 4),
							this.claim.manualClaimServiceItems[0].dateOfService.substr(4, 2) - 1,
							this.claim.manualClaimServiceItems[0].dateOfService.substr(6, 2)
						),
						'yyyy-MM-dd'
					);

					// If more that one participant exists grab a list of participants for the dropdown based on the claim date.
					if (!this.onlyParticipant) {
						await this.getParticipants(this.serviceInfo.claimDate);
					}
					// Only get these if it's health, they don't exist for drug or dental.
					if (this.isHealth) {
						this.serviceInfo.providerType = this.claim.manualClaimServiceItems[0].whatIsClaimFor;
						this.otherProviderType = this.claim.manualClaimServiceItems[0].specify;
						this.showOtherInput = this.otherProviderType ? true : false;
					}
				}
			}
		} else {
			await this.getProviderTypes();
		}

		// Check if a benefitService already exists. If it does then set the provider type to the existing services specialty code.
		if (this.claim?.benefitServices && this.claim.benefitServices.length !== 0) {
			this.serviceInfo.claimDate = format(
				new Date(
					this.claim?.benefitServices[0]?.dateOfService.substr(0, 4),
					this.claim?.benefitServices[0]?.dateOfService.substr(4, 2) - 1,
					this.claim?.benefitServices[0]?.dateOfService.substr(6, 2)
				),
				'yyyy-MM-dd'
			);

			// If more that one participant exists grab a list of participants for the dropdown based on the claim date.
			if (!this.onlyParticipant) {
				await this.getParticipants(this.serviceInfo.claimDate);
			}

			// Set the provider type and filter recent providers based on it.
			this.serviceInfo.providerType = this.claim?.benefitServices[0].specialtyCode;
			if (this.isPWA) {
				this.serviceInfo.service = this.claim.benefitServices[0].descriptionRbKey;
				this.serviceInfo.amountPaid = this.claim.benefitServices[0].amountPaid;
			} else {
				this.filterRecentProviders(this.serviceInfo.providerType);
			}
			await this.checkShowNextButton();
		}

		await this.checkOneParticipant();
		await this.checkShowNextButton();

		this.$store.dispatch('updateLoading', false);
	}

	// -------------------- COMPUTED ---------------------------

	get isVision() {
		return this.claim?.claimType === Claimtypes.VISION;
	}

	get isDrugsOrDental() {
		return (
			this.claim?.claimType === Claimtypes.DRUGS || this.claim?.claimType === Claimtypes.DENTAL
		);
	}

	get isHealth() {
		return this.claim?.claimType === Claimtypes.HEALTH;
	}

	get isPractitioner() {
		return this.claim?.claimType === Claimtypes.PRACTITIONER;
	}

	get isPWA() {
		return this.claim?.claimType === Claimtypes.PWA;
	}

	get errorTitle() {
		return this.errorType === 'min' ? this.$t('error.minTitle') : this.$t('error.maxTitle');
	}

	get errorMessage() {
		return this.errorType === 'min'
			? this.$t('error.minDescription')
			: this.$t('error.maxDescription');
	}
	// -------------------- NAVIGATION ---------------------------

	/**
	 * Delete the page and clear the benefit service before navigating back to the previous screen.
	 */
	async navigateNext() {
		// If the claim type is Vision then set the providter type to vision and change the next screen to treatment for accident/
		if (this.isVision) {
			this.serviceInfo.providerType = this.claim.claimType;
			this.nextScreen = SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT;
		} else if (this.serviceInfo.providerType === 'OTHER') {
			this.nextScreen = SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT;
		}
		if (this.isPWA) {
			const isAmountValid = await this.checkValidPaidAmount();
			if (isAmountValid) {
				await this.savePwaService();
				Page.updatePage(this.page, this.claim);
				this.$router.push({ name: SUBMIT_CLAIM.SERVICE_LIST });
			}
		} else if (this.isHealth || this.isDrugsOrDental) {
			this.saveHealthDrugsOrDentalService();
			Page.updatePage(this.page, this.claim);
			this.$router.push({ name: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT });
		} else {
			await this.saveService();
			Page.updatePage(this.page, this.claim);
			this.$router.push({ name: this.nextScreen });
		}
	}

	/**
	 * Call this method if the member has decided to continue with an amount lower than $1.
	 */
	async navigateNextLowAmount() {
		await this.savePwaService();
		Page.updatePage(this.page, this.claim);
		this.$router.push({ name: SUBMIT_CLAIM.SERVICE_LIST });
	}

	/**
	 * This method is called when the member selects a recent provider. it will save the service and provider selected before navigating to the next screen.
	 */
	async chooseProvider(provider) {
		await this.saveService();
		await this.createFoundBenefitProvider(provider);
		Page.updatePage(this.page, this.claim);
		this.$router.push({ name: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT });
	}

	/**
	 * Delete the page and clear the benefit service before navigating back to the previous screen.
	 */
	async navigateBack() {
		Page.deleteById(this.pageName);
		BenefitService.clearBenefitService();
	}

	// ----------------------- UPDATE/SAVE -------------------------

	/**
	 * Create or update the benefit service. Then save the new claim state to the page Model.
	 */
	async saveService() {
		if (this.claim?.benefitServices == null || this.claim?.benefitServices?.length === 0) {
			var newBenefitService = new BenefitService();
			let benefitService = await BenefitService.createNewBenefitService(
				newBenefitService,
				sessionStorage.getItem('email'),
				null,
				this.serviceInfo.providerType,
				format(
					new Date(
						this.serviceInfo.claimDate.split('-')[0],
						this.serviceInfo.claimDate.split('-')[1] - 1,
						this.serviceInfo.claimDate.split('-')[2]
					),
					'yyyyMMdd'
				),
				0
			);
			this.claim.benefitServices.push(benefitService);
		} else {
			if (this.claim?.benefitServices[0]) {
				this.claim.benefitServices[0].dateOfService = format(
					new Date(
						this.serviceInfo.claimDate.split('-')[0],
						this.serviceInfo.claimDate.split('-')[1] - 1,
						this.serviceInfo.claimDate.split('-')[2]
					),
					'yyyyMMdd'
				);
				this.claim.benefitServices[0].specialtyCode = this.serviceInfo.providerType;
				await BenefitService.updateBenefitServiceById(this.claim.benefitServices[0]);
			}
		}
		this.claim.isRta = this.canRta(this.serviceInfo.providerType);
		this.claim.participantId = this.serviceInfo.participant.participantId;
	}

	/**
	 * This method is used to save the service if the member is on a PWA claim.
	 */
	async savePwaService() {
		for (let i = 0; i < this.services?.length; i++) {
			if (
				this.services[i].descriptionRbKey === this.serviceInfo.service ||
				this.services[i].description === this.serviceInfo.service
			) {
				// If a benefit service already exists from the member navigating back to this screen then remove it.
				if (this.claim?.benefitServices?.length > 0) {
					await BenefitService.removeBenefitServiceById(this.claim.benefitServices[0].id);
					let benefitService = await BenefitService.createNewBenefitService(
						this.services[i],
						sessionStorage.getItem('email'),
						this.serviceInfo.amountPaid,
						this.claim.claimType,
						format(
							new Date(
								this.serviceInfo.claimDate.split('-')[0],
								this.serviceInfo.claimDate.split('-')[1] - 1,
								this.serviceInfo.claimDate.split('-')[2]
							),
							'yyyyMMdd'
						),
						0
					);
					this.claim.benefitServices[0] = benefitService;
				} else {
					if (this.serviceInfo.service.toUpperCase() === 'OTHER') {
						this.services[i].descriptionRbKey = 'Other';
					}

					let benefitService = await BenefitService.createNewBenefitService(
						this.services[i],
						sessionStorage.getItem('email'),
						this.serviceInfo.amountPaid,
						this.claim.claimType,
						format(
							new Date(
								this.serviceInfo.claimDate.split('-')[0],
								this.serviceInfo.claimDate.split('-')[1] - 1,
								this.serviceInfo.claimDate.split('-')[2]
							),
							'yyyyMMdd'
						),
						0
					);
					this.claim?.benefitServices.push(benefitService);
				}
			}
		}
		this.claim.participantId = this.serviceInfo.participant.participantId;
	}
	/**
	 * This method is used to save the service if the member is on a PWA claim.
	 */
	async saveHealthDrugsOrDentalService() {
		this.manualClaimService.participant = this.serviceInfo.participant.participantId;
		this.manualClaimService.dateOfService = format(
			new Date(
				this.serviceInfo.claimDate.split('-')[0],
				this.serviceInfo.claimDate.split('-')[1] - 1,
				this.serviceInfo.claimDate.split('-')[2]
			),
			'yyyyMMdd'
		);
		this.manualClaimService.whatIsClaimFor = this.serviceInfo.providerType;
		this.manualClaimService.specify = this.otherProviderType;
		if (this.claim.manualClaimServiceItems && this.claim.manualClaimServiceItems.length > 0) {
			this.claim.manualClaimServiceItems[0] = this.manualClaimService;
		} else {
			this.claim.manualClaimServiceItems.push(this.manualClaimService);
		}
	}

	async createFoundBenefitProvider(provider) {
		this.claim.isProviderNotFound = false;
		this.claim.benefitProvider = await BenefitProvider.insertBenefitProvider(
			provider,
			sessionStorage.getItem('email')
		);
	}

	// ----------------------- INPUT UPDATES -------------------------

	/**
	 * Update the provider info criteria to save to the service.
	 */
	async updateClaimDate(value) {
		// Clear the next fields
		this.serviceInfo.providerType = null;
		this.serviceInfo.amountPaid = null;
		this.serviceInfo.service = null;
		this.otherProviderType = null;
		this.showOtherInput = false;
		this.filteredRecentProviders = null;
		this.pwaServices = [];

		// If the member updated the claim date then get the participants with the new date of service to know if they were active.
		if (!this.onlyParticipant && this.serviceInfo?.claimDate !== value) {
			this.serviceInfo.participant = null;
			this.claim.participantId = null;
			this.claim.manualClaimServiceItems = [];
			await this.getParticipants(value);
			await nextTick();
		}
		this.serviceInfo.claimDate = value;

		if (this.isPWA) {
			await this.getPwaServices();
		}
		await this.checkShowNextButton();
	}

	/**
	 * Update the provider info criteria to save to the service.
	 */
	async updateParticipant(value) {
		// Clear the next fields
		this.serviceInfo.providerType = null;
		this.serviceInfo.amountPaid = null;
		this.serviceInfo.service = null;
		this.otherProviderType = null;
		this.showOtherInput = false;
		this.filteredRecentProviders = null;

		this.serviceInfo.participant = value;
		await this.checkShowNextButton();
	}

	/**
	 * Update the provider info criteria to save to the service.
	 */
	async updateProviderType(value) {
		// Clear the next field
		this.otherProviderType = null;
		this.filteredRecentProviders = null;

		// Only get recent providers if this is not the health claimtype.
		if (this.isHealth) {
			let selectedHealthProviderType = this.providerTypes?.find(
				(provider) => provider.value === value
			);
			// Update the claim object with the selected provider type.
			this.manualClaimService.whatIsClaimFor = value;
			this.otherProviderType = null;
			// Check if we need to show the other input.
			selectedHealthProviderType?.isOther
				? (this.showOtherInput = true)
				: (this.showOtherInput = false);
		} else {
			// Check if the provider type is set, or has changed. If it has then filter the recent providers to show the ones for the new provider type.
			if (value !== null && this.serviceInfo?.providerType !== value) {
				await this.filterRecentProviders(value);
			} else {
				// If the value is null then empty filteredRecentProviders. This will remove the list in case the member removes the provider type selection.
				this.filteredRecentProviders = null;
			}
		}

		this.serviceInfo.providerType = value;
		await this.checkShowNextButton();
	}

	/** update the Other input field on the health flow when a member selects "Not listed" from the health provider types. */
	async updateOtherProviderType(value) {
		this.otherProviderType = value;
		this.manualClaimService.specify = this.otherProviderType;
		await this.checkShowNextButton();
	}

	async updateService(value) {
		this.serviceInfo.amountPaid = null;
		this.serviceInfo.service = value;
		await this.checkShowNextButton();
	}

	async updateAmount(value) {
		this.serviceInfo.amountPaid = value;
		await this.checkShowNextButton();
	}

	// ----------------------- GET INFORMATION -------------------------

	/**
	 * This method will retrieve the provider types for the provider type dropdown.
	 */
	async getProviderTypes() {
		await ProviderTypeSearch.getProviderTypes(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			?.then((response) => {
				this.providers = response.data;
				for (let i = 0; i < this.providers.length; i++) {
					if (this.providerTypes.findIndex((x) => x.value === this.providers[i].claimType) === -1) {
						this.providerTypes.push({
							value: this.providers[i].claimType,
							text: this.providers[i].description
						});
					}
				}
			})
			.catch(() => {
				this.$store.dispatch('error');
			});
	}

	/**
	 * This method will call the API to see if the member has any recent providers.
	 * If they do then set the recentProvider variable to pass to the RecentProviderList component.
	 */
	async getRecentProviders() {
		await ProviderTypeSearch.getRecentProviders(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			?.then((response) => {
				this.recentProviders = response.data;
			})
			.catch(() => {
				this.$store.dispatch('error');
			});
	}

	/**
	 * Retrieve the participants and use the claim date to know if the member was active during the date of service.
	 * The participants will be displayed in the participant dropdown.
	 */
	async getParticipants(claimDate) {
		Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			if (response?.data && response?.status === 200) {
				// If only one participant exists set the onlyParticipant to hide the participant dropdown and
				this.participants = [];
				for (const index in response.data) {
					if (claimDate) {
						// Format the date responses for the code to format the javascript date correctly.
						let formattedTermDate = `${response?.data[index]?.terminationDate.slice(
							0,
							4
						)}/${response?.data[index]?.terminationDate.slice(4, 6)}/${response?.data[
							index
						]?.terminationDate.slice(6)}`;
						let formattedClaimDate = claimDate.split('-').join('/');
						// Only get the active participants.
						if (response.data[index].active) {
							this.participants.push(response.data[index]);

							// If the participant is not active then check to see if the date of service is before the termination date.
						} else if (new Date(formattedTermDate) >= new Date(formattedClaimDate)) {
							{
								this.participants.push(response.data[index]);
							}
						}
					}
				}
				// Check if a participant id already exists on the claim, or in manualClaimServices
				if (this.claim?.participantId) {
					this.serviceInfo.participant = this.participants.find(
						(participant) => participant.participantId === this.claim.participantId
					);
					this.claim.participantId = null;
				} else if (this.claim?.manualClaimServiceItems[0]?.participant) {
					this.serviceInfo.participant = this.participants.find(
						(participant) =>
							participant.participantId === this.claim.manualClaimServiceItems[0].participant
					);
					this.claim.participantId = null;
				}
			} else {
				this.$store.dispatch('error');
			}
			this.$store.dispatch('updateLoading', false);
		});
		await this.checkShowNextButton();
	}

	/**
	 * Check if the member only has one participant. If they do set the only participant, and the provider info participant.
	 */
	async checkOneParticipant() {
		Dependent.getDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)?.then((response) => {
			if (response?.data && response?.status === 200) {
				// If only one participant exists set the onlyParticipant to hide the participant dropdown and
				if (response.data.length === 1) {
					this.onlyParticipant = response.data[0];
					this.serviceInfo.participant = this.onlyParticipant;
				}
			} else {
				this.$store.dispatch('error');
			}
		});
	}

	async getPwaServices() {
		if (
			this.serviceInfo.claimDate.length === 10 &&
			(this.serviceInfo.claimDate !== null || this.serviceInfo.claimDate !== '')
		) {
			await ServiceTypes.getServices(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale,
				this.claim.lob,
				this.serviceInfo.claimDate,
				Claimtypes.PWA
			).then((response) => {
				this.services = response;
				for (let i = 0; i < this.services?.length; i++) {
					if (
						this.pwaServices.findIndex((x) => x.value === this.services[i].descriptionRbKey) === -1
					) {
						if (this.services[i]?.description?.toUpperCase() === 'OTHER') {
							this.pwaServices.push({
								value: 'Other',
								text: 'Other'
							});
						} else {
							this.pwaServices.push({
								value: this.services[i].descriptionRbKey,
								text: this.services[i].description
							});
						}
					}
				}
			});
		}
	}

	/**
	 * Filter the recent provider list to only show providers related to the specific provider type the member has selected.
	 * Also checks the
	 */
	async filterRecentProviders(providerType) {
		if (this.recentProviders) {
			this.filteredRecentProviders = this.recentProviders?.filter((provider) => {
				return (
					provider.providerType === providerType &&
					provider.status !== 'INACTIVE' &&
					provider.status !== 'INPROGRESS'
				);
			});
		}
		this.checkShowNextButton();
	}

	/**
	 * Retrieve the resource urls from the API to use the urls to determine the last updated date for the privacy policy and the terms and conditions
	 */
	async getResourceUrls() {
		await MemberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		).then((response) => {
			if (response && (response.status === 201 || response.status === 200)) {
				// Retrieve the updated date for the privacy policy from S3.
				let secureUrl = response.data.json_claimNonRtaUrl.replace('http://', 'https://');
				fetch(secureUrl)
					.then((response) => response.json())
					.then((data) => {
						for (let i = 0; i < data.length; i++) {
							this.providerTypes.push({
								value: data[i].typeName,
								text: data[i].typeName,
								isOther: data[i].isOther
							});
						}
					})
					.catch((error) => {
						if (error) {
							this.$store.dispatch('error', {
								name: 'generic'
							});
						}
					});
			}
		});
	}

	/**
	 * Check if we should show the next button.
	 */
	async checkShowNextButton() {
		this.showNextButton = false;

		// PRACTITIONER
		if (this.isPractitioner) {
			if (
				this.serviceInfo.claimDate !== null &&
				this.serviceInfo.participant !== null &&
				this.filteredRecentProviders?.length === 0
			) {
				this.showNextButton = true;
			} else if (
				this.serviceInfo.claimDate !== null &&
				this.serviceInfo.participant !== null &&
				this.serviceInfo.providerType !== null &&
				this.filteredRecentProviders === null
			) {
				this.showNextButton = true;
			}
		}

		// VISION
		if (this.isVision && this.serviceInfo.participant !== null) {
			this.showNextButton = true;
		}

		// DRUGS / Dental
		if (this.isDrugsOrDental && this.serviceInfo.participant !== null) {
			this.showNextButton = true;
		}

		// Health Flow
		if (this.isHealth) {
			if (this.serviceInfo.providerType !== null && this.showOtherInput === false) {
				this.showNextButton = true;
			}
			if (
				this.showOtherInput === true &&
				this.otherProviderType !== null &&
				this.otherProviderType !== ''
			) {
				this.showNextButton = true;
			}
		}

		// PWA Flow
		if (this.isPWA) {
			this.showNextButton = true;
			this.disableNextButton = true;
			if (this.serviceInfo.amountPaid !== null && this.serviceInfo.amountPaid > 0) {
				this.disableNextButton = false;
			}
		}
	}

	/**
	 * This method is called when the member is submitting a pwa claim.
	 * It will check to make sure the amount is valid. if not it will display a modal with more information for the member.
	 * If the amount is valid it returns true.
	 */
	async checkValidPaidAmount() {
		if (this.serviceInfo.amountPaid < 1) {
			this.errorType = 'min';
			this.showErrorModal = true;
		} else if (this.serviceInfo.amountPaid >= 100000) {
			this.errorType = 'max';
			this.showErrorModal = true;
		} else {
			return true;
		}
	}

	/**
	 * Check if the service type has RTA
	 */
	canRta(serviceType) {
		return this.providers?.some((type) => {
			if (type.claimType === serviceType) return type.isRta;
		});
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			// Created a structured clone of the claim object from the last page. This ensures that the claim object is not a reference object.
			this.claim = structuredClone(await Page.getClaimFromLastPage());
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
		}
	}
}
</script>
<i18n>
{
  	"en": {
    	"title": {
      		"step": "Claim Details"
		},
		"error": {
			"minTitle": "This cost seems low.",
			"maxTitle": "This cost seems high.",
			"minDescription": "You've entered an amount less than $1.00. Is that correct?",
			"maxDescription": "The total cost you entered is too high for an online claim. Please double-check the amount entered and try again.",
			"yes": "Yes",
			"no": "No",
			"okay": "OK"
		}
  	},
  	"fr": {
		"title": {
      		"step": "Détails"
    	},
		"error": {
			"minTitle": "Ce coût semble trop bas.",
			"maxTitle": "Ce coût semble trop élevé.",
			"minDescription": "Vous avez entré un montant de moins de 1 $. Est-ce le bon montant?",
			"maxDescription": "Le coût total que vous avez entré est trop élevé pour une demande de règlement en ligne. Veuillez vérifier le montant entré et réessayer.",
			"yes": "Oui",
			"no": "Non",
			"okay": "OK"
		}
  	}
}
</i18n>
