<template>
	<div>
		<BaseCard>
			<ProviderCard
				class="mb-0"
				:member="member"
				:show-provider="false"
				automation-id="claim-details-hsa-provider"
			/>
			<BaseAlert v-if="showReminder" class="reminder" variant="warning" alternate>
				{{ $t('label.friendlyReminder') }}
				<template #description>{{ $t('label.submitCobFirst') }}</template>
			</BaseAlert>
		</BaseCard>
		<BaseCard>
			<HsaServiceListItem
				:value="services"
				:lob-labels="labels"
				class="service-list-item"
				:automation-id="`claim-details-hsa-service`"
			/>
		</BaseCard>
		<BaseCard v-if="showOtherCarrierPaid">
			<BRow>
				<BCol cols="10">
					<YesNoToggle
						:value="newValues.wasSubmittedToOtherFirst"
						:label="$t('label.wasSubmittedToOtherFirst')"
						stacked
						is-required
						class="mb-0"
						:required-error-msg="$t('error.required')"
						:automation-id="getAutomationId('service-card-paid-other-policy')"
						@input="updateWasSubmittedToOtherFirst"
					></YesNoToggle>
				</BCol>
			</BRow>
		</BaseCard>
		<BaseCard v-if="newValues.wasSubmittedToOtherFirst">
			<!-- Show a currency input if the member selected that the other carrier paid on this claim. -->
			<BRow>
				<BCol cols="12">
					<CurrencyInput
						id="cob-amount-input"
						class="currency-input input"
						:min-value="-1"
						:label="$t('label.amountPaidByOtherPolicy')"
						:placeholder="$t('label.amountPlaceholder')"
						:value="newValues.amountPaidByOtherCarrier"
						:validation-rules="{
							required: true,
							isANumber: true,
							isMinValue: 0.01,
							isMaxValue: costOfService
						}"
						:validation-messages="{
							required: $t('error.cobAmountCovered'),
							isMinValue: $t('error.valid'),
							isMaxValue: $t('error.max')
						}"
						:automation-id="getAutomationId('service-card-cob')"
						@change="updateAmountPaidByOtherCarrier"
					/>
				</BCol>
			</BRow>
		</BaseCard>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// Components
import { BRow, BCol } from 'bootstrap-vue';
import HsaServiceListItem from '@/components/claim/claimService/HsaServiceListItem';
import ProviderCard from '@/components/claim/claimService/ProviderCard';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseAlert from '@/components/common/alert/BaseAlert';
import YesNoToggle from '@/components/common/YesNoToggle';
import CurrencyInput from '@/components/common/CurrencyInput';

//Models
import HsaClaims from '@/models/claims/HsaClaims';
import COB from '@/models/claims/CoordinationOfBenefits';

// Mixin
import IdMixin from '@/mixins/id';

@Component({
	name: 'HsaClaimDetails',
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		member: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		BRow,
		BCol,
		HsaServiceListItem,
		BaseCard,
		ProviderCard,
		BaseAlert,
		YesNoToggle,
		CurrencyInput
	}
})
export default class HsaClaimDetails extends Vue {
	labels = null;
	cobOnFile = null;
	showReminder = false;
	showOtherCarrierPaid = false;
	showPaidByOtherPolicyAmount = null;
	newValues = {
		wasSubmittedToOtherFirst: null,
		amountPaidByOtherCarrier: null
	};
	// ---------------------------- COMPUTED PROPERTIES ------------------------------

	get claim() {
		return this.value ? this.value : {};
	}

	get services() {
		return this.claim?.hsaEligibleServices ? this.claim.hsaEligibleServices : [];
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get costOfService() {
		// Only need to get this if their is only 1 service.
		if (this.services.length === 1) {
			return this.services[0].calculatedEligibleAmount;
		}
		return null;
	}

	// lifecycle methods
	async mounted() {
		this.$store.dispatch('updateLoading', true);
		await this.getlabels();

		// Check if the member is navigating back to this screen and has already entered an amount paid by other carrier.
		if (
			this.services[0]?.wasSubmittedToOtherFirst !== null &&
			this.services[0]?.wasSubmittedToOtherFirst !== undefined
		) {
			this.showReminder = true;
			this.showOtherCarrierPaid = true;
			this.showPaidByOtherPolicyAmount = this.services[0]?.wasSubmittedToOtherFirst;
			this.newValues.wasSubmittedToOtherFirst = this.services[0]?.wasSubmittedToOtherFirst;
			this.newValues.amountPaidByOtherCarrier = this.services[0].amountPaidByOtherCarrier;
		} else {
			await this.getCobInfo();
			if (this.services.length === 1) {
				if (
					this.cobOnFile &&
					this.cobOnFile.primaryInd !== 'Y' &&
					this.cobOnFile.primaryInd !== true
				) {
					if (
						this.claim?.coordinationOfBenefits?.extCovCarrierCode !== 'BC' &&
						this.claim?.coordinationOfBenefits?.extCovCarrierCode !== 'CB' &&
						this.claim?.coordinationOfBenefits?.extCovCarrierCode !== 'SK'
					) {
						this.showReminder = true;
						this.showOtherCarrierPaid = true;
					}
				}
			}
		}

		this.$store.dispatch('updateLoading', false);
	}

	async getlabels() {
		this.labels = await HsaClaims.getLobLabels(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale
		);
	}

	async getCobInfo() {
		this.cobOnFile = await COB.getCOBInfoAPI(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.locale,
			this.services[0]?.dateOfService,
			this.claim.lob,
			this.claim.participantId
		);
		if (this.cobOnFile) {
			this.claim.coordinationOfBenefits = await COB.createNewCOB(sessionStorage.getItem('email'));
			this.claim.coordinationOfBenefits.extCovCarrierCode = this.cobOnFile?.carrierCode;
			this.claim.coordinationOfBenefits.extCovPrimaryInd = this.cobOnFile?.primaryInd;
			this.$emit('updateCob', this.claim);
		}
	}

	updateWasSubmittedToOtherFirst(value) {
		this.newValues.wasSubmittedToOtherFirst = value;
		this.showPaidByOtherPolicyAmount = value;
		this.newValues.amountPaidByOtherCarrier = null;
		this.$emit('blur', this.newValues);
	}

	updateAmountPaidByOtherCarrier(value) {
		this.newValues.amountPaidByOtherCarrier = value;
		this.$emit('blur', this.newValues);
	}
}
</script>

<style lang="scss" scoped>
.service-list-item {
	padding: 0px;
}
.reminder {
	margin: 1em 2em 0 0;
}
</style>

<i18n>
{
  "en": {
    "label": {
	  "friendlyReminder": "Reminder:",
	  "submitCobFirst": "You'll want to submit your claim to your other provider first before using your HSA funds. This will get you the greatest reimbursement and prevent you from prematurely using up your HSA funds.",
	  "wasSubmittedToOtherFirst": "Was this claim paid by the other policy?",
	  "amountPaidByOtherPolicy": "Amount covered by the other policy",
	  "amountPlaceholder": "$0.00"

    },
	"error": {
		"cobAmountCovered": "Please enter the amount covered by the other policy.",
		"valid": "The amount covered needs to be above zero.",
		"max": "The amount paid by the other policy must be less than the eligible amount. Please review the amount entered and try again.",
		"required": "Please select an option to continue."
	}
  },
  "fr": {
    "label": {
	  "friendlyReminder": "Rappel : ",
	  "submitCobFirst": "vous pouvez soumettre votre demande de règlement à votre autre assureur avant d’utiliser vos fonds du CGS. De cette façon, vous aurez un plus grand remboursement et vous n’épuiserez pas vos fonds du CGS trop rapidement. ",
	  "wasSubmittedToOtherFirst": "Cette demande de règlement a-t-elle été payée en vertu de l'autre police ?",
	  "amountPaidByOtherPolicy": "Veuillez entrer les montants couverts par l’autre régime.",
	  "amountPlaceholder": "0.00 $"

    },
	"error": {
		"cobAmountCovered": "Veuillez entrer le montant couvert par l’autre police.",
		"valid": "Le montant couvert doit être supérieur à zéro.",
		"max": "Le montant payé par l’autre police doit être plus bas que le montant admissible au CGS. Veuillez vérifier le montant que vous avez entré et réessayer.",
		"required": "Veuillez sélectionner une option pour continuer."
	}
  }
}
</i18n>
