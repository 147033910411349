import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class ServiceTypes extends Model {
	static entity = 'serviceTypes';

	static loaded = false;

	/**
	 * benefit services fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			id: this.number(),
			email: this.string(),
			programId: this.number(),
			programCd: this.string(),
			specialtyId: this.number(),
			specialtyCode: this.number(),
			pocLobId: this.number(),
			pocLobCode: this.string(),
			serviceId: this.number(),
			languageCode: this.string(),
			description: this.string(),
			isMasterService: this.boolean(),
			categoryId: this.number(),
			categoryUiId: this.number(),
			isRequirePrescription: this.boolean(),
			dateOfService: this.string(),
			amountPaid: this.number(),
			isServiceTypeOther: this.boolean()
		};
	}

	static async getServiceByEmail(email) {
		return await super.query().withAll().where('email', email);
	}

	static async getServices(email, token, locale, lob, dateOfService, specialties) {
		try {
			let params = new URLSearchParams();
			params.append('lob', lob);
			params.append('service-date', dateOfService);
			params.append('specialties', specialties);
			return await axios
				.get(`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/service-types`, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						Locale: locale
					},
					params,
					data: {}
				})
				.then((response) => {
					return response.data;
				})
				.catch(() => {
					this.store().dispatch('error');
				});
		} catch (err) {
			this.store().dispatch('error', err);
		}
	}

	static async clearServices() {
		await super.deleteAll();
		return true;
	}
}
