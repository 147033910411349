<template>
	<BCard>
		<CheckListItem>
			{{ $t('check.alreadySubmitted') }}
			<template #description>{{ $t('description.alreadySubmitted') }}</template>
		</CheckListItem>
		<CheckListItem>
			{{ $t('check.needToSubmit') }}
			<template #description>
				{{ $t('description.needToSubmit1') }}

				<BLink class="link" automation-id="HSASelectClaimTypeLink" :to="{ name: selectClaimType }">
					{{ $t('description.needToSubmit2') }}
				</BLink>

				{{ $t('description.needToSubmit3') }}
			</template>
		</CheckListItem>
		<CheckListItem>
			{{ $t('check.otherPolicy') }}
			<template #description>{{ $t('description.otherPolicy') }}</template>
		</CheckListItem>
	</BCard>
</template>

<script>
import { SUBMIT_CLAIM } from '@/constants/Routes.js';
import { BCard, BLink } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import CheckListItem from '@/components/common/CheckListItem';

export default {
	name: 'HSACheckListCard',
	components: {
		BCard,
		BLink,
		CheckListItem
	},
	mixins: [IdMixin],
	computed: {
		selectClaimType() {
			return SUBMIT_CLAIM.CLAIM_TYPE;
		}
	}
};
</script>

<style lang="scss" scoped>
.card-body {
	padding-bottom: 15px;
	width: 90%;
}

.link {
	font-weight: 400;
	color: $blue;
}
</style>

<i18n>
{
  "en": {
    "check": {
      "alreadySubmitted": "Was your claim already submitted?",
      "needToSubmit": "Need to submit your claim?",
      "otherPolicy": "Do you have another policy you can use?"
    },
    "description": {
      "alreadySubmitted": "You will be asked to choose from a list of eligible claims that you or your provider have already submitted to Blue Cross. If your claim is not in the list, it will need to be submitted first before you can claim for HSA reimbursement.",
      "needToSubmit1": "You can do this by ",
      "needToSubmit2": " returning to the main menu",
      "needToSubmit3": " and submitting the original claim (for example: submitting as a health claim first)",
      "otherPolicy": "If you share benefits with a spouse or partner, we recommend validating that you’ve exhausted all funds from that policy before requesting reimbursements from your HSA."
    }
  },
  "fr": {
    "check": {
      "alreadySubmitted": "Votre demande de règlement a-t-elle déjà été soumise?",
      "needToSubmit": "Vous devez soumettre votre demande de règlement?",
      "otherPolicy": "Pourriez-vous obtenir un remboursement en vertu d’une autre police?"
    },
    "description": {
      "alreadySubmitted": "Vous devrez sélectionner la demande de règlement dans la liste de demandes admissibles que vous ou votre fournisseur avez déjà soumises à Croix Bleue. Si votre demande de règlement ne figure pas dans la liste, vous devrez d’abord la soumettre avant d’utiliser votre CGS pour le remboursement.",
      "needToSubmit1": "Vous pouvez le faire en ",
      "needToSubmit2": " retournant au menu principal ",
      "needToSubmit3": " et en soumettant la demande de règlement originale (par exemple, soumettre comme une demande règlement pour soins de santé en premier).",
      "otherPolicy": "Si vous coordonnez vos prestations avec celles de votre conjoint(e), nous vous recommandons de vérifier que vous avez réclamé tous les fonds offerts par cette police avant d’utiliser votre CGS pour le remboursement."
    }
  }
}
</i18n>
